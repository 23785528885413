import {LeftOutlined} from '@ant-design/icons';
import {Button} from '@joonca/library/src/antd';
import React, {FunctionComponent, HTMLProps, useCallback} from 'react';
import {useHistory} from 'react-router';
import {classNames} from 'src/antd/utils';
import styled from 'styled-components';

export type FormActionsProps = Pick<HTMLProps<HTMLDivElement>, 'className' | 'style'> & {
  submitDisabled?: boolean;
  submitLoading?: boolean;
};

export const FormActions: FunctionComponent<FormActionsProps> = ({
  className,
  submitDisabled,
  submitLoading,
  children,
  ...otherProps
}) => {
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <Container className={classNames('ant-row', className)} {...otherProps}>
      <Button size="large" type="secondary" onClick={goBack}>
        <LeftOutlined />
        Retour
      </Button>
      <Button size="large" type="primary" htmlType="submit" disabled={submitDisabled} loading={submitLoading}>
        Enregistrer
      </Button>
      {children}
    </Container>
  );
};

const Container = styled.div<HTMLProps<HTMLDivElement>>`
  justify-content: flex-start;
  position: relative;
  margin: 2em 0 0;
  button:not(:first-child) {
    margin-left: 1rem;
  }
`;
