// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {Button, useActions, useAsyncEffect} from '@joonca/library';
import {LinkButton, messageOnError} from '@joonca/library/src/app';
import {dateStringSorterForField, localeSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {message, Popconfirm} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {get} from 'lodash';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import ApplicationStatus, {
  APPLICATION_CLIENT_STATUS_LABELS,
  APPLICATION_WORKER_STATUS_LABELS
} from 'src/components/app/mission/ApplicationStatus';
import isClosedColumn from 'src/components/app/table/isClosedColumn';
import missionColumn from 'src/components/app/table/missionColumn';
import userColumn from 'src/components/app/table/userColumn';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as applicationsActions} from 'src/store/modules/applications';
import {State} from 'src/store/reducers';
import {Application} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

const QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1, username: 1},
  position: {name: 1},
  mission: {_sid: 1, internalTitle: 1, internalReference: 1, startsAt: 1, place: 1},
  organization: {name: 1}
};

const APPLICATION_SEARCH_KEYS = [
  'user.firstName',
  'user.lastName',
  'mission.enrollmentDeadline',
  'mission.internalTitle',
  'mission.internalReference',
  'position.name',
  'organization.name'
];

type Props = {
  localStorageKey?: string;
  queryFilter?: Record<string, unknown>;
};
const ApplicationList: FunctionComponent<Props> = ({localStorageKey, queryFilter = {}}) => {
  const {fetchApplications, acceptApplication, rejectApplication, deleteApplication} = useActions(
    applicationsActions,
    []
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const applications = useSelector<State, Array<Application>>((state) => state.applications.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchApplications({}, {query: {filter: queryFilter, population: QUERY_POPULATION}})]),
    [fetchApplications]
  );

  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<Application>>(
    () => [
      userColumn<Application>(),
      missionColumn<Application>(),
      {
        title: 'Ville',
        dataIndex: ['mission', 'place', 'postalCode'],
        sorter: localeSorterForField('mission.place.postalCode'),
        render: (value, record) => (
          <span style={{fontSize: '12px'}}>
            {value}
            <br />
            {get(record, 'mission.place.locality', 'N/A')}
          </span>
        )
      },
      {
        title: 'Start Date',
        dataIndex: ['mission', 'startsAt'],
        sorter: dateStringSorterForField('mission.startsAt'),
        render: dateTableRender
      },
      // {
      //   title: 'Position',
      //   dataIndex: 'position',
      //   render: value => (value ? value.name : '')
      // },
      // {
      //   title: 'Organization',
      //   dataIndex: 'organization',
      //   render: value => (value ? value.name : '')
      // },
      isClosedColumn<Application>(),
      {
        title: 'Statut Jober',
        dataIndex: 'workerStatus',
        filters: Object.keys(APPLICATION_WORKER_STATUS_LABELS).map((value) => ({
          value,
          text: APPLICATION_WORKER_STATUS_LABELS[value]
        })),
        onFilter: (value, record) => record.workerStatus === value,
        sorter: dateStringSorterForField('workerStatusDate'),
        render: (value, record) => (
          <ApplicationStatus type="worker" status={value} date={record.workerStatusDate} />
        )
      },
      {
        title: 'Statut Client',
        dataIndex: 'clientStatus',
        filters: Object.keys(APPLICATION_CLIENT_STATUS_LABELS).map((value) => ({
          value,
          text: APPLICATION_CLIENT_STATUS_LABELS[value]
        })),
        onFilter: (value, record) => record.clientStatus === value,
        sorter: dateStringSorterForField('clientStatusDate'),
        render: (value, record) => (
          <ApplicationStatus type="client" status={value} date={record.clientStatusDate} />
        )
      },
      {
        title: 'Création',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('applications')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 16 * 2 + 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              {/* <Tooltip title="Voir" placement="bottom">
                <Button
                  ghost
                  type="primary"
                  icon="search"
                  onClick={() => {
                    window.open(`/workers/${record.worker._sid}/view`);
                  }}
                />
              </Tooltip> */}
              <Popconfirm
                title="Confirmez-vous l'acceptation?"
                placement="topRight"
                onConfirm={async () => {
                  try {
                    await acceptApplication({_sid});
                    message.success('Candidature correctement acceptée! 👍');
                    await fetchData();
                  } catch (err) {
                    messageOnError(err);
                  }
                }}
                okText="Confirmer"
                cancelText="Annuler"
              >
                <Button ghost type="primary" icon="check" />
              </Popconfirm>
              <Popconfirm
                title="Confirmez-vous le rejet?"
                placement="topRight"
                onConfirm={async () => {
                  try {
                    await rejectApplication({_sid});
                    message.success('Candidature correctement rejetée! 👍');
                    await fetchData();
                  } catch (err) {
                    messageOnError(err);
                  }
                }}
                okText="Confirmer"
                cancelText="Annuler"
              >
                <Button ghost type="primary" icon="stop" />
              </Popconfirm>
              <Popconfirm
                title="Confirmez-vous la suppression?"
                placement="topRight"
                onConfirm={async () => {
                  try {
                    await deleteApplication({_sid});
                    message.success('Candidature correctement supprimée! 👍');
                    await fetchData();
                  } catch (err) {
                    messageOnError(err);
                  }
                }}
                okText="Confirmer"
                cancelText="Annuler"
              >
                <Button ghost type="primary" icon="close" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteApplication, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon="plus" to="/applications/create">
          Add Application
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={applications}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={APPLICATION_SEARCH_KEYS}
          localStorageKey={localStorageKey || 'ApplicationList'}
          locale={{emptyText: 'Aucune candidature'}}
        />
      </TableContent>
    </TableLayout>
  );
};

export default ApplicationList;
