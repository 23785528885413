import styled from 'styled-components';

// export const FormRowProps = HTMLProps

export const FormRow = styled.div.attrs(() => ({
  className: 'ant-row'
}))`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  /* align-items: space-between; */
  /* justify-content: space-between; */
  > .ant-row {
    flex: 1;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
