// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders

import CircleOutlined from './CircleOutlined';
import {Checkbox as BaseCheckbox} from 'antd';
import React, {
  createElement,
  FunctionComponent,
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  ReactNode,
  RefForwardingComponent
} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
// import {
//   CheckboxProps as BaseCheckboxProps,
//   CheckboxGroupProps as BaseCheckboxGroupProps
// } from 'antd/lib/checkbox/interface';
import {mapSizeProps, SizeType} from '../helpers/size';
import {
  CheckboxGroupProps as BaseCheckboxGroupProps,
  CheckboxProps as BaseCheckboxProps
} from 'antd/lib/checkbox';

const {Group: BaseCheckboxGroup} = BaseCheckbox;

const StyledGroup = styled(BaseCheckboxGroup)`
  &.ant-checkbox-group-sm {
    .ant-checkbox-button-wrapper {
      height: 24px;
      padding: 0 7px;
      line-height: 22px;
    }
  }
  &.ant-checkbox-group-md {
    .ant-checkbox-button-wrapper {
      height: 32px;
      padding: 0 15px;
      line-height: 30px;
    }
  }
  &.ant-checkbox-group-lg {
    .ant-checkbox-button-wrapper {
      min-height: 40px;
      font-size: 16px;
      /* line-height: 38px; */
    }
  }
  &.ant-checkbox-group-xl {
    .ant-checkbox-button-wrapper {
      min-height: 48px;
      font-size: 18px;
      line-height: ${48 / 2}px;
      padding-top: 11px;
      padding-bottom: 11px;
      /* line-height: ${48 - 2}px; */
    }
  }
  &.ant-checkbox-group-flex-3 {
    display: flex;
    flex-wrap: wrap;
    .ant-checkbox-button-wrapper {
      flex: 0 0 calc(33.33% - 12px);
    }
  }
  &.ant-checkbox-group-flex-4 {
    display: flex;
    flex-wrap: wrap;
    .ant-checkbox-button-wrapper {
      flex: 0 0 calc(25% - 12px);
    }
  }
  &.ant-checkbox-group-flex-5 {
    display: flex;
    flex-wrap: wrap;
    .ant-checkbox-button-wrapper {
      flex: 0 0 calc(20% - 12px);
    }
  }
  &.ant-checkbox-group-grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
  }
  &.ant-checkbox-group-vertical {
    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
    .ant-checkbox-button-wrapper {
      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
`;

export type GroupProps = Omit<BaseCheckboxGroupProps, 'size'> & {
  layout?: 'horizontal' | 'vertical' | 'flex-3' | 'flex-4' | 'flex-5' | 'grid';
  size?: SizeType;
};

const Group: FunctionComponent<GroupProps> = ({
  children,
  layout = 'horizontal',
  size = 'md',
  className = '',
  ...props
}) => {
  return createElement(
    StyledGroup,
    {
      className: `${className} ant-checkbox-group-${layout} ant-checkbox-group-${size}`.trim(),
      ...(props as BaseCheckboxGroupProps)
    },
    Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child;
      }
      const {value} = child.props;
      return cloneElement(child, {checked: props.value ? props.value.includes(value) : false});
    })
  );
  // return createElement(StyledCheckboxGroup, {...props, ...mapSizeProps(props, 'ant-radio-group-')});
};

const StyledButton = styled(BaseCheckbox)`
  &.ant-checkbox-button-wrapper {
    display: inline-block;
    border-radius: 2px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: row;
    justify-content: flext-start;
    align-items: center;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
    margin: 0 12px 12px 0;
    &:hover {
      color: #1890ff;
    }
    &:not(.ant-checkbox-button-wrapper-checked),
    &.ant-checkbox-button-wrapper-checked.ant-checkbox-wrapper-disabled {
      border: 1px solid #d9d9d9;
    }
    &.ant-checkbox-button-wrapper-checked {
      border: 1px solid #1890ff;
      color: #1890ff;
      &:hover {
        border: 1px solid #40a9ff;
        color: #40a9ff;
      }
    }
  }
`;

/*
&:not(.ant-checkbox-button-wrapper-checked), &.ant-checkbox-button-wrapper-checked.ant-checkbox-wrapper-disabled {
  border: 1px solid #d9d9d9;
}
&.ant-checkbox-button-wrapper-checked {
  border: 1px solid #1890ff;
}*/
/*
.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 30px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
}
*/

export type ButtonProps = Omit<BaseCheckboxProps, 'size'> & {
  size?: SizeType;
};
export const Button: FunctionComponent<ButtonProps> = ({children, className = '', checked, ...otherProps}) => {
  return createElement(
    StyledButton,
    {
      className: `${className} ant-checkbox-button-wrapper ${
        checked ? 'ant-checkbox-button-wrapper-checked' : ''
      }`.trim(),
      checked,
      ...otherProps
    } as BaseCheckboxProps,
    children
  );
};

const StyledCheckbox = styled(BaseCheckbox)`
  background: black;
`;

export type CheckboxProps = Omit<BaseCheckboxProps, 'size'> & {
  size?: SizeType;
};
export const Checkbox: FunctionComponent<CheckboxProps> = ({children, ...otherProps}) => {
  return createElement(StyledCheckbox, otherProps, children);
};

export default Object.assign(Checkbox, {Group, Button});
