import {
  BookOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import type {Enrollment, EnrollmentStatus} from '@joonca/typings';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import React, {FunctionComponent, ReactNode} from 'react';
import {Tag, TagProps} from 'src/antd';
import {StatusTag} from './StatusTag';

export const ENROLLMENT_STATUS_LABELS: {[s in EnrollmentStatus]: string} = {
  confirmed: 'confirmée',
  aborted: 'retirée',
  cancelled: 'annulée',
  performed: 'effectuée',
  completed: 'finalisée',
  deserted: 'désertée',
  terminated: 'clôturée'
};
export const ENROLLMENT_STATUS_CAPTIONS: {[s in EnrollmentStatus]: string} = {
  confirmed: "L'inscription à la mission a été confirmée par le jober",
  aborted: "Le jober s'est désengagé de la mission",
  cancelled: 'La mission a été annulée',
  performed: 'La mission a bien eu lieu et les horaires sont en attente de validation',
  completed: 'La mission a bien eu lieu et les horaires ont été validés',
  deserted: "Le jober ne s'est pas présenté le jour de la mission",
  terminated: "L'inscription a été clôturée automatiquement par Joonca"
};
export const ENROLLMENT_STATUS_BY_LABELS: {[s in EnrollmentStatus]: string} = {
  confirmed: 'par le jober',
  aborted: 'par le jober',
  cancelled: 'par vous',
  performed: 'suite à la création de la timesheet',
  completed: "avec l'acceptation de la timesheet",
  deserted: 'par le jober',
  terminated: 'par joonca'
};
export const ENROLLMENT_STATUS_COLORS: {[s in EnrollmentStatus]: TagProps['color']} = {
  confirmed: 'blue',
  aborted: 'red',
  cancelled: 'volcano',
  performed: 'green',
  completed: 'green',
  deserted: 'red',
  terminated: 'purple'
};
export const ENROLLMENT_STATUS_ICONS: {[s in EnrollmentStatus]: ReactNode} = {
  confirmed: <BookOutlined />,
  aborted: <CloseCircleOutlined />,
  cancelled: <ClockCircleOutlined />,
  performed: <CheckCircleOutlined />,
  completed: <CheckCircleOutlined />,
  deserted: <ExclamationCircleOutlined />,
  terminated: <CloseCircleOutlined />
};

export type EnrollmentStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Enrollment['status']>;
  date?: Date | string | number;
};

export const EnrollmentStatusTag: FunctionComponent<EnrollmentStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip title={ENROLLMENT_STATUS_CAPTIONS[value]} placement={placement} overlayStyle={{maxWidth: 200}}>
      <Tag icon={ENROLLMENT_STATUS_ICONS[value]} color={ENROLLMENT_STATUS_COLORS[value]} {...otherProps}>
        {ENROLLMENT_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);
