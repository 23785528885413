/* eslint-disable no-console */
import {IS_DEV} from 'src/config/env';

const noop = (): void => {
  // noop
};

export const count: (s: string) => void = IS_DEV ? console.count.bind(console) : noop;
export const time: (s: string) => void = IS_DEV ? console.time.bind(console) : noop;
export const timeEnd: (s: string) => void = IS_DEV ? console.timeEnd.bind(console) : noop;
