import React from 'react';
import {Switch} from 'react-router-dom';

import AppLayout from 'src/layouts/AppLayout';
import OrganizationList from 'src/views/organizations/OrganizationList';
import OrganizationForm from 'src/views/organizations/OrganizationForm';
import PrivateRoute from '../helpers/PrivateRoute';

const OrganizationRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/organizations" component={OrganizationList} layout={AppLayout} />
    <PrivateRoute exact path="/organizations/create" component={OrganizationForm} layout={AppLayout} />
    <PrivateRoute exact path="/organizations/:id/edit" component={OrganizationForm} layout={AppLayout} />
  </Switch>
);

export const breadcrumbs = {
  '/organizations': {label: 'Organizations', icon: 'bank'},
  '/organizations/create': {label: 'Create', icon: 'edit'},
  '/organizations/:id/edit': {label: 'Edit', icon: 'edit'}
};

OrganizationRoutes.breadcrumbs = breadcrumbs;

export default OrganizationRoutes;
