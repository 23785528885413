import React, {FunctionComponent} from 'react';

type Props = {};

const UserForm: FunctionComponent<Props> = () => {
  return <div></div>;
};

export default UserForm;

// import React, {PureComponent} from 'react';
// import {Input, Select, Checkbox, Button, Spin, message} from 'antd';
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import {omit} from 'lodash';
// // @locals
// import {IS_DEV} from 'src/config/env';
// import {Form, FormFooter} from 'src/components/form';
// import {actions as usersActions} from 'src/store/modules/users';
// import UserFieldset, {fixture} from 'src/components/form/fieldset/UserFieldset';
// // @typings
// // import type {FormProps, ValidateCallback} from 'antd';
// // import type {ContextRouter} from 'react-router-dom';

// export const QUERY_PROJECTION = {
//   username: 1,
//   firstName: 1,
//   lastName: 1,
//   email: 1,
//   phoneNumber: 1,
//   role: 1,
//   isVerified: 1,
//   isBlocked: 1,
//   avatar: 1
// };

// type Props = {
//   actions: any,
//   isCreating: boolean,
//   isUpdating: boolean,
//   isFetching: boolean
// };

// type State = {
//   isEditing: boolean,
//   isPreloading: boolean,
//   isLoading: boolean
// };

// export class UserForm extends PureComponent<Props & FormProps & ContextRouter, State> {
//   state = {
//     isEditing: false,
//     isPreloading: true,
//     isLoading: false
//   };

//   static getDerivedStateFromProps(props: Props, state: State) {
//     const {match, isCreating, isUpdating, isFetching} = props;
//     const isEditing = !!match.params.id;
//     return {
//       isEditing,
//       isPreloading: isEditing && isFetching,
//       isLoading: isEditing ? isUpdating : isCreating
//     };
//   }

//   componentDidMount() {
//     const {isEditing} = this.state;
//     const {actions, form} = this.props;
//     const {params} = this.props.match;
//     if (isEditing) {
//       actions.getUser(params.id, {query: {projection: QUERY_PROJECTION}}).then(({body}) => {
//         form.setFieldsValue(omit(body, '_id'));
//       });
//     } else if (IS_DEV) {
//       form.setFieldsValue(fixture);
//     }
//   }

//   handleCancel = (ev: SyntheticEvent<any>) => {
//     const {history, location} = this.props;
//     const {isEditing} = this.state;
//     ev.preventDefault();
//     const target = location.pathname
//       .split('/')
//       .slice(0, isEditing ? -2 : -1)
//       .join('/');
//     history.push(target);
//   };

//   handleSubmit = (ev: SyntheticEvent<any>) => {
//     const {form} = this.props;
//     const {isEditing} = this.state;
//     ev.preventDefault();
//     form.validateFields((err, values: {[s: string]: any}) => {
//       if (err) {
//         return;
//       }
//       console.warn('validateFields', {values});
//       if (isEditing) {
//         this.performUpdateAction(values);
//       } else {
//         this.performCreateAction(values);
//       }
//     });
//   };

//   performUpdateAction(payload) {
//     const {actions, history} = this.props;
//     const {params} = this.props.match;
//     actions
//       .updateUser({...payload, _sid: params.id})
//       .then(() => {
//         message.success(`Sucessfully updated user!`);
//         // history.push('/users');
//       })
//       .catch(err => {
//         console.warn('err', err);
//         const message = err.body ? err.body.error : '';
//         message.error(`Update failed${message ? ` : ${message}` : ''}`);
//       });
//   }

//   performCreateAction(payload) {
//     const {actions, history} = this.props;
//     actions
//       .createUser(payload)
//       .then(() => {
//         message.success(`Sucessfully created user!`);
//         history.push('/users');
//       })
//       .catch(err => {
//         console.warn('err', err);
//         const message = err.body ? err.body.error : '';
//         message.error(`Create failed${message ? ` : ${message}` : ''}`);
//       });
//   }

//   render() {
//     console.count(`${this.constructor.name}.render()`);

//     const {isPreloading, isLoading, isEditing} = this.state;
//     const {form} = this.props;
//     const {params} = this.props.match;
//     console.warn({isEditing});
//     return (
//       <Spin spinning={isPreloading} delay={100}>
//         <Form onSubmit={this.handleSubmit} key={params.id || 'create'}>
//           <UserFieldset form={form} isEditing={isEditing} />
//           <FormFooter>
//             <Button htmlType="button" onClick={this.handleCancel}>
//               Cancel
//             </Button>
//             &nbsp;
//             <Button type="primary" htmlType="submit" disabled={isPreloading} loading={isLoading}>
//               {isEditing ? 'Update' : 'Create'}
//             </Button>
//           </FormFooter>
//         </Form>
//       </Spin>
//     );
//   }
// }

// export default connect(
//   // mapStateToProps
//   state => ({
//     isCreating: state.users.resource.isCreating,
//     isUpdating: state.users.resource.isUpdating,
//     isFetching: state.users.resource.isFetchingItem
//   }),
//   // mapDispatchToProps
//   (dispatch: Function) => ({
//     actions: bindActionCreators(usersActions, dispatch)
//   })
// )(Form.create()(UserForm));
