import React from 'react';
import {Select, Input} from 'antd';
// import type {FormProps} from 'antd';
import {FormItem, FormGroup, Fieldset} from 'src/components/form';

const Option = Select.Option; // eslint-disable-line

type Props = {
  fieldPrefix?: string;
};

export default function AddressFieldset({form, fieldPrefix}: Props & FormProps) {
  const {getFieldDecorator} = form;
  const prefix = fieldPrefix ? `${fieldPrefix}.` : '';
  return (
    <Fieldset legend="Address">
      <FormItem label="Address 1">
        {getFieldDecorator(`${prefix}firstLine`, {
          rules: [{required: true}]
        })(<Input />)}
      </FormItem>
      <FormItem label="Address 2">
        {getFieldDecorator(`${prefix}secondLine`, {
          rules: [{required: false}]
        })(<Input />)}
      </FormItem>
      <FormGroup>
        <FormItem label="Postal Code">
          {getFieldDecorator(`${prefix}postalCode`, {
            rules: [{required: false}]
          })(<Input />)}
        </FormItem>
        <FormItem style={{flex: 2}} label="City">
          {getFieldDecorator(`${prefix}locality`, {
            rules: [{required: false}]
          })(<Input />)}
        </FormItem>
      </FormGroup>
      <FormItem label="Country">
        {getFieldDecorator(`${prefix}country`)(
          <Select showSearch>
            <Option value="FR">France</Option>
          </Select>
        )}
      </FormItem>
    </Fieldset>
  );
}

/*
        contactName: {bsonType: 'string', required: false},
        firstLine: {bsonType: 'string', required: false},
        secondLine: {bsonType: 'string', required: false},
        thirdLine: {bsonType: 'string', required: false},
        zipCode: {bsonType: 'string', required: false},
        city: {bsonType: 'string', required: false},
        country: {bsonType: 'string', required: false}
        */

/*
         Copy
<form>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputEmail4">Email</label>
      <input type="email" class="form-control" id="inputEmail4" placeholder="Email">
    </div>
    <div class="form-group col-md-6">
      <label for="inputPassword4">Password</label>
      <input type="password" class="form-control" id="inputPassword4" placeholder="Password">
    </div>
  </div>
  <div class="form-group">
    <label for="inputAddress">Address</label>
    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
  </div>
  <div class="form-group">
    <label for="inputAddress2">Address 2</label>
    <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputCity">City</label>
      <input type="text" class="form-control" id="inputCity">
    </div>
    <div class="form-group col-md-4">
      <label for="inputState">State</label>
      <select id="inputState" class="form-control">
        <option selected>Choose...</option>
        <option>...</option>
      </select>
    </div>
    <div class="form-group col-md-2">
      <label for="inputZip">Zip</label>
      <input type="text" class="form-control" id="inputZip">
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck">
      <label class="form-check-label" for="gridCheck">
        Check me out
      </label>
    </div>
  </div>
  <button type="submit" class="btn btn-primary">Sign in</button>
</form>
*/
