// @docs https://ant.design/components/menu/
// @docs https://ant.design/components/icon/

import {
  AuditOutlined,
  BankOutlined,
  EuroOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileZipOutlined,
  HddOutlined,
  SolutionOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {computePathSnippetsUrls} from '@joonca/library/src/utils/location';
import {Layout, Menu} from 'antd';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {gradientColors, presetPalettes} from 'src/config/theme';
import styled from 'styled-components';
import {ReactComponent as AppLogo} from 'src/assets/AppLogo.svg';

const LOCAL_STORAGE_KEY = 'AppSidebar.isCollapsed';
const SubMenu = Menu.SubMenu;
const Item = Menu.Item;

type Props = {};

const AppSidebar: FunctionComponent<Props> = () => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    localStorage.getItem(LOCAL_STORAGE_KEY) === 'true'
  );
  const pathSnippetsUrls = useMemo(() => computePathSnippetsUrls(location.pathname), [
    location.pathname
  ]);
  const handleCollapse = useCallback(() => {
    const nextValue = !isCollapsed;
    localStorage.setItem(LOCAL_STORAGE_KEY, nextValue.toString());
    setIsCollapsed(nextValue);
  }, [isCollapsed]);

  if (pathSnippetsUrls.includes('/sessions') || pathSnippetsUrls.includes('/users')) {
    pathSnippetsUrls.push('/auth');
  }
  if (pathSnippetsUrls.includes('/workers') || pathSnippetsUrls.includes('/qualities')) {
    pathSnippetsUrls.push('/workforce');
  }
  return (
    <Sider collapsible collapsed={isCollapsed} onCollapse={handleCollapse}>
      <a href="/" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <AppLogo style={{width: '200px', height: '64px'}} />
      </a>
      <Menu
        // ref={ref => {
        //   this.menuRef = ref;
        // }}
        theme="dark"
        defaultSelectedKeys={pathSnippetsUrls}
        defaultOpenKeys={!isCollapsed ? pathSnippetsUrls : []}
        mode="inline"
      >
        <Item key="/workers">
          <Link to="/workers">
            <TeamOutlined />
            <span>Jobers</span>
          </Link>
        </Item>
        <Item key="/organizations">
          <Link to="/organizations">
            <BankOutlined />
            <span>Entreprises</span>
          </Link>
        </Item>
        {/* <SubMenu
          key="/work"
          title={
            <span>
              <Icon type="solution" />
              <span>Missions</span>
            </span>
          }
        > */}
        <Item key="/work/missions">
          <Link to="/work/missions">
            <SolutionOutlined />
            <span>Missions</span>
          </Link>
        </Item>
        <Item key="/work/applications">
          <Link to="/work/applications">
            <FileSearchOutlined />
            <span>Candidatures</span>
          </Link>
        </Item>
        <Item key="/work/enrollments">
          <Link to="/work/enrollments">
            <FileProtectOutlined />
            <span>Inscriptions</span>
          </Link>
        </Item>
        <Item key="/work/timesheets">
          <Link to="/work/timesheets">
            <FileDoneOutlined />
            <span>Timesheets</span>
          </Link>
        </Item>
        <Item key="/work/invoices">
          <Link to="/work/invoices">
            <EuroOutlined />
            <span>Factures</span>
          </Link>
        </Item>
        {/* <Item key="/work/skills">
              <Link to="/work/skills">Skills</Link>
            </Item> */}
        {/* </SubMenu> */}
        <SubMenu
          key="/labor"
          title={
            <span>
              <AuditOutlined />
              <span>Travail</span>
            </span>
          }
        >
          <Item key="/work/positions">
            <Link to="/work/positions">Postes</Link>
          </Item>
          <Item key="/qualities">
            <Link to="/qualities">Qualités</Link>
          </Item>
          <Item key="/labor/domains">
            <Link to="/labor/domains">Domaines</Link>
          </Item>
          <Item key="/labor/categories">
            <Link to="/labor/categories">Catégories</Link>
          </Item>
          <Item key="/labor/professions">
            <Link to="/labor/professions">Métiers</Link>
          </Item>
          <Item key="/labor/designations">
            <Link to="/labor/designations">Désignations</Link>
          </Item>
          <Item key="/labor/skills">
            <Link to="/labor/skills">Compétences</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="/users"
          title={
            <span>
              <FileZipOutlined />
              <span>Autres</span>
            </span>
          }
        >
          <Item key="/users">
            <Link to="/users">Utilisateurs</Link>
          </Item>
          <Item key="/devices">
            <Link to="/devices">Appareils</Link>
          </Item>
          <Item key="/sessions">
            <Link to="/sessions">Sessions</Link>
          </Item>
          <Item key="/files">
            <Link to="/files">
              <HddOutlined />
              <span>Fichiers</span>
            </Link>
          </Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default AppSidebar;

const Sider = styled(Layout.Sider)`
  background: linear-gradient(${gradientColors[0]}, ${gradientColors[1]});
  /* color: #fff;
  font-size: 24px;
  font-weight: 200; */
  .ant-logo {
    background: ${gradientColors[0]};
  }
  .ant-menu-dark {
    /* background: ${presetPalettes.geekblue[8]}; */
    background: transparent;
    font-weight: 500;
    .ant-menu-item-selected {
      background-color: ${presetPalettes.geekblue[5]};
    }
    .ant-menu-inline.ant-menu-sub {
      background-color: ${presetPalettes.geekblue[6]};
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) inset;
    }
  }
  .ant-layout-sider-trigger {
    background-color: ${presetPalettes.geekblue[5]};
  }
  &.ant-layout-sider-collapsed {
    .ant-logo {
      max-width: 48px;
      padding-left: 0px;
    }
  }
`;
