import React, {FunctionComponent, useEffect, useMemo, useState, useCallback, useRef} from 'react';
import {Select, SelectProps} from 'src/antd';

export type TimeSelectValue = number;

type Option = {
  value: TimeSelectValue;
  label: string;
};

export type TimeSelectProps = Omit<SelectProps<TimeSelectValue>, 'onChange' | 'value'> & {
  hourStep?: number;
  minuteStep?: number;
  doesOverflow?: boolean;
  value?: TimeSelectValue;
  onChange?: (value?: TimeSelectValue) => void;
  min?: number;
  max?: number;
};

export const TimeSelect: FunctionComponent<TimeSelectProps> = ({
  hourStep = 1,
  minuteStep = 15,
  min,
  max,
  value,
  onChange,
  doesOverflow = false,
  ...otherProps
}) => {
  const availableOptions = useRef<Option[]>([]);
  const latestSearchValue = useRef<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  // Filter by label
  // @NOTE optionFilterProp="label" isn't working
  const handleSearch = useCallback<NonNullable<SelectProps<TimeSelectValue>['onSearch']>>((searchValue) => {
    latestSearchValue.current = searchValue;
    setOptions(availableOptions.current.filter(({label}) => label.includes(searchValue)));
  }, []);

  // Auto-select when search === value
  const handleBlur = useCallback<NonNullable<SelectProps<TimeSelectValue>['onBlur']>>(() => {
    if (options.length === 1 && options[0].label === latestSearchValue.current && onChange) {
      onChange(options[0].value);
    }
  }, [onChange, options]);

  const handleChange = useCallback<NonNullable<SelectProps<TimeSelectValue>['onChange']>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (value, _option) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  // Build available options
  useEffect(() => {
    const options = [];
    for (let h = 0; h < (doesOverflow ? 48 : 24); h += hourStep) {
      for (let m = 0; m < 60; m += minuteStep) {
        const time = 36e5 * h + 6e4 * m;
        if (!isUndefined(min) && time < min) {
          continue;
        }
        if (!isUndefined(max) && time > max) {
          break;
        }
        const label = h >= 24 ? `${padTwo(h - 24)}h${padTwo(m)} J+1` : `${padTwo(h)}h${padTwo(m)}`;
        options.push({
          value: time,
          label
        });
      }
    }
    availableOptions.current = options;
    setOptions(options);
  }, [minuteStep, hourStep, doesOverflow, min, max]);

  // Reset value when it ends up being out of min/max bounds
  useEffect(() => {
    if (isUndefined(value) || !onChange) {
      return;
    }
    if (!isUndefined(min) && value < min) {
      onChange(undefined);
      return;
    }
    if (!isUndefined(max) && value > max) {
      onChange(undefined);
      return;
    }
  }, [min, max, value, onChange]);

  const optionItems = useMemo(
    () =>
      options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          <span>{option.label}</span>
        </Select.Option>
      )),
    [options]
  );

  return (
    <Select<TimeSelectValue>
      showSearch
      // loading
      filterOption={false}
      // optionFilterProp="label"
      value={options.length ? value : undefined}
      onSearch={handleSearch}
      onBlur={handleBlur}
      onChange={handleChange}
      style={{width: 200}}
      {...otherProps}
    >
      {optionItems}
    </Select>
  );
};

const padTwo = (value: number): string => ('00' + value).slice(-2);
const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';
