// @docs https://ant.design/components/form/

import React from 'react';
import {Checkbox, InputNumber} from 'antd';
import {FormItem, FormGroup, Fieldset, DatePicker, DateRangePicker, TimeRangePicker, Switch} from 'src/components/form';
import moment from 'moment';
import {WrappedFormUtils} from 'antd/lib/form/Form';

const CheckboxGroup = Checkbox.Group; // eslint-disable-line prefer-destructuring

export const fixture = {
  singleDate: false,
  everyDay: true,
  dates: {
    startDate: moment('2018-11-08T00:00:00.000Z'),
    endDate: moment('2018-11-14T00:00:00.000Z')
  },
  times: {
    startTime: moment('1970-01-01T10:00:00.000Z'),
    endTime: moment('1970-01-01T18:00:00.000Z')
  }
};

const WEEK_DAY_OPTIONS = [
  {label: 'Monday', value: 1},
  {label: 'Tuesday', value: 2},
  {label: 'Wednesday', value: 3},
  {label: 'Thursday', value: 4},
  {label: 'Friday', value: 5},
  {label: 'Saturday', value: 6},
  {label: 'Sunday', value: 0}
];

const timeInputNumberFormatter = (value: string) => {
  const parts = value.split('.');
  const hours = parts[0];
  const minutes = (parts[1] * 6) / 10;
  return `${hours}h${minutes < 10 ? `0${minutes}` : minutes}m`;
};

type Props = {
  form: WrappedFormUtils;
  validator: (rule: {[s: string]: any}, value: any, callback: () => void) => void;
  values: {[s: string]: any};
};

export default function MissionDateSlotFieldset(props: Props) {
  const {form, values, validator} = props;
  const {getFieldDecorator} = form;
  const {
    singleDate = values ? values.singleDate : false,
    everyDay = values ? values.everyDay : true,
    ofVariableDuration = values ? values.ofVariableDuration : false
  } = form.getFieldsValue(['singleDate', 'everyDay', 'ofVariableDuration']);
  return (
    <Fieldset legend="Mission Date Slot" {...props}>
      <FormGroup>
        <FormItem label="Single date" style={{flex: '0 0 150px'}}>
          {getFieldDecorator('singleDate', {valuePropName: 'checked'})(<Switch />)}
        </FormItem>
        {!singleDate ? (
          <FormItem label="Dates">
            {getFieldDecorator('dates', {
              rules: [{type: 'object', required: true}].concat(validator ? [{validator}] : [])
            })(<DateRangePicker format="DD/MM/YYYY" />)}
          </FormItem>
        ) : (
          <FormItem label="Date">
            {getFieldDecorator('date', {
              rules: [{type: 'object', required: true}].concat(validator ? [{validator}] : [])
            })(<DatePicker format="DD/MM/YYYY" />)}
          </FormItem>
        )}
        <FormItem label="Every days" style={{flex: '0 0 150px', display: singleDate ? 'none' : ''}}>
          {getFieldDecorator('everyDay', {initialValue: true, valuePropName: 'checked'})(<Switch />)}
        </FormItem>
      </FormGroup>
      <FormItem label="Week days" style={{display: everyDay ? 'none' : ''}}>
        {getFieldDecorator('weekDays', {
          rules: [{type: 'array', required: true}],
          initialValue: [0, 1, 2, 3, 4, 5, 6]
        })(<CheckboxGroup style={{marginLeft: '1.125rem'}} options={WEEK_DAY_OPTIONS} disabled={everyDay} />)}
      </FormItem>
      <FormGroup>
        <FormItem label="Varying duration" style={{flex: '0 0 150px', alignSelf: 'flex-start'}}>
          {getFieldDecorator('ofVariableDuration', {valuePropName: 'checked'})(<Switch />)}
        </FormItem>
        <FormItem label="Timetable">
          {getFieldDecorator('times', {
            rules: [{type: 'object', required: true}].concat(validator ? [{validator}] : [])
          })(<TimeRangePicker format="HH:mm" minuteStep={5} />)}
        </FormItem>
        {/* <FormItem label="Start Time">
          {getFieldDecorator('startTime', {
            rules: [{type: 'object', required: true}]
          })(<TimePicker format="HH:mm" minuteStep={5} />)}
        </FormItem>
        <FormItem label="End Time">
          {getFieldDecorator('endTime', {
            rules: [{type: 'object', required: true}]
          })(<TimePicker format="HH:mm" minuteStep={5} />)}
        </FormItem> */}
        {ofVariableDuration ? (
          <FormItem label="Drift">
            {getFieldDecorator('estimatedDriftHours', {
              initialValue: 1,
              rules: [{required: false}]
            })(<InputNumber min={1} max={168} step={0.25} formatter={timeInputNumberFormatter} />)}
          </FormItem>
        ) : (
          <div style={{flex: 1}} />
        )}
      </FormGroup>
    </Fieldset>
  );
}

// MissionDatesFieldsets.getDerivedValuesForProps = props => {
//   console.warn('in', props);
//   return props.values;
// };
