// @source https://github.com/ant-design/ant-design/blob/master/components/date-picker/RangePicker.tsx

import React, {PureComponent} from 'react';
import {Input, Select} from 'antd';

const Option = Select.Option; // eslint-disable-line prefer-destructuring

export default class PhoneNumberInput extends PureComponent {
  static getDerivedStateFromProps(props: Props, state: State) {
    const {value} = props;
    if (!value) {
      return state;
    }
    const prefix = value.slice(1, 3);
    const number = value.slice(3);
    return {...state, prefix, number};
  }

  static getDerivedValueFromState(state: State) {
    const {prefix, number} = state;
    if (!number) {
      return '';
    }
    return `+${prefix}${number.startsWith('0') ? number.slice(1) : number}`;
  }

  state = {
    prefix: '33',
    number: ''
  };

  handlePrefixChange = (value: string) => {
    const nextState = {...this.state, prefix: value};
    // this.setState(nextState);
    const {onChange} = this.props;
    if (onChange) {
      const {getDerivedValueFromState} = this.constructor;
      onChange(getDerivedValueFromState(nextState));
    }
  };

  handleChange = (ev: SyntheticEvent<Event>) => {
    const {value} = ev.target;
    const nextState = {...this.state, number: value};
    // this.setState(nextState);
    const {onChange} = this.props;
    if (onChange) {
      const {getDerivedValueFromState} = this.constructor;
      onChange(getDerivedValueFromState(nextState));
    }
  };

  render() {
    const {onChange, value, ...otherProps} = this.props;
    const {prefix, number} = this.state;

    const prefixSelector = (
      <Select value={prefix} style={{width: 70}} onChange={this.handlePrefixChange}>
        <Option value="33">+33</Option>
      </Select>
    );
    return <Input addonBefore={prefixSelector} {...otherProps} value={number} onChange={this.handleChange} />;
  }
}
