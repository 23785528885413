import {Spin, Table} from 'antd';
import {ColumnsType, TableProps} from 'antd/lib/table';
import {ColumnType, TableRowSelection} from 'antd/lib/table/interface';
import {get, toString, isEqual} from 'lodash';
import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {TABLE_LOCALE} from 'src/config/locale';
import styled from 'styled-components';

export const Count = styled('div')`
  color: #999;
  font-size: 11px;
  position: absolute;
  top: -30px;
  left: 10px;
`;

type Props<T> = TableProps<T> & {
  searchValue: string;
  searchKeys?: Array<string>;
  showCount?: boolean;
  localStorageKey?: string;
};

export const defaultProps = {
  showCount: true
};

const SearchableTable = <T extends Record<string, unknown>>({
  dataSource,
  columns,
  searchValue,
  showCount = defaultProps.showCount,
  searchKeys,
  localStorageKey,
  ...otherProps
}: Props<T>): ReactElement | null => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<(string | number)[]>([]);
  const filteredList = useMemo(() => {
    // Skip search
    if (!searchValue || !dataSource || !dataSource.length) {
      return dataSource;
    }
    // Search in defined searchKeys and fallback to all keys
    console.time('filter');
    const keys = searchKeys || Object.keys(dataSource[0]);
    const results = dataSource.filter((item) =>
      keys.some((key) => toString(get(item, key, '')).toLowerCase().includes(searchValue.toLowerCase()))
    );
    console.timeEnd('filter');
    return results;
  }, [dataSource, searchKeys, searchValue]);
  const handleRowSelectionChange = useCallback(
    (selectedRowKeys: (string | number)[], _selectedRows: T[]) => setSelectedRowKeys(selectedRowKeys),
    []
  );
  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys,
    onChange: handleRowSelectionChange
  };

  // Restore last used params
  const columnsWithDefaults = useMemo<ColumnsType<T> | undefined>(() => {
    if (!localStorageKey || !columns) {
      return columns;
    }
    const localStorageValue = localStorage.getItem(`${localStorageKey}.table`);
    if (!localStorageValue) {
      return columns;
    }
    const {filters, sorters} = JSON.parse(localStorageValue);
    // Mutate columns in-place
    (columns as ColumnType<T>[]).forEach((column) => {
      column.defaultSortOrder =
        sorters && sorters.columnKey && isEqual(sorters.columnKey, column.dataIndex)
          ? sorters.order
          : undefined;
      column.defaultFilteredValue =
        filters && filters[column.dataIndex] ? filters[column.dataIndex] : undefined;
    });
    return columns;
  }, [localStorageKey, columns]);
  const pagination = useMemo(() => {
    if (!localStorageKey) {
      return {defaultPageSize: 25};
    }
    const localStorageValue = localStorage.getItem(`${localStorageKey}.table`);
    if (!localStorageValue) {
      return {defaultPageSize: 25};
    }
    const {pagination} = JSON.parse(localStorageValue);
    return {defaultPageSize: pagination.defaultPageSize, defaultCurrent: pagination.current};
  }, [localStorageKey]);
  const handleChange = useCallback(
    (pagination, filters, {column, ...sorters}) => {
      // console.warn('change!', {localStorageKey, column, sorters, filters});
      if (!localStorageKey || !column) {
        return;
      }
      // @FIX
      if (!sorters.columnKey) {
        sorters.columnKey = column.dataIndex;
      }
      // console.warn('saved!', {column, pagination, filters, sorters});
      localStorage.setItem(`${localStorageKey}.table`, JSON.stringify({pagination, filters, sorters}));
    },
    [localStorageKey]
  );
  // Store debounced searchValue
  // useDebouncedEffect(
  //   () => {
  //     if (!localStorageKey) {
  //       return;
  //     }
  //     localStorage.setItem(`${localStorageKey}.search`, JSON.stringify({searchValue}));
  //   },
  //   [localStorageKey, searchValue],
  //   1000
  // );

  return (
    <div style={{position: 'relative'}}>
      <Spin spinning={!dataSource} delay={100}>
        {showCount && dataSource && filteredList ? (
          <Count>
            {searchValue
              ? `${filteredList.length}/${dataSource.length} entrée(s)`
              : `${dataSource.length} entrée(s)`}
          </Count>
        ) : null}
        <Table
          rowSelection={rowSelection}
          dataSource={filteredList}
          locale={TABLE_LOCALE}
          onChange={handleChange}
          pagination={pagination}
          columns={columnsWithDefaults}
          {...otherProps}
        />
      </Spin>
    </div>
  );
};

export default SearchableTable;
