import React, {FunctionComponent, HTMLProps} from 'react';
import styled from 'styled-components';
import {FormattedDate} from '../typography';

export type StatusTagProps = Pick<HTMLProps<HTMLDivElement>, 'style'> & {
  date?: Date | number | string;
};

export const StatusTag: FunctionComponent<StatusTagProps> = ({children, date, ...otherProps}) => {
  return (
    <Container {...otherProps}>
      {children}
      {date ? <StyledDate value={date} format="DD/MM/YY HH[h]mm" tooltip="bottom" /> : null}
    </Container>
  );
};

const Container = styled.span`
  flex-direction: column;
  align-items: center;
  display: inline-flex; ;
`;

const StyledDate = styled(FormattedDate)`
  font-size: 10px;
  color: var(--grey600);
`;
