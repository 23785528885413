import React, {FunctionComponent} from 'react';
import {ReactComponent as CircleOutlinedSvg} from './CircleOutlined.svg';

type Props = {};

const CircleOutlined: FunctionComponent<Props> = () => {
  return (
    <span role="img" className="anticon anticon-circle">
      <CircleOutlinedSvg
        viewBox="64 64 896 896"
        focusable={false}
        width="1em"
        height="1em"
        fill="currentColor"
      />
    </span>
  );
};

export default CircleOutlined;
