import React, {PureComponent} from 'react';
import {Input} from 'antd';
import styled from 'styled-components';
import {throttle} from 'lodash';

export const Search = styled(Input.Search)`
  /* display: flex;
  flex-direction: column;
  flex: 1; */
`;

type Props = {
  onSearch: Function;
};
type State = {
  selectedRowKeys: Array<string>;
};
export default class SearchableTable extends PureComponent<Props, State> {
  throttledSearch = throttle((searchValue) => this.handleSearch(searchValue), 400, {leading: false});

  handleSearch = (searchValue: string) => {
    const {onSearch} = this.props;
    if (onSearch) {
      onSearch(searchValue);
    }
  };

  handleSearchChange = (ev: SyntheticEvent<any>) => {
    const {value: searchValue} = ev.target;
    this.throttledSearch(searchValue);
  };

  render() {
    const {onSearch, ...otherProps} = this.props;
    return (
      <Search
        placeholder="Rechercher"
        onSearch={this.handleSearch}
        onChange={this.handleSearchChange}
        size="large"
        enterButton
        {...otherProps}
      />
    );
  }
}
