import {combineReducers} from 'redux';
import {createResource /* , reduceReducers */} from 'redux-rest-resource';
import {HOST_AUTH_URL} from 'src/config/env';

const {actions, reducers: resouceReducers} = createResource({
  name: 'device',
  url: `${HOST_AUTH_URL}/devices/:_sid`,
  credentials: 'include',
  actions: {
    update: {
      assignResponse: true
    }
  }
});

const rootReducer = combineReducers({
  resource: resouceReducers
});

export {actions};
export default rootReducer;
