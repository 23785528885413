import {message} from 'antd';
import {IS_DEV} from 'src/config/env';

export const messageOnError = (err: Error) => {
  if (IS_DEV) {
    console.error(err);
  }
  const {status = 500} = err;
  if (status === 500) {
    let content = "Une erreur inattendue s'est produite. Merci de réessayer ultérieurement.";
    if (IS_DEV && err.body) {
      content += `\n${JSON.stringify(err.body)}`;
    }
    message.error(content);
  } else if (status === 400) {
    let content = "Une erreur inattendue s'est produite. Merci de réessayer ultérieurement.";
    if (IS_DEV && err.body) {
      content += `\n${JSON.stringify(err.body)}`;
    }
    message.error(content);
  } else {
    console.warn('err', {err, status});
  }
  message.error('Une erreur inattendue est survenue');
};

// export {message};
