import React, {FunctionComponent} from 'react';

type Props = {};

const WorkerForm: FunctionComponent<Props> = () => {
  return <div>WorkerForm</div>;
};

export default WorkerForm;

// // @preview https://preview.pro.ant.design/form/step-form/info
// // @source https://github.com/ant-design/ant-design-pro/blob/master/src/pages/Forms/StepForm/index.js
// // @source https://github.com/react-component/steps/blob/master/src/Step.jsx
// // @docs https://ant.design/components/steps/

// import React, {PureComponent} from 'react';
// import {message} from 'antd';
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import {Route, Redirect} from 'react-router';
// import {pick} from 'lodash';
// import moment from 'moment';
// import memoize from 'memoize-one';
// // @locals
// import {IS_DEV} from 'src/config/env';
// import {actions as workersActions} from 'src/store/modules/workers';
// import {Form, StepperForm} from 'src/components/form';
// import {count} from '@joonca/library/src/utils/console';
// // @types
// // import type {ContextRouter} from 'react-router-dom';
// // import type {FormProps} from 'antd'; // CRASHES!!

// import WorkerProfileFieldset, {
//   fixture as workerProfileFixture
// } from 'src/components/form/fieldset/WorkerProfileFieldset';
// import WorkerDocumentsFieldset, {
//   fixture as workerDocumentsFixture
// } from 'src/components/form/fieldset/WorkerDocumentsFieldset';
// import WorkerSkillsFieldset, {fixture as workerSkillsFixture} from 'src/components/form/fieldset/WorkerSkillsFieldset';
// import WorkerFormExperienceStep from './WorkerFormExperienceStep';

// type Props = {
//   actions: any,
//   isCreating: boolean,
//   isUpdating: boolean,
//   isFetching: boolean
// };

// export class WorkerForm extends PureComponent<Props & FormProps & ContextRouter> {
//   static getDerivedStateFromProps(props: Props, state: State) {
//     const {match} = props;
//     const isEditing = 'id' in match.params;
//     return {
//       ...state,
//       isEditing
//     };
//   }

//   static getDerivedStepsValuesFromValues = memoize((values: any) => {
//     if (!values) {
//       return [];
//     }

//     // @TODO smart list displays
//     const castedValues = {...values};
//     castedValues.experiences = castedValues.experiences.map(experience => ({
//       ...experience,
//       startDate: moment(experience.startDate),
//       endDate: moment(experience.endDate)
//     }));

//     return [
//       pick(castedValues, 'user', 'nationality', 'gender', 'homePlace'),
//       pick(castedValues, 'experiences', 'certificates'),
//       pick(castedValues, 'licenses', 'languages', 'qualities'),
//       pick(castedValues, 'siren', 'bankAccount')
//     ];
//   });

//   static steps = {
//     create: [
//       {path: '/workers/create/profile', title: 'Profile'},
//       {path: '/workers/create/experiences', title: 'Experiences'},
//       {path: '/workers/create/skills', title: 'Skills'},
//       {path: '/workers/create/documents', title: 'Documents'}
//     ],
//     edit: [
//       {path: '/workers/:id/edit/profile', title: 'Profile'},
//       {path: '/workers/:id/edit/experiences', title: 'Experiences'},
//       {path: '/workers/:id/edit/skills', title: 'Skills'},
//       {path: '/workers/:id/edit/documents', title: 'Documents'}
//     ]
//   };

//   state = {
//     isEditing: false
//   };

//   componentDidMount() {
//     const {isEditing} = this.state;
//     const {actions} = this.props;
//     const {params} = this.props.match;
//     if (isEditing) {
//       actions.getWorker(params.id, {query: {}});
//     }
//   }

//   handleSubmit = values => {
//     console.warn('handleSubmit', values, JSON.stringify(values, null, 2));
//     const {isEditing} = this.state;
//     if (isEditing) {
//       this.performUpdateAction(values);
//     } else {
//       this.performCreateAction(values);
//     }
//   };

//   performUpdateAction(payload) {
//     const {actions} = this.props;
//     const {params} = this.props.match;
//     actions
//       .updateWorker({...payload, _sid: params.id})
//       .then(() => {
//         message.success(`Sucessfully updated user!`);
//       })
//       .catch(err => {
//         console.warn('err', err);
//         const text = err.body ? err.body.error : '';
//         message.error(`Update failed${text ? ` : ${text}` : ''}`);
//       });
//   }

//   performCreateAction(payload) {
//     const {actions, history} = this.props;
//     actions
//       .createWorker(payload)
//       .then(() => {
//         message.success(`Sucessfully created user!`);
//         history.push('/workers');
//       })
//       .catch(err => {
//         console.warn('err', err);
//         const text = err.body ? err.body.error : '';
//         message.error(`Create failed${text ? ` : ${text}` : ''}`);
//       });
//   }

//   render() {
//     count(`${this.constructor.name}.render()`);
//     // console.warn(`${this.constructor.name}.render()`, this.props, this.state);
//     const {match, values} = this.props;
//     const {getDerivedStepsValuesFromValues} = this.constructor;
//     const {isEditing} = this.state;
//     return (
//       <StepperForm
//         {...this.props}
//         stepsValues={getDerivedStepsValuesFromValues(values)}
//         steps={WorkerForm.steps}
//         onSubmit={this.handleSubmit}
//       >
//         <Route exact path="/workers/create" render={() => <Redirect to="/workers/create/profile" />} />
//         <Route
//           exact
//           path="/workers/:id/edit"
//           render={() => <Redirect to={`/workers/${match.params.id}/edit/profile`} />}
//         />
//         <Route
//           path={['/workers/create/profile', '/workers/:id/edit/profile']}
//           component={WorkerProfileFieldset}
//           values={IS_DEV && !isEditing ? workerProfileFixture : undefined}
//         />
//         <Route
//           path={['/workers/create/experiences', '/workers/:id/edit/experiences']}
//           component={WorkerFormExperienceStep}
//         />
//         <Route
//           path={['/workers/create/skills', '/workers/:id/edit/skills']}
//           component={WorkerSkillsFieldset}
//           values={IS_DEV && !isEditing ? workerSkillsFixture : undefined}
//         />
//         <Route
//           path={['/workers/create/documents', '/workers/:id/edit/documents']}
//           component={WorkerDocumentsFieldset}
//           values={IS_DEV && !isEditing ? workerDocumentsFixture : undefined}
//         />
//       </StepperForm>
//     );
//   }
// }

// export default connect(
//   // mapStateToProps
//   state => ({
//     isCreating: state.workers.resource.isCreating,
//     isUpdating: state.workers.resource.isUpdating,
//     isFetching: state.workers.resource.isFetchingItem,
//     values: state.workers.resource.item
//   }),
//   // mapDispatchToProps
//   (dispatch: Function) => ({
//     actions: bindActionCreators(workersActions, dispatch)
//   })
// )(Form.create()(WorkerForm));
