// https://github.com/dfahlander/Dexie.js/blob/master/samples/react-redux/src/actions/index.js

// import {defaultHeaders} from 'redux-rest-resource';
import {getAsync, postAsync} from '@joonca/library/src/utils/fetch';
import {HOST_AUTH_URL} from 'src/config/env';
import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';
import ky from 'ky';

// import type {Dispatch} from 'redux';

type State = {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authenticationError: Error | null;
  user: Record<string, unknown> | null;
};

type AsyncAction = ThunkAction<Promise<void>, State, null, Action<string>>;

const initialState: State = {
  isAuthenticating: false,
  isAuthenticated: false,
  authenticationError: null,
  user: null
};

// Types

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

export const types = {
  LOGIN,
  LOGOUT
};

// Actions

// const loginWithToken = (token = localStorage.getItem('token')) => dispatch => {
//   dispatch({type: types.LOGIN, status: 'pending'});
//   // Do not use bluebird Promise to prevent mounting errors being swallowed
//   return getAsync(`${HOST_URL}/me`, {headers: {Authorization: `Bearer ${token}`}})
//     .then(body => {
//       dispatch({type: types.LOGIN, status: 'resolved', body, token: body.token});
//       return body;
//     })
//     .catch(err => {
//       setTimeout(() => {
//         dispatch({
//           type: types.LOGIN,
//           status: 'rejected',
//           err: err.toString()
//         });
//       });
//       throw err;
//     });
// };

const loginWithForm = (username: string, password: string): AsyncAction => (dispatch) => {
  dispatch({type: types.LOGIN, status: 'pending'});
  const isEmail = username.includes('@');
  const payload = {password, [isEmail ? 'email' : 'username']: username};
  // @NOTE cookie not working
  // return ky
  //   .post(`${HOST_AUTH_URL}/login`, {json: payload})
  //   .json()
  return postAsync(`${HOST_AUTH_URL}/login`, {body: payload})
    .then((body) => {
      if (!body.user || !body.session) {
        throw new Error('Invalid server response');
      }
      const {role} = body.user;
      if (role !== 'admin') {
        throw new Error('Invalid credentials');
      }
      const {user, session} = body;
      dispatch({type: types.LOGIN, status: 'resolved', user, session});
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch({type: types.LOGIN, status: 'rejected', err});
      });
      throw err;
    });
};

const loginWithSession = (): AsyncAction => (dispatch) => {
  dispatch({type: types.LOGIN, status: 'pending'});
  return getAsync(`${HOST_AUTH_URL}/me`)
    .then((body) => {
      if (!body.user || !body.session) {
        throw new Error('Invalid server response');
      }
      const {role} = body.user;
      if (role !== 'admin') {
        throw new Error('Invalid credentials');
      }
      const {user, session} = body;
      dispatch({type: types.LOGIN, status: 'resolved', user, session});
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch({type: types.LOGIN, status: 'rejected', err});
      });
      throw err;
    });
};

const logout = (): AsyncAction => (dispatch) => {
  dispatch({type: types.LOGOUT, status: 'pending'});
  return postAsync(`${HOST_AUTH_URL}/logout`)
    .then((body) => {
      dispatch({type: types.LOGOUT, status: 'resolved', body});
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch({type: types.LOGOUT, status: 'rejected', err});
      });
      throw err;
    });
};

export const actions = {
  // loginWithToken,
  loginWithForm,
  loginWithSession,
  logout
};

// Reducers

export const reducers = {
  [types.LOGIN]: (state, action) => {
    switch (action.status) {
      case 'pending':
        return {
          ...state,
          isAuthenticating: true,
          authenticationError: null
        };
      case 'resolved': {
        const {user, session} = action;
        localStorage.setItem('sessionId', session.id);
        // Object.assign(defaultHeaders, {Authorization: `Bearer ${action.token}`});
        return {
          ...state,
          isAuthenticating: false,
          isAuthenticated: true,
          user
        };
      }
      default: {
        const {err} = action;
        if (err && err.status < 500) {
          localStorage.removeItem('sessionId');
        }
        const {status, statusText, body} = action.err;
        return {
          ...state,
          isAuthenticating: false,
          isAuthenticated: false,
          authenticationError: {status, statusText, body}
        };
      }
    }
  },
  [types.LOGOUT]: (state, action) => {
    switch (action.status) {
      case 'pending':
        return state;
      case 'resolved':
      default:
        localStorage.removeItem('sessionId');
        return {
          ...state,
          isAuthenticating: false,
          isAuthenticated: false,
          user: null
        };
    }
  }
};

// Root Reducer

export default function rootReducer(state = initialState, action) {
  const reducer = reducers[action.type];
  return reducer ? reducer(state, action) : state;
}
