// @docs https://uber.github.io/react-map-gl/#/
// @docs https://www.mapbox.com/mapbox-gl-js/api/#map
// @source https://github.com/mapbox/mapbox-gl-styles
// @autocomplete https://github.com/localvore-today/react-mapbox-autocomplete/blob/master/ReactMapboxAutocomplete.js

import React, {PureComponent} from 'react';
import ReactMapGL, {InteractiveMapProps} from 'react-map-gl';
import {MAPBOX_API_ACCESS_TOKEN} from 'src/config/env';
import styled from 'styled-components';
import MAP_STYLE from 'src/assets/mapbox/streets-v10.json';
import {defaults} from 'lodash';

export {Marker} from 'react-map-gl';

const MapContainer = styled('div')`
  margin: 12px 0 0;
  border-radius: 4px;
  overflow: hidden;
`;
const Map = styled(ReactMapGL)`
  .mapboxgl-ctrl-logo {
    display: none;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
  }
`;

type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
};

type Geopoint = {
  coordinates: [number, number];
  type: 'point';
};

const DEFAULT_VIEWPORT: Viewport = {
  latitude: 48.864716,
  longitude: 2.349014,
  zoom: 10
};

type Props = InteractiveMapProps & {
  defaultViewport: any;
  height: number;
};
type State = {
  viewport: any;
  pristine: boolean;
};

export default class MapView extends PureComponent<Props, State> {
  static defaultProps = {
    height: 400
  };

  state = {
    pristine: true,
    viewport: {
      width: '100%',
      ...defaults(this.props.defaultViewport, DEFAULT_VIEWPORT)
    }
  };

  mapRef = React.createRef();

  goToGeoPoint = (geoPoint: Geopoint) => {
    const {coordinates} = geoPoint;
    const [longitude, latitude] = coordinates;
    this.setState(({viewport}) => ({
      viewport: {...viewport, longitude, latitude, zoom: 14}
    }));
  };

  handleViewportChange = (viewport: Viewport) => {
    this.setState({viewport, pristine: false});
  };

  handleLoad = () => {
    if (!this.mapRef.current) {
      return;
    }
    const node: ReactMapGL = this.mapRef.current;
    const map = node.getMap();
    const locale = 'fr';
    map.setLayoutProperty('country-label-lg', 'text-field', ['get', `name_${locale}`]);
  };

  render() {
    const {children, height} = this.props;
    // const {pristine, viewport} = this.state;
    // console.warn('render', {center, viewport});
    return (
      <MapContainer>
        <Map
          ref={this.mapRef}
          {...{...this.state.viewport, height}}
          onViewportChange={this.handleViewportChange}
          onLoad={this.handleLoad}
          mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
          // mapStyle="mapbox://styles/mapbox/streets-v10"
          mapStyle={MAP_STYLE}
        >
          {children}
        </Map>
      </MapContainer>
    );
  }
}
