// @source https://github.com/ant-design/ant-design/tree/master/components/form

import {Form as AntDesignForm} from 'antd';
import {FormListProps as AntDesignFormListProps} from 'antd/lib/form/FormList';
import React, {FunctionComponent} from 'react';
import './style/form.less';

const {List: AntDesignFormList} = AntDesignForm;

export type FormListProps = AntDesignFormListProps;

export const FormList: FunctionComponent<FormListProps> = ({...otherProps}) => {
  return <AntDesignFormList {...otherProps} />;
};
