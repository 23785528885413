import styled from 'styled-components';

export default styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  min-width: 0;
  /* flex: 0 0 auto; */
  .ant-form-item {
    /* margin-right: 1.71429rem; // qonto */
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
`;
