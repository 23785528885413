import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout';
import PrivateRoute from 'src/routers/helpers/PrivateRoute';
import ApplicationList from 'src/views/applications/ApplicationList';
import EnrollmentList from 'src/views/enrollments/EnrollmentList';
import NotFoundPage from 'src/views/errors/NotFoundPage';
import MissionForm from 'src/views/missions/MissionForm';
import MissionList from 'src/views/missions/MissionList';
import MissionView from 'src/views/missions/MissionView';
import TimesheetList from 'src/views/timesheets/TimesheetList';
import InvoiceList from 'src/views/invoices/InvoiceList';
import WorkPositionForm from 'src/views/work/WorkPositionForm';
import WorkPositionList from 'src/views/work/WorkPositionList';

const MissionRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/work/missions" component={MissionList} layout={AppLayout} />
    <PrivateRoute path="/work/missions/create" component={MissionForm} layout={AppLayout} />
    <PrivateRoute path="/work/missions/:id/edit" component={MissionForm} layout={AppLayout} />
    <PrivateRoute path="/work/missions/:id/view" component={MissionView} layout={AppLayout} />
    <PrivateRoute exact path="/work/applications" component={ApplicationList} layout={AppLayout} />
    <PrivateRoute exact path="/work/enrollments" component={EnrollmentList} layout={AppLayout} />
    <PrivateRoute exact path="/work/timesheets" component={TimesheetList} layout={AppLayout} />
    <PrivateRoute exact path="/work/invoices" component={InvoiceList} layout={AppLayout} />
    <PrivateRoute exact path="/work/positions" component={WorkPositionList} layout={AppLayout} />
    <PrivateRoute path="/work/positions/create" component={WorkPositionForm} layout={AppLayout} />
    <PrivateRoute path="/work/positions/:id/edit" component={WorkPositionForm} layout={AppLayout} />
    <Route component={NotFoundPage} />
  </Switch>
);

export const breadcrumbs = {
  '/work/missions': {label: 'Missions', icon: 'solution'},
  '/work/missions/create': {label: 'Create', icon: 'edit'},
  '/work/missions/:id/edit': {label: 'Edit', icon: 'edit'},
  '/work/missions/:id/view': {label: 'View', icon: 'search'},
  '/work/applications': {label: 'Candidatures', icon: 'file-search'},
  '/work/enrollments': {label: 'Inscriptions', icon: 'file-protect'},
  '/work/timesheets': {label: 'Timesheets', icon: 'file-done'},
  '/work/invoices': {label: 'Factures', icon: 'euro'},
  '/work/positions': {label: 'Postes', icon: 'solution'},
  '/work/positions/create': {label: 'Create', icon: 'edit'},
  '/work/positions/:id/edit': {label: 'Edit', icon: 'edit'}
};

MissionRoutes.breadcrumbs = breadcrumbs;

export default MissionRoutes;
