import {SizeType as BaseSizeType} from 'antd/lib/config-provider/SizeContext';
import {Size} from 'src/config/theme';

export type SizeType = Size;
export type CombinedSizeType = BaseSizeType | SizeType;

export const SHORTHANDS: Record<NonNullable<BaseSizeType>, SizeType> = {
  small: 'sm',
  middle: 'md',
  large: 'lg'
};

export const getSizeClassName = (size: NonNullable<CombinedSizeType> = 'md', prefix = ''): string => {
  return `${prefix}${SHORTHANDS[size as NonNullable<BaseSizeType>] ?? size}`;
};

type SizeProps = {
  size: CombinedSizeType;
  className: string | undefined;
};
export const mapSizeProps = ({size, className = ''}: Partial<SizeProps>, prefix = ''): SizeProps => ({
  size,
  className: `${className} ${getSizeClassName(size, prefix)}`.trim()
});
