import {Button, Icon, Popconfirm} from 'antd';
import React, {FunctionComponent, useCallback} from 'react';
import MapPin from 'src/components/map/MapPin';
import MapView, {Marker} from 'src/components/map/MapView';
import {HOST_STORAGE_URL} from 'src/config/env';
import {LEGAL_FORM_LABELS, LEGAL_STATUS_LABELS} from 'src/config/labels';
import {actions as workersActions} from 'src/store/modules/workers';
import {Worker} from '@joonca/typings';
import {useActions} from '@joonca/library/src/utils/redux';
import styled from 'styled-components';

const ButtonGroup = Button.Group;
const ICON_PROPS = {
  success: {type: 'check-circle', theme: 'twoTone', twoToneColor: 'limegreen'},
  warn: {type: 'exclamation-circle', theme: 'twoTone', twoToneColor: 'orange'}
};

type Props = {worker: Worker};

const WorkerInfoPane: FunctionComponent<Props> = ({worker}) => {
  const {updateWorker} = useActions(workersActions, []);

  const validateIdentity = useCallback(async () => {
    await updateWorker(
      {
        _sid: worker._sid,
        'legalIdentity.isVerified': true,
        'legalIdentity.verificationStatus': 'success',
        'legalIdentity.verificationDate': new Date(),
        'legalIdentity.verificationError': ''
      },
      {query: {population: {user: 1}}}
    );
  }, [worker._sid, updateWorker]);

  const invalidateIdentity = useCallback(async () => {
    await updateWorker(
      {
        _sid: worker._sid,
        'legalIdentity.isVerified': false,
        'legalIdentity.verificationStatus': 'failure',
        'legalIdentity.verificationDate': new Date(),
        'legalIdentity.verificationError': 'INVALID_DOCUMENT'
      },
      {query: {population: {user: 1}}}
    );
  }, [worker._sid, updateWorker]);

  const {user, homePlace} = worker;
  const [longitude, latitude] = homePlace.location.coordinates;

  return (
    <div>
      <h3>Domicile</h3>
      <ul>
        <li>
          <Label>Domicile:</Label>
          <Value>{`${worker.homePlace.streetNumber} ${worker.homePlace.route}, ${worker.homePlace.postalCode}, ${worker.homePlace.locality}`}</Value>
        </li>
      </ul>
      <div style={{width: '400px', marginBottom: '12px'}}>
        <MapView defaultViewport={{longitude, latitude, zoom: 5}} height={200}>
          <Marker longitude={longitude} latitude={latitude}>
            <MapPin size={20} />
          </Marker>
        </MapView>
      </div>
      <h3>Statut</h3>
      <ul>
        <li>
          <Label>Etat:</Label>
          <Value>{LEGAL_STATUS_LABELS[worker.legalStatus] || worker.legalStatus}</Value>
        </li>
        {worker.legalEntity && worker.legalEntity.number ? (
          <>
            <li>
              <Label>Forme:</Label>
              <Value>{LEGAL_FORM_LABELS[worker.legalEntity.form] || worker.legalEntity.form}</Value>
            </li>
            <li>
              <Label>Numéro:</Label>
              <Value>
                <a
                  href={`https://www.societe.com/cgi-bin/fiche?rncs=${worker.legalEntity.number.slice(0, -5)}`}
                  target="_blank"
                >
                  {worker.legalEntity.number}
                </a>
              </Value>
            </li>
            <li>
              <Label>Validation:</Label>
              <Value>
                <Icon {...ICON_PROPS[worker.legalEntity.isVerified ? 'success' : 'warn']} />{' '}
                {worker.legalEntity.isVerified ? 'Oui' : 'Non'}
              </Value>
            </li>
          </>
        ) : null}
      </ul>
      <h3>Identité</h3>
      <ul>
        <li>
          <Label>Etat:</Label>
          <Value>
            {worker.legalIdentity && worker.legalIdentity.document ? 'Renseigné' : 'Non renseigné'}
          </Value>
        </li>
        {worker.legalIdentity && worker.legalIdentity.document ? (
          <>
            <li>
              <Label>Document:</Label>
              <Value>
                <a
                  href={`${HOST_STORAGE_URL}/files/contents/${worker.legalIdentity.document}`}
                  target="_blank"
                >
                  {worker.legalIdentity.document}
                </a>
              </Value>
            </li>
            <li>
              <Label>Validation:</Label>
              <Value>
                <Icon {...ICON_PROPS[worker.legalIdentity.isVerified ? 'success' : 'warn']} />{' '}
                {worker.legalIdentity.isVerified ? 'Oui' : 'Non'}
                <ButtonGroup style={{marginLeft: 12}}>
                  <Popconfirm
                    title="Are you sure?"
                    placement="topRight"
                    onConfirm={validateIdentity}
                    okText="Validate"
                    cancelText="Cancel"
                  >
                    <Button size="small" ghost type="primary" icon="check" />
                  </Popconfirm>
                  <Popconfirm
                    title="Are you sure?"
                    placement="topRight"
                    onConfirm={invalidateIdentity}
                    okText="Invalidate"
                    okType="danger"
                    cancelText="Cancel"
                  >
                    <Button size="small" ghost type="primary" icon="cross" />
                  </Popconfirm>
                </ButtonGroup>
              </Value>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default WorkerInfoPane;

const Label = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  padding-right: 4px;
`;

const Value = styled.span``;
