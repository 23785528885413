// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField, localeSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {Popconfirm, Tag, Tooltip} from 'antd';
import {ColumnProps} from 'antd/lib/table';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as organizationsActions} from 'src/store/modules/organizations';
import {State} from 'src/store/reducers';
import {Organization} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

const ORGANIZATION_SEARCH_KEYS = [
  '_id',
  '_sid',
  'name',
  'legal.name',
  'legal.form',
  'legal.sector',
  'position.name',
  'address.postalCode'
];

type Props = {};
const OrganizationList: FunctionComponent<Props> = () => {
  const {fetchOrganizations, deleteOrganization} = useActions(organizationsActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const organizations = useSelector<State, Array<Organization>>((state) => state.organizations.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchOrganizations({}, {query: {population: {user: 1}}})]),
    [fetchOrganizations]
  );

  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnProps<Organization>[]>(
    () => [
      {
        title: 'legalName',
        dataIndex: ['legal', 'name'],
        sorter: localeSorterForField('legal.name')
      },
      {
        title: 'legalNumber',
        dataIndex: ['legal', 'number'],
        sorter: localeSorterForField('legal.number'),
        render: (value = '') => (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.societe.com/cgi-bin/fiche?rncs=${value.slice(0, 9)}`}
          >
            {value}
          </a>
        )
      },
      {
        title: 'legalForm',
        dataIndex: ['legal', 'form'],
        filters: [{text: 'SARL', value: 'SARL'}],
        onFilter: (value, record) => record.legal.form === value,
        render: (value) => <Tag color="blue">{value}</Tag>
      },
      {
        title: 'legalSector',
        dataIndex: ['legal', 'sector'],
        sorter: localeSorterForField('legal.sector')
      },
      {
        title: 'postalCode',
        dataIndex: ['address', 'postalCode'],
        sorter: localeSorterForField('address.postalCode')
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('organizations')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <Tooltip title="Voir" placement="bottom">
                <LinkButton disabled ghost type="primary" icon="search" to={`/organizations/${_sid}/view`} />
              </Tooltip>
              <Tooltip title="Editer" placement="bottom">
                <LinkButton disabled ghost type="primary" icon="edit" to={`/organizations/${_sid}/edit`} />
              </Tooltip>
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteOrganization({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="close" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteOrganization, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon="plus" to="/organizations/create">
          Add Organization
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={organizations}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={ORGANIZATION_SEARCH_KEYS}
        />
      </TableContent>
    </TableLayout>
  );
};

export default OrganizationList;
