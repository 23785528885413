export const assets = new Map<string, Promise<unknown>>();

// @docs https://webpack.js.org/guides/dependency-management/#requirecontext
// @docs https://webpack.js.org/api/module-methods/
const requireContext = require.context('.', true, /\.json$/, 'lazy-once');
const availableAssets = requireContext.keys();
// console.dir({availableAssets});

export const importAsset = async <T = unknown>(path: string): Promise<T> => {
  const asset = `./${path}.json`;
  if (!availableAssets.includes(asset)) {
    throw new Error(`Asset with path "${path}" not found`);
  }
  if (assets.has(path)) {
    return assets.get(path) as Promise<T>;
  }
  console.time(`importAsset("${path}")`);
  const promise = requireContext(asset);
  console.timeEnd(`importAsset("${path}")`);
  assets.set(path, promise);
  return await promise;
};
