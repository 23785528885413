import React from 'react';
import {HOST_STORAGE_URL} from 'src/config/env';
import {Avatar} from 'antd';
import styled from 'styled-components';

export {Marker} from 'react-map-gl';

const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    margin-right: 8px;
    vertical-align: middle;
    > img {
      object-fit: cover;
    }
  }
`;
type Props = {
  user: {[s: string]: any};
  size?: number | ('small' | 'large');
};

export default function Image(props: Props) {
  const {user, size = 'small', ...otherProps} = props;
  if (user.avatar) {
    return (
      <StyledAvatar
        size={size}
        src={`${HOST_STORAGE_URL}/files/contents/${user.avatar}`}
        alt="avatar"
        {...otherProps}
      />
    );
  }
  if (user.firstName && user.lastName) {
    return (
      <StyledAvatar size={size} alt="avatar" style={{backgroundColor: '#7265e6'}} {...otherProps}>
        {`${user.firstName.substr(0, 1)}${user.lastName.substr(0, 1)}`}
      </StyledAvatar>
    );
  }
  return (
    <StyledAvatar size={size} alt="avatar" style={{backgroundColor: '#7265e6'}} {...otherProps}>
      ?
    </StyledAvatar>
  );
}
