// @source https://github.com/ant-design/ant-design/tree/master/components/radio

import {Timeline as AntDesignTimeline} from 'antd';
import {TimeLineItemProps as AntDesignTimelineItemProps} from 'antd/lib/timeline';
import React, {FunctionComponent} from 'react';

const {Item: AntDesignTimelineItem} = AntDesignTimeline;

export type TimelineItemProps = AntDesignTimelineItemProps;

export const TimelineItem: FunctionComponent<TimelineItemProps> = ({...otherProps}) => {
  return <AntDesignTimelineItem {...otherProps} />;
};
