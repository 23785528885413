import {Steps as BaseSteps} from 'antd';
import styled from 'styled-components';

const Steps = styled(BaseSteps)`
  &.ant-steps {
    margin: 0 auto 40px;
    max-width: 600px;
  }
`;

export const Step = BaseSteps.Step; // eslint-disable-line

export default Steps;
