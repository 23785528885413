import React, {PureComponent} from 'react';
import {Cascader} from 'antd';
import cascaderOptions from 'src/assets/rome/arbo_domaine_professionnel_cascader.json';

// const includes = (string, search) =>
//   String(string)
//     .toLowerCase()
//     .indexOf(search.toLowerCase()) > -1;
const isString = (maybeString) => typeof maybeString === 'string';

export default class CategoryInput extends PureComponent {
  filter = (inputValue, path) =>
    path.some((option) => String(option.label).includes(inputValue) || String(option.value).includes(inputValue));

  onChange = (value, selectedOptions) => {
    const {onChange} = this.props;
    onChange(value.pop(), selectedOptions);
  };

  displayRender = (labels, selectedOptions) =>
    labels.map((label, i) => {
      const option = selectedOptions[i];
      if (i === labels.length - 1) {
        return (
          <span key={option.value}>
            {option.value} - {label}
          </span>
        );
      }
      return '';
    });

  render() {
    const {onChange, value: givenValue, ...otherProps} = this.props;
    const value = isString(givenValue) ? [givenValue.slice(0, 1), givenValue.slice(0, 3)] : givenValue;
    return (
      <Cascader
        options={cascaderOptions}
        onChange={this.onChange}
        placeholder="Please select"
        showSearch={{filter: this.filter}}
        displayRender={this.displayRender}
        changeOnSelect
        value={value}
        {...otherProps}
      />
    );
  }
}
