import {CloseCircleOutlined} from '@ant-design/icons';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import {ColumnType} from 'antd/lib/table';
import {get} from 'lodash';
import React, {FunctionComponent, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Document, User, Worker} from '@joonca/typings';

type Props = {
  value: User;
  record: Document & {worker: Worker};
};

export const UserCell: FunctionComponent<Props> = () => {
  return <div></div>;
};

export const userColumn = <T extends Document & {user: User; worker: Worker}>(): ColumnType<T> => ({
  title: 'Jober',
  dataIndex: 'user',
  render: (user: User, {worker}): ReactNode => {
    // In case it has been deleted!
    if (!user) {
      return (
        <>
          <CloseCircleOutlined style={{color: presetPalettes['red'][4]}} />
          &nbsp;Unknown
        </>
      );
    }
    const {firstName, lastName} = user;
    return (
      <>
        <Link to={`/workers/${worker._sid}/view`}>
          {firstName} {lastName}
        </Link>
        <div>
          <small>{get(user, 'username', 'N/A')}</small>
        </div>
      </>
    );
  }
});

export default userColumn;
