import React, {ReactNode} from 'react';
import {Popconfirm} from 'antd';
import {PopconfirmProps} from 'antd/lib/popconfirm';

const I18N_PROPS = {
  title: 'Êtes-vous bien certain?',
  okText: 'Supprimer',
  cancelText: 'Annuler'
};

type Props = Partial<PopconfirmProps> & {
  children: ReactNode;
};
export default function DeletePopConfirm({children, ...otherProps}: Props) {
  return (
    <Popconfirm placement="bottomRight" okType="primary" {...I18N_PROPS} {...otherProps}>
      {children}
    </Popconfirm>
  );
}
