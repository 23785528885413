// @source https://github.com/ant-design/ant-design/blob/master/components/date-picker/RangePicker.tsx

import React, {PureComponent} from 'react';
import {DatePicker} from 'antd';
import invariant from 'invariant';
import {isMoment} from 'moment';
import {isUndefined} from 'lodash';

const RangePicker = DatePicker.RangePicker; // eslint-disable-line prefer-destructuring

export default class DateRangePicker extends PureComponent {
  static getDerivedValue(value) {
    if (isUndefined(value)) {
      return [];
    }
    const {startDate, endDate} = value;
    invariant(isMoment(startDate) && startDate.isUtc(), 'Expected an utc moment date');
    invariant(isMoment(endDate) && endDate.isUtc(), 'Expected an utc moment date');
    return [startDate, endDate];
  }

  static setDerivedValue(value) {
    const [startDate, endDate] = value;
    return {startDate: startDate.utc().startOf('day'), endDate: endDate.utc().startOf('day')};
  }

  handleChange = (value) => {
    const {onChange} = this.props;
    if (onChange) {
      const {setDerivedValue} = this.constructor;
      onChange(setDerivedValue(value));
    }
  };

  render() {
    const {onChange, value, ...otherProps} = this.props;
    const {getDerivedValue} = this.constructor;
    return <RangePicker {...otherProps} value={getDerivedValue(value)} onChange={this.handleChange} />;
  }
}
