// @docs https://docs.mapbox.com/mapbox-gl-js/api/#map

import type {CameraOptions} from 'mapbox-gl';
import React, {
  forwardRef,
  HTMLProps,
  RefForwardingComponent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import ReactMapGL, {
  InteractiveMap,
  MapboxProps,
  MapLoadEvent,
  Marker,
  ViewportProps,
  ViewState
} from 'react-map-gl';
import MAP_STYLE from 'src/assets/mapbox/streets-v11.json';
import styled from 'styled-components';
import MapPin from './components/MapPin';

const {REACT_APP_MAPBOX_API_ACCESS_TOKEN: defaultAccessToken} = process.env;

export const defaultProps = {
  mapStyle: 'mapbox://styles/mapbox/streets-v11'
  // mapStyle: MAP_STYLE
};

const DEFAULT_VIEWPORT: ViewState = {
  latitude: 48.864716,
  longitude: 2.349014,
  zoom: 10
};

export type Props = HTMLProps<HTMLDivElement> &
  Omit<MapboxProps, 'width' | 'height' | 'longitude' | 'latitude'> & {
    width?: number | string;
    height?: number | string;
    locale?: string;
    viewport?: ViewState | null;
    flyMode?: boolean;
  };

const MapView: RefForwardingComponent<InteractiveMap | null, Props> = (
  {
    locale = 'fr',
    mapStyle = defaultProps.mapStyle,
    onLoad,
    viewport: propViewport,
    flyMode = true,
    width = '100%',
    height = '100%',
    ...otherProps
  },
  ref
) => {
  const [viewport, setViewport] = useState<ViewState>(propViewport || DEFAULT_VIEWPORT);
  const [showMapPin, setShowMapPin] = useState<boolean>(!!propViewport);
  const mapRef = useRef<InteractiveMap>(null);
  useImperativeHandle(ref, () => mapRef.current);

  const setLocale = useCallback(() => {
    if (!mapRef.current) {
      return;
    }
    const map = mapRef.current.getMap();
    map.setLayoutProperty('country-label', 'text-field', ['get', `name_${locale}`]);
    map.setLayoutProperty('poi-label', 'text-field', ['get', `name_${locale}`]);
    map.setLayoutProperty('settlement-label', 'text-field', ['get', `name_${locale}`]);
    map.setLayoutProperty('settlement-subdivision-label', 'text-field', ['get', `name_${locale}`]);
  }, [locale]);

  const handleLoad = useCallback((event: MapLoadEvent) => {
    setLocale();
    if (onLoad) {
      onLoad(event);
    }
  }, []);

  useEffect(() => {
    if (!mapRef.current || !propViewport) {
      return;
    }
    const map = mapRef.current.getMap();
    const {latitude, longitude, zoom} = propViewport;
    const cameraOptions: CameraOptions = {
      center: [longitude, latitude],
      zoom
    };
    map.once('moveend', () => {
      setViewport(propViewport);
      setShowMapPin(true);
    });
    setShowMapPin(false);
    map[flyMode ? 'flyTo' : 'jumpTo'](cameraOptions);
  }, [propViewport, flyMode]);

  const handleViewportChange = useCallback((viewState: ViewportProps) => {
    setViewport(viewState);
  }, []);

  return (
    <Map
      ref={mapRef}
      mapboxApiAccessToken={defaultAccessToken}
      mapOptions={{locale, interactive: true}}
      mapStyle={mapStyle}
      onLoad={handleLoad}
      onViewportChange={handleViewportChange}
      width={width}
      height={height}
      {...otherProps}
      {...viewport}
    >
      {showMapPin && propViewport ? (
        <Marker longitude={propViewport.longitude} latitude={propViewport.latitude}>
          <MapPin size={32} />
        </Marker>
      ) : null}
    </Map>
  );
};

export default forwardRef(MapView);

const Map = styled(ReactMapGL)`
  .mapboxgl-ctrl-logo {
    opacity: 0.25;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    opacity: 0.25;
    font-size: 12px;
  }
  .mapbox-improve-map {
    display: none;
  }
`;
