/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {FunctionComponent, useMemo} from 'react';
import {Avatar as BaseAvatar} from 'antd';
import {AvatarProps as BaseAvatarProps} from 'antd/lib/avatar';
import styled from 'styled-components';
import {colors, Size, Theme, Contrast} from 'src/config/theme';

export type AvatarProps = Omit<BaseAvatarProps, 'size'> & {
  size?: number | Size;
  theme?: Theme;
  contrast?: Contrast;
  type?: 'default' | 'primary' | 'secondary';
};

export const Avatar: FunctionComponent<AvatarProps> = ({
  className = '',
  size,
  type = 'default',
  // theme = 'primary',
  // contrast = 400,
  ...otherProps
}) => {
  return (
    <StyledAvatar
      // @ts-ignore-next-line
      size={size}
      className={`${className} ant-avatar-${size} ant-avatar-${type}`.trim()}
      shape="square"
      // theme={theme}
      // contrast={contrast}
      {...otherProps}
    />
  );
};

const StyledAvatar = styled(BaseAvatar)<AvatarProps>`
  &.ant-avatar {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey100);
    background-color: var(--grey200);
    /* background-color: ${({theme, contrast}) => `var(--${theme}${contrast})`}; */
    &.ant-avatar-primary {
      background-color: ${colors.primary};
    }
    &.ant-avatar-secondary {
      background-color: ${colors.secondary};
    }
    &.ant-avatar-xl {
      width: 105px;
      height: 105px;
      line-height: 105px;
      .ant-avatar-string {
        font-size: 52px;
        font-weight: 500;
      }
    }
  }
`;

export const getOrganizationInitials = ({name}: {name?: string}): string =>
  name ? name.split(' ').reduce((soFar, value) => `${soFar}${value.slice(0, 1)}`, '') : '?';

export type OrganizationAvatarProps = AvatarProps & {
  organization: {name?: string};
};

export const OrganizationAvatar: FunctionComponent<OrganizationAvatarProps> = ({
  organization,
  ...otherProps
}) => {
  const initials = useMemo<string>(() => getOrganizationInitials(organization), [organization]);
  return <Avatar {...otherProps}>{initials}</Avatar>;
};

/* background-color: var(--blue500);
      background-color: var(--purple500);
      background-color: ${presetPalettesgeekblue[5]}; */
/*
.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['size', 'theme', 'contrast'].includes(prop) && defaultValidatorFn(prop)
})
*/
