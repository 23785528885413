// @docs https://ant.design/components/layout/
import React, {Component} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Layout, message} from 'antd';
// import type {Node} from 'react';
// import type {Dispatch, State as StoreState} from 'src/store/typings';
import {actions as authActions} from 'src/store/modules/auth';
import AppSidebar from './components/AppSidebar';
import AppBreadcrumb from './components/AppBreadcrumb';
import AppHeader from './components/AppHeader';
// @types

// const Footer = styled(Layout.Footer)`
//   color: #fff;
//   font-size: 12px;
//   font-weight: 200;
// `;

const Breadcrumb = styled(AppBreadcrumb)`
  &.ant-breadcrumb {
    margin: 16px 0;
  }
`;

const Content = styled(Layout.Content)`
  padding: 0 16px;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  overflow-y: auto;
`;

const InnerContent = styled('div')`
  padding: 24px;
  background: #fff;
  flex: 1;
  /* padding: 24px 24px 0;
  display: flex;
  flex-direction: column; */
`;

type Props = {
  children?: Node;
  actions: {
    loginWithSession: Function;
  };
};

export class AppLayout extends Component<Props> {
  componentDidMount = () => {
    const {history} = this.props;
    const {loginWithSession} = this.props.actions;
    loginWithSession().catch((err) => {
      const text = err.body ? err.body.error : '';
      message.error(`Login failed failed${text ? ` : ${text}` : ''}`);
      history.replace('/signin');
    });
  };

  render() {
    const {children} = this.props;
    // console.warn({user: this.props.user});
    return (
      <Layout>
        <AppSidebar />
        <Layout>
          <AppHeader />
          <Content>
            <Breadcrumb />
            <InnerContent>{children}</InnerContent>
          </Content>
          {/* <Footer>Footer App</Footer> */}
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  // mapStateToProps
  (state: StoreState) => ({
    user: state.user
  }),
  // mapDispatchToProps
  (dispatch: Dispatch) => ({
    actions: bindActionCreators(authActions, dispatch)
  })
)(AppLayout);
