import Icon, {HomeOutlined} from '@ant-design/icons';
import {computePathSnippetsUrls} from '@joonca/library/src/utils/location';
import {Breadcrumb} from 'antd';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {breadcrumbs as fileBreadcrumbs} from 'src/routers/routes/FileRoutes';
import {breadcrumbs as laborBreadcrumbs} from 'src/routers/routes/LaborRoutes';
import {breadcrumbs as missionBreadcrumbs} from 'src/routers/routes/MissionRoutes';
import {breadcrumbs as organizationBreadcrumbs} from 'src/routers/routes/OrganizationRoutes';
import {breadcrumbs as userBreadcrumbs} from 'src/routers/routes/UserRoutes';
import {breadcrumbs as workerBreadcrumbs} from 'src/routers/routes/WorkerRoutes';
import styled from 'styled-components';

const BreadcrumbItem = styled(Breadcrumb.Item)`
  text-decoration: none !important;
`;

const BreadcrumbIcon = styled(Icon)`
  padding: 12px 6px 12px 0;
`;

type Props = {};

const AppBreadcrumb: FunctionComponent<Props> = () => {
  const {pathname} = useLocation();
  const pathSnippetsUrls = useMemo(() => computePathSnippetsUrls(pathname), [pathname]);
  const handleItemClick = useCallback(() => {}, []);
  // @NOTE crashes with "ReferenceError: Cannot access 'breadcrumbs' before initialization" when out of scope
  const breadcrumbNameMap = {
    ...userBreadcrumbs,
    ...workerBreadcrumbs,
    ...organizationBreadcrumbs,
    ...laborBreadcrumbs,
    ...missionBreadcrumbs,
    ...fileBreadcrumbs
  };
  const extraBreadcrumbItems = pathSnippetsUrls.map((url) => {
    if (!breadcrumbNameMap[url]) {
      return null;
    }
    const {label, icon} = breadcrumbNameMap[url];
    return (
      <BreadcrumbItem key={url} href={url} onClick={handleItemClick}>
        {icon ? <BreadcrumbIcon component={icon} /> : null}
        {label}
      </BreadcrumbItem>
    );
  });
  const breadcrumbItems = [
    <BreadcrumbItem key="home" href="/" onClick={handleItemClick}>
      <BreadcrumbIcon component={HomeOutlined} />
    </BreadcrumbItem>
  ].concat(extraBreadcrumbItems);
  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
};

export default AppBreadcrumb;
