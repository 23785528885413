// @source https://github.com/ant-design/ant-design/tree/master/components/typography
import {Typography} from 'antd';
import {TitleProps as AntDesignTitleProps} from 'antd/lib/typography/Title';
import React, {FunctionComponent, SyntheticEvent} from 'react';
import {Helmet} from 'react-helmet';
import './style/title.less';

const {Title: AntDesignTitle} = Typography;

export type TitleProps = AntDesignTitleProps & {
  helmet?: string;
  onClick?: (ev: SyntheticEvent<Element, MouseEvent>) => void;
};

export const Title: FunctionComponent<TitleProps> = ({children, helmet, ...otherProps}) => {
  return (
    <AntDesignTitle {...otherProps}>
      {children}
      {helmet ? (
        <Helmet>
          <title>{helmet}</title>
        </Helmet>
      ) : null}
    </AntDesignTitle>
  );
};
