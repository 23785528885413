import type {Document} from '@joonca/typings';
import React, {ReactElement, SyntheticEvent} from 'react';
import {HOSTNAME_ENDPOINTS} from 'src/config/env';
import {ManOutlined, WomanOutlined} from '@ant-design/icons';
import {GENDER_LABELS} from 'src/app/config';

export const dateTableRender = (value: Date | string): ReactElement => {
  const localeDate = new Date(value).toLocaleString();
  const [date, time] = localeDate.split(', ');
  return (
    <span style={{display: 'inline-block', textAlign: 'center', fontSize: '12px'}}>
      {date}
      <br />
      {time}
    </span>
  );
};

export const utcDateTableRender = (value: Date | string): ReactElement => {
  const dateObject = new Date(value);
  const localeDate = new Date(dateObject.getTime() + dateObject.getTimezoneOffset() * 6e4).toLocaleString();
  const [date, time] = localeDate.split(', ');
  return (
    <span style={{display: 'inline-block', textAlign: 'center', fontSize: '12px'}}>
      {date}
      <br />
      {time}
    </span>
  );
};

export const genderTableRender = (value: 'male' | 'female'): ReactElement => {
  return (
    <span style={{display: 'inline-block', textAlign: 'center', fontSize: '14px'}}>
      {value === 'male' ? <ManOutlined /> : <WomanOutlined />}
      {/* &nbsp;{GENDER_LABELS[value]} */}
    </span>
  );
};

export const smallTableRender = ({fallback = '', style = {}}) => (value: string) => {
  return (
    <span style={{display: 'inline-block', textAlign: 'left', fontSize: '12px', ...style}}>
      {value || fallback}
    </span>
  );
};

export const debugOnCell = <T extends Document>(
  path: string,
  endpoint: keyof typeof HOSTNAME_ENDPOINTS = 'api'
) => (record: T) => ({
  onClick: (ev: SyntheticEvent<Element, MouseEvent>) => {
    if (navigator.platform === 'MacIntel' && ev.nativeEvent.metaKey /* || ev.ctrlKey */) {
      setTimeout(() => {
        window.open(`${HOSTNAME_ENDPOINTS[endpoint]}/${path}/${record._sid}`);
      }, 0);
    }
  }
});
