import React, {FunctionComponent, ComponentType} from 'react';
import {RouteProps, RouteComponentProps} from 'react-router-dom';
import {Redirect} from 'react-router-dom';
// import { useSelector } from 'react-redux';
import RouteWithLayout from './RouteWithLayout';

type Props = RouteProps & {
  layout?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
};

const PrivateRoute: FunctionComponent<Props> = (props) => {
  // Check signed out
  const sessionId = localStorage.getItem('sessionId');
  // const auth = useSelector<State, State['auth']>(state => state.auth);
  if (!sessionId) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: {
            from: location,
            message: 'You need to sign in'
          }
        }}
      />
    );
  }
  return React.createElement(RouteWithLayout, props);
};

export default PrivateRoute;
