import {Button, Result} from 'antd';
import React, {FunctionComponent, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

type Props = {};

const NotFoundPage: FunctionComponent<Props> = () => {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <StyledResult
      style={{color: 'white'}}
      status="404"
      title="404"
      // subTitle="Sorry, the page you visited does not exist."
      subTitle="Désolé, la page recherchée n'existe pas."
      extra={
        <Button type="primary" onClick={onClick}>
          Retour
        </Button>
      }
    />
  );
};

const StyledResult = styled(Result)`
  .ant-result-title {
    color: white;
  }
  .ant-result-subtitle {
    color: white;
  }
`;

export default NotFoundPage;
