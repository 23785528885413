// @preview https://preview.pro.ant.design/exception/404
// @docs https://ant.design/components/layout/
// @source https://github.com/ant-design/ant-design-pro/blob/master/src/components/GlobalHeader/RightContent.js

import React, {FunctionComponent, ReactNode, useState, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout, Spin, Menu, Icon, Dropdown} from 'antd';
import {useActions} from '@joonca/library';

import {actions as authActions} from 'src/store/modules/auth';
import UserAvatar from 'src/components/content/UserAvatar';
import LocaleDropdown from 'src/components/menu/LocaleDropdown';
import {State} from 'src/store/reducers';
import {User} from '@joonca/typings';
import {gradientColors} from 'src/config/theme';

type Props = {
  children?: ReactNode;
};

const Appheader: FunctionComponent<Props> = () => {
  const {logout} = useActions(authActions, []);
  const user = useSelector<State, User>((state) => state.auth.user);
  const history = useHistory();

  const handleMenuClick = useCallback(
    async (menuItem) => {
      const {key} = menuItem;
      if (key === 'logout') {
        await logout();
        history.replace('/signin');
      }
    },
    [logout]
  );

  const dropdownOverlay = useMemo(() => {
    if (!user) {
      return null;
    }
    return (
      <Menu selectedKeys={[]} onClick={handleMenuClick} style={{width: 200}}>
        <MenuItem key="userinfo">
          <Icon type="setting" />
          <Link to={`/users/edit/${user._sid}`}>Account Settings</Link>
        </MenuItem>
        <Menu.Divider />
        <MenuItem key="logout">
          <Icon type="logout" />
          <div>Logout</div>
        </MenuItem>
      </Menu>
    );
  }, [user, handleMenuClick]);

  return (
    <Header>
      <LeftContent>Backoffice</LeftContent>
      <RightContent>
        {user && user.username ? (
          <Dropdown overlay={dropdownOverlay}>
            <span style={{marginLeft: 16, marginRight: 8}}>
              <UserAvatar user={user} />
              <span>
                {user.firstName} {user.lastName}
              </span>
            </span>
          </Dropdown>
        ) : (
          <Spin size="small" style={{marginLeft: 16, marginRight: 8}} />
        )}
        <LocaleDropdown />
      </RightContent>
    </Header>
  );
};

export default Appheader;

const MenuItem = styled(Menu.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled(Layout.Header)`
  background: ${gradientColors[0]};
  color: #fff;
  display: flex;
  flex-direction: row;
`;

const LeftContent = styled.div`
  font-size: 24px;
  font-weight: 300;
  align-self: flex-start;
  flex: 1;
`;

const RightContent = styled.div`
  align-self: flex-end;
`;
