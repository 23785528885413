// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders
// @source https://github.com/ant-design/ant-design/tree/master/components/input

import {Input as BaseInput} from 'antd';
import {InputProps as BaseInputProps} from 'antd/lib/input';
import {createElement, forwardRef, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

export const sizeMixin = (size: string, prefix = 'input'): FlattenInterpolation<unknown> => css`
  &.ant-${prefix}-${size} {
      /* padding: 6.4px 11px; */
      padding: 8px 13.75px; /* * +25% */
      font-size: 18px;
      .ant-input {
        font-size: 18px;
      }
    }
    &.ant-input-affix-wrapper {
      .ant-input-prefix {
          margin-right: 8px;
        }
    }
  }
`;

// @source https://github.com/ant-design/ant-design/blob/master/components/input/style/index.less
// @source https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less#L337
const StyledInput = styled(BaseInput)`
  /* &.ant-input {
    background: red;
  } */
  ${sizeMixin('xl')}
  &.ant-input-affix-wrapper {
    &.ant-input-affix-wrapper-lg {
      height: 40px;
      /* .ant-input {
        line-height: 40px;
      } */
    }
  }
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

export type InputProps = Omit<BaseInputProps, 'size'> & {
  size?: SizeType;
};

const InputWithoutRef: RefForwardingComponent<typeof BaseInput, InputProps> = (props, ref) => {
  // const classPrefix = props.prefix || props.suffix ? 'ant-input-affix-wrapper-' : 'ant-input-';
  return createElement(StyledInput, {
    ref,
    ...props,
    ...mapSizeProps(props, 'ant-input-')
  } as BaseInputProps);
};
export const Input = forwardRef(InputWithoutRef);

// Password

export const Password = styled(BaseInput.Password)`
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
  ${sizeMixin('xl', 'input-password')}
`;

// Search

export const Search = styled(BaseInput.Search)`
  /* &.ant-input {
    background: red;
  } */
  /* .ant-input-prefix > .anticon {
    color: #a9a9a9;
  } */
`;

// TextArea

export const StyledTextArea = styled(BaseInput.TextArea)`
  ${sizeMixin('xl')}
`;

const TextAreaWithoutRef: RefForwardingComponent<typeof BaseInput.TextArea, InputProps> = (props, ref) => {
  // const classPrefix = props.prefix || props.suffix ? 'ant-input-affix-wrapper-' : 'ant-input-';
  return createElement(StyledTextArea, {
    ref,
    ...props,
    ...mapSizeProps(props, 'ant-input-')
  } as BaseInputProps);
};
export const TextArea = forwardRef(TextAreaWithoutRef);

// Interface

export default Object.assign(Input, {
  Password,
  TextArea,
  Search
});

/*
// Input
// ---
@input-height-base: 32px;
@input-height-lg: 40px;
@input-height-sm: 24px;
@input-padding-horizontal: @control-padding-horizontal - 1px;
@input-padding-horizontal-base: @input-padding-horizontal;
@input-padding-horizontal-sm: @control-padding-horizontal-sm - 1px;
@input-padding-horizontal-lg: @input-padding-horizontal;
@input-padding-vertical-base: round(
    (@input-height-base - @font-size-base * @line-height-base) / 2 * 10
  ) / 10 - @border-width-base;
@input-padding-vertical-sm: round((@input-height-sm - @font-size-base * @line-height-base) / 2 * 10) /
  10 - @border-width-base;
@input-padding-vertical-lg: round((@input-height-lg - @font-size-lg * @line-height-base) / 2 * 10) /
  10 - @border-width-base;
@input-placeholder-color: hsv(0, 0, 75%);
@input-color: @text-color;
@input-icon-color: @input-color;
@input-border-color: @border-color-base;
@input-bg: @component-background;
@input-number-hover-border-color: @input-hover-border-color;
@input-number-handler-active-bg: #f4f4f4;
@input-number-handler-hover-bg: @primary-5;
@input-number-handler-bg: @component-background;
@input-number-handler-border-color: @border-color-base;
@input-addon-bg: @background-color-light;
@input-hover-border-color: @primary-5;
@input-disabled-bg: @disabled-bg;
@input-outline-offset: 0 0;
@input-icon-hover-color: fade(@black, 85%);
*/

// // @flow
// // @docs https://ant.design/components/input/

// import React, {PureComponent} from 'react';
// // import type {Element} from 'react';
// import {Input, Icon} from 'antd';
// import styled from 'styled-components';
// import {fontSizes, colors, spacings} from '../../../common/vars';

// // const StyledTextField = styled.div`
// //   box-shadow: 5px 3px 18px rgba(0, 0, 0, 0.1);
// //   border-radius: 5px;
// //   background-color: ${colors.lightest};
// //   font-size: ${fontSizes.xxl};
// //   font-family: inherit;
// //   padding: 10px 5px;
// //   display: flex;
// //   align-items: center;
// //   color: ${props => (props.hasFocus ? colors.dark : colors.neutral)};

// //   & > input {
// //     border: none;
// //     background-color: transparent;
// //     width: 100%;
// //     flex: 1;
// //     color: ${colors.dark};
// //     padding: 6px 11px;

// //     &:focus {
// //       outline: none;
// //     }
// //   }
// //   & > svg {
// //     margin-right: 11px;
// //   }
// // `;

// const StyledInput = styled(Input)`
//   &.ant-input-affix-wrapper {
//     font-family: inherit;
//     box-shadow: 5px 3px 18px rgba(0, 0, 0, 0.1);
//     border-radius: 5px;
//     background-color: ${colors.lightest};
//     padding: 11px 5px;
//     /* display: flex; */
//     /* align-items: center; */

//     .ant-input-prefix,
//     .ant-input-suffix {
//       color: ${props => (props.hasFocus ? colors.dark : colors.neutral)};
//       &.ant-input-prefix {
//         margin-left: 5px;
//       }
//       &.ant-input-suffix {
//         margin-right: 5px;
//       }
//     }

//     input.ant-input {
//       font-family: inherit;
//       border: none;
//       background-color: transparent;
//       color: ${colors.dark};

//       &:not(:first-child) {
//         padding-left: 60px;
//       }
//       &:not(:last-child) {
//         padding-right: 40px;
//       }

//       &:focus {
//         outline: none;
//         box-shadow: none;
//       }

//       &.ant-input-lg {
//         font-size: ${fontSizes.xxl};
//         height: 48px;
//       }
//     }
//   }
// `;

// type Props = {
//   icon?: Element<any>;
// };

// type State = {
//   hasFocus: boolean;
// };

// class TextField extends PureComponent<Props, State> {
//   state = {
//     hasFocus: false
//   };

//   toggleFocus = () => {
//     this.setState(state => ({hasFocus: !state.hasFocus}));
//   };

//   render() {
//     const {icon, ...otherProps} = this.props;
//     const {hasFocus} = this.state;
//     return (
//       <StyledInput
//         size="large"
//         onFocus={this.toggleFocus}
//         onBlur={this.toggleFocus}
//         hasFocus={hasFocus}
//         {...otherProps}
//         // prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />}
//         // suffix={<Icon type="environment" style={{color: 'rgba(0,0,0,.25)'}} />}
//       />
//     );
//   }
// }

// export default TextField;
