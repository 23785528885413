import {green, grey, red} from '@ant-design/colors';
import {CloseCircleFilled, PlusCircleFilled} from '@ant-design/icons';
import {Tooltip} from 'antd';
import React, {FunctionComponent, ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {TimeRangeSelect, TimeRangeSelectProps, TimeRangeSelectValue} from './TimeRangeSelect';

const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';

export type TimeMultipleRangeSelectProps = Omit<TimeRangeSelectProps, 'value' | 'onChange'> & {
  value?: TimeRangeSelectValue[];
  onChange?: (value: TimeRangeSelectValue[]) => void;
  removeIcon?: ReactElement;
  addIcon?: ReactElement;
  spread?: number;
};

export const TimeMultipleRangeSelect: FunctionComponent<TimeMultipleRangeSelectProps> = ({
  onChange,
  value = [],
  hourStep = 1,
  minuteStep = 15,
  spread = 36e5 * 24,
  removeIcon = <CloseCircleFilled />,
  addIcon = <PlusCircleFilled />,
  ...otherProps
}) => {
  const [values, setValues] = useState<TimeRangeSelectValue[]>(value);
  const uniqueId = useRef<number>(0);
  const [extraRows, setExtraRows] = useState<string[]>([]);

  const handleChange = useCallback(
    (value: TimeRangeSelectValue, index: number) => {
      setValues((values) => {
        values[index] = value;
        const hasValidValues = !values.some((value) => !Array.isArray(value) || value.some(isUndefined));
        if (onChange && hasValidValues) {
          onChange(values);
        }
        return [...values];
      });
    },
    [onChange]
  );

  // @NOTE required to prevent infinite onChange loop when handler ref is broken
  const {current: memoizedHandleChangeCallbacks} = useRef<Record<number, TimeRangeSelectProps['onChange']>>(
    {}
  );
  const handleChangeAtIndex = useCallback(
    (index: number) => {
      if (!memoizedHandleChangeCallbacks[index]) {
        memoizedHandleChangeCallbacks[index] = (value: TimeRangeSelectValue): void =>
          handleChange(value, index);
      }
      return memoizedHandleChangeCallbacks[index];
    },
    [handleChange, memoizedHandleChangeCallbacks]
  );
  const handleDeleteRowAtIndex = useCallback(
    (index: number) => (): void => {
      setValues((values) => {
        values.splice(index, 1);
        return [...values];
      });
      setExtraRows((extraRows) => {
        extraRows.splice(index - 1, 1);
        return [...extraRows];
      });
    },
    []
  );

  const handleAddRow = useCallback(() => {
    setExtraRows((extraRows) => {
      extraRows.push(`${uniqueId.current}`);
      uniqueId.current += 1;
      return [...extraRows];
    });
  }, []);

  const duration = useMemo(
    () =>
      values.reduce((soFar, value) => {
        return soFar + (value && value[1] ? value[1] - value[0] : 0);
      }, 0),
    [values]
  );

  const durationLabel = useMemo(
    () => (duration === 864e5 ? '24h00' : new Date(duration).toISOString().slice(11, 16).replace(':', 'h')),
    [duration]
  );

  const canAddARow = duration < spread - minuteStep;

  return (
    <Container>
      <Row key={0}>
        <Number>#1</Number>
        <TimeRangeSelect
          minuteStep={minuteStep}
          onChange={handleChangeAtIndex(0)}
          value={values[0]}
          {...otherProps}
        />
        {canAddARow && extraRows.length === 0 ? (
          <Tooltip title="Ajouter plage">
            <AddIconButton onClick={handleAddRow}>{addIcon}</AddIconButton>
          </Tooltip>
        ) : null}
      </Row>
      {extraRows.map((value, index) => (
        <Row key={value}>
          <Number>#{index + 2}</Number>
          <TimeRangeSelect
            allowClear
            doesOverflow
            hourStep={hourStep}
            minuteStep={minuteStep}
            min={values
              .slice(0, index + 1)
              .reduce((soFar, value) => (value && value[1] ? Math.max(soFar, value[1] + 1) : soFar), 0)}
            max={(values[0] ? values[0][0] : 0) + spread}
            onChange={handleChangeAtIndex(index + 1)}
            value={values[index + 1]}
            {...otherProps}
          />
          <Tooltip title="Supprimer plage">
            <DeleteIconButton onClick={handleDeleteRowAtIndex(index + 1)}>{removeIcon}</DeleteIconButton>
          </Tooltip>
          {canAddARow && index === extraRows.length - 1 ? (
            <Tooltip title="Ajouter plage">
              <AddIconButton onClick={handleAddRow}>{addIcon}</AddIconButton>
            </Tooltip>
          ) : null}
        </Row>
      ))}
      <Row style={{justifyContent: 'space-between'}}>
        {duration > 0 ? <span>total: {durationLabel}</span> : null}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
`;

const Number = styled.span`
  margin-right: 1rem;
`;

const AddIconButton = styled.a.attrs({
  role: 'button',
  className: 'ant-time-select-add-range'
})`
  margin-left: 1rem;
  font-size: 1rem;
  color: ${grey[0]};
  &:hover {
    color: ${green.primary};
  }
`;

const DeleteIconButton = styled.a.attrs({
  role: 'button',
  className: 'ant-time-select-delete-range'
})`
  margin-left: 1rem;
  font-size: 1rem;
  color: ${grey[0]};
  &:hover {
    color: ${red.primary};
  }
`;

/*

<Form.List name="foo">
  {(fields, {add, remove}) => {
    return (
      <div>
        {fields.map((field, index) => (
          <Form.Item
            // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
            label={index === 0 ? 'Horaires' : ''}
            required={false}
            key={field.key}
          >
            <Form.Item
              {...field}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please input passenger's name or delete this field."
                }
              ]}
              noStyle
            >
              <Input placeholder="passenger name" style={{width: '60%'}} />
            </Form.Item>
            {fields.length > 1 ? (
              <MinusCircleOutlined
                className="dynamic-delete-button"
                style={{margin: '0 8px'}}
                onClick={() => {
                  remove(field.name);
                }}
              />
            ) : null}
          </Form.Item>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => {
              add();
            }}
            style={{width: '60%'}}
          >
            <PlusOutlined /> Add field
          </Button>
          <Button
            type="dashed"
            onClick={() => {
              add('The head item', 0);
            }}
            style={{width: '60%', marginTop: '20px'}}
          >
            <PlusOutlined /> Add field at head
          </Button>
        </Form.Item>
      </div>
    );
  }}
</Form.List>
*/
