import React, {PureComponent} from 'react';
// import {Button, LinkButton} from '@joonca/library';
// import {Link} from 'react-router-dom';
import {Select /* , Icon, Divider */} from 'antd';
import {fetch} from 'redux-rest-resource';
import {HOST_API_URL} from 'src/config/env';

const Option = Select.Option; // eslint-disable-line prefer-destructuring

export const QUERY_PROJECTION = {
  _id: 1,
  _sid: 1,
  'legal.name': 1,
  'legal.number': 1
};

export default class OrganizationSelect extends PureComponent {
  state = {
    organizations: []
  };

  async componentDidMount() {
    const res = await fetch(`${HOST_API_URL}/organizations`, {
      credentials: 'include',
      query: {projection: QUERY_PROJECTION}
    });
    const organizations = await res.json();
    this.setState({organizations});
  }

  filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  render() {
    const {filter, value, ...otherProps} = this.props;
    const {organizations} = this.state;
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={this.filterOption}
        value={organizations.length ? value : null}
        // dropdownRender={menu => (
        //   <div>
        //     {menu}
        //     <Divider style={{margin: '4px 0'}} />
        //     <LinkButton icon="plus" style={{padding: '8px', cursor: 'pointer'}} to="/work/organizations/create">
        //       Add item
        //     </LinkButton>
        //   </div>
        // )}
        {...otherProps}
      >
        {organizations.map((organization) => (
          <Option key={organization._id} value={organization._id}>
            {organization.legal.name} ({organization.legal.number})
          </Option>
        ))}
      </Select>
    );
  }
}
