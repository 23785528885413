import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import 'src/config/defaults';
import {RELEASE_NAME, NODE_ENV, IS_PROD} from 'src/config/env';
import App from 'src/containers/App';

if (IS_PROD) {
  // Enable sentry error reporting
  // @docs https://sentry.io/mgcrea/joonca-frontend/getting-started/javascript-react/
  Sentry.init({
    release: RELEASE_NAME,
    environment: NODE_ENV,
    dsn: 'https://295ea271786d405cbd69ae90002b346d@sentry.io/1385344'
  });
}

// $FlowFixMe
ReactDOM.render(<App />, document.getElementById('root'));
