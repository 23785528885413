// @source https://github.com/ant-design/ant-design/tree/master/components/form

import {Form as BaseForm} from 'antd';
import {InputProps} from 'antd/lib/input';
import React, {FunctionComponent, useMemo} from 'react';
import Input from './../Input/Input';
import {FormItemProps} from './Form';

const {Item} = BaseForm;
const {TextArea} = Input;

export type FormTextAreaProps = Omit<FormItemProps, 'name'> &
  Omit<InputProps, 'name' | 'required' | 'pattern'> & {
    name: string;
    required?: boolean;
    pattern?: RegExp;
  };

export const FormTextArea: FunctionComponent<FormTextAreaProps> = ({
  name,
  label,
  rules: propRules = [],
  required,
  pattern,
  placeholder,
  size = 'large',
  ...otherInputProps
}) => {
  const itemName = name && name.includes('.') ? name.split('.') : name;
  const rules = useMemo<FormItemProps['rules']>(
    () =>
      propRules
        .concat(required ? [{required: true, message: 'saisie requise'}] : [])
        .concat(pattern ? [{pattern, message: 'saisie invalide'}] : []),
    [pattern, propRules, required]
  );
  return (
    <Item label={label} name={itemName} rules={rules}>
      <TextArea size={size} placeholder={label ? label : placeholder} {...otherInputProps} />
    </Item>
  );
};
