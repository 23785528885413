// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders
// @source https://github.com/ant-design/ant-design/blob/master/components/select/index.tsx

import {TimePicker as BaseTimePicker} from 'antd';
import {Moment} from 'moment';
import {TimePickerProps as BaseTimePickerProps} from 'antd/lib/date-picker';
import React, {createElement, useCallback, forwardRef, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';

const {RangePicker} = BaseTimePicker;
// padding: 6.5px 11px 6.5px;

const toUTCStartOfDay = (date: Moment): Moment => date.utc().startOf('day');

const sizeMixin = (size: string): FlattenInterpolation<unknown> => css`
  &.ant-picker {
    &.ant-picker-${size} {
      padding: 6.5px 11px 6.5px;
      .ant-picker-input > input {
        font-size: 18px;
      }
    }
  }
`;

const StyledTimePicker = styled(BaseTimePicker)`
  /* &.ant-input {
    background: red;
  } */
  /* &.ant-select {
    &.ant-select-xl {
      font-size: 16px;
    }
    &.ant-select-single.ant-select-xl:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 40px;
      }
      .ant-select-selection-placeholder {
        line-height: 40px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 38px;
      }
    }
  } */
  ${sizeMixin('xl')}
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

export type TimePickerProps = BaseTimePickerProps & {
  size: SizeType;
};

const TimePicker: RefForwardingComponent<typeof BaseTimePicker, TimePickerProps> = (
  {onChange, ...otherProps},
  ref
) => {
  const handleChange = useCallback((date, string) => {
    if (onChange) {
      onChange(toUTCStartOfDay(date), string);
    }
  }, []);
  return <StyledTimePicker ref={ref} onChange={handleChange} {...otherProps} {...mapSizeProps} />;
  // return createElement(StyledTimePicker, {
  //   ref,
  //   ...props,
  //   ...mapSizeProps(props, 'ant-select-')
  // } as BaseTimePickerProps);
};

interface TimePickerExportInterface {
  (): typeof TimePicker;
  RangePicker: typeof BaseTimePicker.RangePicker;
}
const ExportedTimePicker = (forwardRef(TimePicker) as unknown) as TimePickerExportInterface;
ExportedTimePicker.RangePicker = RangePicker;
export default ExportedTimePicker;
