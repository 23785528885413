import {presetPalettes} from '@joonca/library/src/utils/typography';
import {Icon, Tag} from 'antd';
import {Moment} from 'moment';
import React from 'react';
import MomentDate from 'src/components/content/MomentDate';
import styled from 'styled-components';
// import {EnrollmentStatus} from 'src/typings/app/enrollmentStatusPlugin';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StaticTag = styled(Tag)`
  cursor: default !important;
  user-select: none;
`;
const TagWithIcon = styled.div`
  margin-bottom: 0.25rem;
  white-space: nowrap;
`;
const StyledDate = styled(MomentDate)`
  font-size: 10px;
  color: ${presetPalettes.grey[4]};
`;

// Status

type TimesheetOpenStatus = 'created' | 'corrected' | 'updated' | 'reverted';
type TimesheetClosedStatus = 'validated';
type TimesheetStatus = TimesheetOpenStatus | TimesheetClosedStatus;

const ENROLLMENT_STATUS_LABELS: {[key in TimesheetStatus]: string} = {
  created: 'créée',
  corrected: 'corrigée',
  updated: 'modifiée',
  reverted: 'restaurée',
  validated: 'validée'
};

const STATUS_DISPLAY_CONFIG: {[key in TimesheetStatus | 'default']: {iconType: string; tagColor: string}} = {
  created: {
    iconType: 'check-circle',
    tagColor: 'blue'
  },
  corrected: {
    iconType: 'exclamation-circle',
    tagColor: 'red'
  },
  updated: {
    iconType: 'check-circle',
    tagColor: 'volcano'
  },
  reverted: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  validated: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  default: {
    iconType: 'question-circle',
    tagColor: 'volcano'
  }
};

const getIconTypeForTimesheetStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].iconType : STATUS_DISPLAY_CONFIG.default.iconType;

const getTagColorForTimesheetStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].tagColor : STATUS_DISPLAY_CONFIG.default.tagColor;

type Props = {
  status: string;
  type?: 'worker' | 'client';
  date?: Date | Moment | string;
};

export default function TimesheetStatus({status, date}: Props) {
  const color = getTagColorForTimesheetStatus(status);
  const label = ENROLLMENT_STATUS_LABELS[status] || status;
  return (
    <Container>
      <TagWithIcon>
        <Icon
          type={getIconTypeForTimesheetStatus(status)}
          style={{color: presetPalettes[color][4], marginRight: '4px'}}
        />
        <StaticTag color={color}>{label}</StaticTag>
      </TagWithIcon>
      {date ? <StyledDate value={date} format="DD/MM/YY LT" tooltip="bottom" /> : null}
    </Container>
  );
}
