// @preview https://preview.pro.ant.design/form/step-form/info
// @source https://github.com/ant-design/ant-design-pro/blob/master/src/pages/Forms/StepForm/index.js
// @source https://github.com/react-component/steps/blob/master/src/Step.jsx
// @docs https://ant.design/components/steps/
// @test http://localhost:9000/work/missions/2bcOiKP13/view

import React, {PureComponent, FunctionComponent, useCallback, useMemo} from 'react';
import {connect, useSelector} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {Spin, Card, Tooltip, Icon, Popconfirm, Collapse} from 'antd';
// import {USE_FIXTURES} from 'src/config/env';
import {RouteChildrenProps} from 'react-router';
import {colors} from 'src/config/theme';
import moment from 'moment';
import {Button, LinkButton, StaticMap, useActions, useAsyncEffect} from '@joonca/library';

import {actions as missionsActions} from 'src/store/modules/missions';
import MissionPositionChart from 'src/components/app/mission/MissionPositionChart';
import MissionDeadlineChart from 'src/components/app/mission/MissionDeadlineChart';
import MapView, {Marker} from 'src/components/map/MapView';
import MapPin from 'src/components/map/MapPin';
import MissionStatus from 'src/components/app/mission/MissionStatus';
import MomentDate from 'src/components/content/MomentDate';

import MissionApplicationList from './MissionApplicationList';
import styled from 'styled-components';
import {messageOnError} from '@joonca/library/src/app';
import DeletePopConfirm from 'src/components/pop/DeletePopConfirm';
import MissionEnrollmentList from './MissionEnrollmentList';
import MissionTimesheetList from './MissionTimesheetList';
import {Mission, Application, Enrollment, Timesheet} from '@joonca/typings';
import {State} from 'src/store/reducers';
import pluralize from '@joonca/library/src/utils/pluralize';

const {Panel} = Collapse;

const MISSION_QUERY_POPULATION = {
  position: {name: 1},
  organization: {name: 1}
};

const APPLICATION_QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1},
  position: {name: 1},
  organization: {name: 1}
};

const ENROLLMENT_QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1},
  position: {name: 1},
  organization: {name: 1}
};

const TIMESHEET_QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1},
  position: {name: 1},
  organization: {name: 1}
};

type Props = RouteChildrenProps<{id: string}> & {};

const MissionView: FunctionComponent<Props> = ({match, history}) => {
  const {id} = match!.params;
  const {
    getMission,
    deleteMission,
    publishMission,
    scheduleMission,
    cancelMission,
    fetchMissionApplications,
    fetchMissionEnrollments,
    fetchMissionTimesheets
  } = useActions(missionsActions);
  const mission = useSelector<State, Mission>((state) => state.missions.resource.item);
  const applications = useSelector<State, Application[]>(
    (state) => state.missions.applications.resource.items
  );
  const enrollments = useSelector<State, Enrollment[]>((state) => state.missions.enrollments.resource.items);
  const timesheets = useSelector<State, Timesheet[]>((state) => state.missions.timesheets.resource.items);
  const isFetching = useSelector<State, boolean>((state) => state.missions.resource.isFetchingItem);

  const fetchData = useCallback(
    () =>
      Promise.all([
        getMission(id, {query: {population: MISSION_QUERY_POPULATION}}),
        fetchMissionApplications({mission: id}, {query: {population: APPLICATION_QUERY_POPULATION}}),
        fetchMissionEnrollments({mission: id}, {query: {population: ENROLLMENT_QUERY_POPULATION}}),
        fetchMissionTimesheets({mission: id}, {query: {population: TIMESHEET_QUERY_POPULATION}})
      ]),
    [getMission, fetchMissionApplications, fetchMissionEnrollments, fetchMissionTimesheets]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const actions = useMemo(() => {
    if (!mission) {
      return null;
    }
    const {status, statusDate} = mission;
    let extraActions = null;
    switch (status) {
      case 'draft':
        extraActions = (
          <>
            <Button
              type="primary"
              size="large"
              icon="eye"
              onClick={() => publishMission(id).then(fetchData).catch(messageOnError)}
            >
              Publish
            </Button>
          </>
        );
        break;
      case 'published':
        extraActions = (
          <>
            <Button
              type="primary"
              size="large"
              icon="schedule"
              disabled={!applications.length}
              onClick={() => scheduleMission(id).then(fetchData).catch(messageOnError)}
            >
              Schedule
            </Button>
            <Button
              type="default"
              size="large"
              icon="eye-invisible"
              onClick={() => cancelMission(id).then(fetchData).catch(messageOnError)}
            >
              Annuler
            </Button>
          </>
        );
        break;
    }
    return (
      <Actions>
        <Button
          type="default"
          size="large"
          icon="arrow-left"
          onClick={() => (history.length ? history.goBack() : history.replace('/work/missions'))}
        >
          Retour
        </Button>
        {extraActions}
        <DeletePopConfirm
          onConfirm={() =>
            deleteMission(id)
              .then(() => history.push('/work/missions'))
              .catch(messageOnError)
          }
        >
          <Button type="danger" size="large" icon="delete">
            Supprimer
          </Button>
        </DeletePopConfirm>
      </Actions>
    );
  }, [mission, publishMission, scheduleMission, cancelMission, deleteMission]);

  if (isFetching || !mission) {
    return <Spin spinning />;
  }

  const {headCount, place, position, startsAt, enrollmentDeadline, status, statusDate, isClosed} = mission;
  const {name: positionName} = position;
  const {name: placeName, postalCode, locality, location} = place;
  const [longitude, latitude] = location.coordinates;

  return (
    <Spin spinning={isFetching} delay={100}>
      <Header>
        <Title>
          <h2>
            Mission{' '}
            {`de ${headCount} ${pluralize(positionName, headCount)} du ${moment(startsAt).format('L')}`} à{' '}
            {locality}
          </h2>
          <div>
            <MissionStatus status={status} /> depuis le <MomentDate value={statusDate} format="L LT" tooltip />
          </div>
        </Title>
        {actions}
      </Header>
      <Collapse defaultActiveKey={isClosed ? ['1', '3'] : ['1', '2']}>
        <Panel header="Dashboard" key="1">
          {/* <div style={{display: 'grid', gridGap: '16px', gridTemplateColumns: '1fr 1fr 1fr'}}> */}
          <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
            <Card style={{width: '50%'}}>
              <h4>
                <Icon
                  type="pie-chart"
                  style={{fontSize: '16px'}}
                  twoToneColor={colors.primary}
                  theme="twoTone"
                />{' '}
                Postes recherchés: {`${headCount} ${positionName}s`} {moment(enrollmentDeadline).fromNow()}
              </h4>
              <MissionPositionChart mission={mission} applications={applications} />
            </Card>
            <Card style={{width: '50%'}}>
              <h4>
                <Icon
                  type="compass"
                  style={{fontSize: '16px'}}
                  twoToneColor={colors.success}
                  theme="twoTone"
                />{' '}
                Lieu de mission: <Tooltip title={placeName}>{`${postalCode}, ${locality}`}</Tooltip>
              </h4>
              <MapView defaultViewport={{longitude, latitude}} height={200}>
                <Marker longitude={longitude} latitude={latitude}>
                  <MapPin size={20} />
                </Marker>
              </MapView>
            </Card>
            {/* <Card style={{flexGrow: 1, flexShrink: 1}}>
              <h4>
                <Icon type="bell" style={{fontSize: '16px'}} twoToneColor={colors.primary} theme="twoTone" /> Deadline
                d'inscription:{' '}
                <Tooltip title={`le ${moment(enrollmentDeadline).format('L LT')}`}>
                  {moment(enrollmentDeadline).fromNow()}
                </Tooltip>
              </h4>
              <MissionDeadlineChart mission={mission} applications={applications} />
            </Card> */}
          </div>
        </Panel>
        <Panel header={`Candidatures (${applications.length})`} key="2">
          <MissionApplicationList match={match} />
        </Panel>
        <Panel header={`Inscriptions (${enrollments.length})`} key="3">
          <MissionEnrollmentList match={match} />
        </Panel>
        <Panel header={`Timesheets (${timesheets.length})`} key="4">
          <MissionTimesheetList match={match} />
        </Panel>
      </Collapse>
    </Spin>
  );
};

export default MissionView;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > h2 {
    margin-bottom: 0.25rem;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & > .ant-btn:not(:last-of-type) {
    margin-right: 8px;
  }
`;
