import React from 'react';
import {Select, Input} from 'antd';
// import type {FormProps} from 'antd';
import {FormItem, FormGroup, Fieldset} from 'src/components/form';

const Option = Select.Option; // eslint-disable-line

type Props = {
  fieldPrefix?: string;
};

export default function OrganizationLegalFieldset({form, fieldPrefix}: Props & FormProps) {
  const {getFieldDecorator} = form;
  const prefix = fieldPrefix ? `${fieldPrefix}.` : '';
  return (
    <Fieldset legend="Legal">
      <FormGroup>
        <FormItem label="Legal name">
          {getFieldDecorator(`${prefix}name`, {
            rules: [{required: true}]
          })(<Input />)}
        </FormItem>
        <FormItem label="Legal Form">
          {getFieldDecorator(`${prefix}form`, {
            rules: [{required: false}]
          })(
            <Select showSearch>
              <Option value="" />
              <Option value="EURL">EURL</Option>
              <Option value="SA">SA</Option>
              <Option value="SARL">SARL</Option>
              <Option value="SAS">SAS</Option>
            </Select>
          )}
        </FormItem>
      </FormGroup>
      <FormGroup>
        <FormItem label="SIRET">
          {getFieldDecorator(`${prefix}number`, {
            rules: [{required: true}]
          })(<Input />)}
        </FormItem>
        <FormItem label="Sector">
          {getFieldDecorator(`${prefix}sector`, {
            rules: [{required: true}]
          })(<Input />)}
        </FormItem>
      </FormGroup>
    </Fieldset>
  );
}
