// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {Button, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField, numericSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {Popconfirm} from 'antd';
import {ColumnProps} from 'antd/lib/table';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import TimesheetStatus from 'src/components/app/mission/TimesheetStatus';
import isClosedColumn from 'src/components/app/table/isClosedColumn';
import localityColumn from 'src/components/app/table/localityColumn';
import missionColumn from 'src/components/app/table/missionColumn';
import userColumn from 'src/components/app/table/userColumn';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as timesheetsActions} from 'src/store/modules/timesheets';
import {State} from 'src/store/reducers';
import {Timesheet} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

const QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1, username: 1},
  position: {name: 1},
  mission: {_sid: 1, internalTitle: 1, internalReference: 1, startsAt: 1, place: 1},
  organization: {name: 1}
};

type Props = {
  localStorageKey?: string;
  queryFilter?: Record<string, unknown>;
};
const TimesheetList: FunctionComponent<Props> = ({localStorageKey, queryFilter = {}}) => {
  const {fetchTimesheets, deleteTimesheet} = useActions(timesheetsActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const timesheets = useSelector<State, Array<Timesheet>>((state) => state.timesheets.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchTimesheets({}, {query: {filter: queryFilter, population: QUERY_POPULATION}})]),
    [fetchTimesheets]
  );

  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnProps<Timesheet>[]>(
    () => [
      userColumn(),
      missionColumn(),
      localityColumn(),
      {
        title: 'Date Début',
        dataIndex: ['mission', 'startsAt'],
        sorter: dateStringSorterForField('mission.startsAt'),
        render: dateTableRender
      },
      // {
      //   title: 'Position',
      //   dataIndex: 'position',
      //   render: value => (value ? value.name : '')
      // },
      // {
      //   title: 'Organization',
      //   dataIndex: 'organization',
      //   render: value => (value ? value.name : '')
      // },
      isClosedColumn(),
      {
        title: 'Statut',
        dataIndex: 'status',
        render: (value, record) => <TimesheetStatus type="worker" status={value} date={record.statusDate} />
      },
      {
        title: 'Work Hours',
        dataIndex: 'hourCount',
        sorter: numericSorterForField('hourCount'),
        render: (value, record) => (
          <div>
            <div>{record.hourCount.toFixed(2)} h</div>
            <small>{record.minuteCount} min</small>
          </div>
        )
      },
      {
        title: 'Création',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('timesheets')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 16 * 2 + 32 * 2,
        render: (_sid: string) => {
          return (
            <Button.Group>
              {/* <Tooltip title="Voir" placement="bottom">
                <LinkButton ghost type="primary" icon="search" to={`/timesheets/${_sid}/view`} />
              </Tooltip> */}
              {/* <Tooltip title="Editer" placement="bottom">
                <LinkButton ghost type="primary" icon="edit" to={`/timesheets/${_sid}/edit`} />
              </Tooltip> */}
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteTimesheet({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteTimesheet, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        {/* <LinkButton type="primary" size="large" icon="plus" to="/timesheets/create">
          Add Timesheet
        </LinkButton> */}
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={timesheets}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={[
            'user.firstName',
            'user.lastName',
            'mission.startsAt',
            'mission.internalTitle',
            'mission.internalReference',
            'position.name',
            'organization.name'
          ]}
          localStorageKey={localStorageKey || 'TimesheetList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default TimesheetList;
