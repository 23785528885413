// @docs https://github.com/alibaba/BizCharts/blob/master/doc_en/api/dataset.md
// @docs https://bizcharts.net/products/bizCharts/api/geom#color
// @docs https://bizcharts.net/products/bizCharts/docs/dataset

import React, {useMemo, FunctionComponent} from 'react';
import {Spin} from 'antd';
import {Chart, Geom, Axis, Coord, Label, Tooltip, Legend} from 'bizcharts';
import {DataView} from '@antv/data-set';
import {colors} from 'src/config/theme';
import {Mission, Application} from '@joonca/typings';

interface ApplicationStatusMap {
  expected: number;
  applied: number;
  accepted: number;
  rejected: number;
  missing: number;
}

const STATUS_LABELS_MAP: {[s: string]: string} = {
  expected: 'Recherchés',
  applied: 'Candidats',
  accepted: 'Acceptés',
  rejected: 'Rejetés',
  missing: 'Manquants'
};

const dataView = new DataView();
const dataScale = {
  label: {
    alias: 'Label'
  },
  value: {
    alias: 'Value',
    tickInterval: 1
  }
};
const getGeomColorForStatus = (status: string) => {
  switch (status) {
    case 'expected':
      return colors.primary;
    case 'applied':
      return colors.primary;
    case 'accepted':
      return colors.success;
    case 'rejected':
    case 'missing':
      return colors.alert;
    default:
      return colors.third;
      break;
  }
};
const computeDataView = (mission: Mission, applications: Application[]) => {
  const {headCount} = mission;
  const initialStatusMap = {expected: headCount, applied: 0, accepted: 0, rejected: 0, missing: 0};
  const statusMap = applications.reduce((soFar: ApplicationStatusMap, application) => {
    if (application.clientStatus === 'accepted' || application.clientStatus === 'scheduled') {
      soFar.accepted++;
      return soFar;
    }
    if (application.clientStatus === 'rejected' || application.workerStatus === 'withdrawn') {
      soFar.rejected++;
      return soFar;
    }
    if (application.workerStatus === 'applying') {
      soFar.applied++;
      return soFar;
    }
    return soFar;
  }, initialStatusMap);
  statusMap.missing = Math.max(0, headCount - statusMap.accepted);

  const data = Object.keys(statusMap)
    .map((status) => ({
      label: STATUS_LABELS_MAP[status],
      status,
      value: statusMap[status]
    }))
    .filter((status) => status.value > 0);

  return dataView.source(data);
  // return dataView.source(data).transform({
  //   type: 'percent',
  //   field: 'value',
  //   dimension: 'status',
  //   as: 'percent'
  // });
};

type Props = {
  mission: Mission;
  applications: Application[];
};

const MissionPositionChart: FunctionComponent<Props> = ({mission, applications}) => {
  if (!mission || !applications) {
    return <Spin style={{padding: '64px', width: '100%'}} />;
  }
  const dataView = useMemo(() => computeDataView(mission, applications), [mission, applications]);
  return (
    <Chart height={200} data={dataView} scale={dataScale} padding={[40, 20, 20, 20]} forceFit>
      <Axis name="label" />
      <Axis name="value" />
      <Tooltip crosshairs={{type: 'rect'}} />
      <Geom type="interval" position="label*value" color={['status', getGeomColorForStatus]}>
        <Label
          content="value"
          // formatter={(val, item) => {
          //   const {label, value} = item.point;
          //   return `${value} ${label}\n(${val})`;
          // }}
        />
      </Geom>
      {/* <Legend position="right" offsetY={-120} offsetX={-80} /> */}
    </Chart>
  );
};

export default MissionPositionChart;
