import {Button, Icon, Spin, Tabs} from 'antd';
import moment from 'moment';
import React, {FunctionComponent, useMemo} from 'react';
import {RouteChildrenProps} from 'react-router';
import ApplicationStatus from 'src/components/app/mission/ApplicationStatus';
import EnrollmentStatus from 'src/components/app/mission/EnrollmentStatus';
import MomentDate from 'src/components/content/MomentDate';
import UserAvatar from 'src/components/content/UserAvatar';
import {GENDER_LABELS, NATIONALITY_LABELS} from 'src/config/labels';
import {actions as workersActions} from 'src/store/modules/workers';
import {alpha2Emoji} from '@joonca/library/src/utils/emoji';
import {useAsyncEffect} from '@joonca/library/src/hooks';
import {useActions, useSelector} from '@joonca/library/src/utils/redux';
import styled from 'styled-components';
import WorkerInfoPane from './components/WorkerInfoPane';
import WorkerProfilePane from './components/WorkerProfilePane';
import ApplicationList from '../applications/ApplicationList';
import EnrollmentList from '../enrollments/EnrollmentList';

const ButtonGroup = Button.Group;
const CURRENT_YEAR = new Date().getFullYear();
const ICON_PROPS = {
  success: {type: 'check-circle', theme: 'twoTone', twoToneColor: 'limegreen'},
  warn: {type: 'exclamation-circle', theme: 'twoTone', twoToneColor: 'orange'}
};

type Props = RouteChildrenProps<{id: string}> & {};

const WorkerView: FunctionComponent<Props> = ({match, history}) => {
  if (!match) {
    return null;
  }
  const {id} = match.params;
  console.warn({workersActions});
  const {getWorker, updateWorker, fetchApplications, fetchEnrollments} = useActions(workersActions, []);
  const worker = useSelector<State, Worker>((state) => state.workers.resource.item);
  const applications = useSelector<State, Array<Application>>(
    (state) => state.workers.resource.applications || []
  );
  const enrollments = useSelector<State, Array<Enrollment>>(
    (state) => state.workers.resource.enrollments || []
  );
  const isLoading = useSelector<State, boolean>(
    (state) => !state.workers.resource.lastUpdatedItem || state.workers.resource.isFetchingItem
  );

  const actions = useMemo(() => {
    if (!worker) {
      return null;
    }
    return (
      <Actions>
        <Button
          type="default"
          size="large"
          icon="arrow-left"
          onClick={() => (history.length ? history.goBack() : history.replace('/work/missions'))}
        >
          Retour
        </Button>
        {/* {extraActions} */}
        {/* <DeletePopConfirm
          onConfirm={() =>
            deleteMission(id)
              .then(() => history.push('/work/missions'))
              .catch(messageOnError)
          }
        >
          <Button type="danger" size="large" icon="delete">
            Delete
          </Button>
        </DeletePopConfirm> */}
      </Actions>
    );
  }, [worker]);

  useAsyncEffect(async () => {
    await Promise.all([
      getWorker(id, {query: {population: {user: 1}}}),
      fetchApplications(id, {
        query: {
          projection: {_sid: 1}
          // population: {mission: {startsAt: 1, place: 1}, position: {name: 1}, organization: {name: 1}}
        }
      }),
      fetchEnrollments(id, {
        query: {
          projection: {_sid: 1}
          // population: {mission: {startsAt: 1, place: 1}, position: {name: 1}, organization: {name: 1}}
        }
      })
    ]);
  }, [id]);

  // Loading
  if (isLoading) {
    return <Spin style={{padding: '64px', width: '100%'}} />;
  }

  const {user, homePlace} = worker;
  const [longitude, latitude] = homePlace.location.coordinates;

  return (
    <Container>
      <Header>
        <Title>
          <h2>
            {user.firstName} {user.lastName}
          </h2>
        </Title>
        {actions}
      </Header>
      <Row style={{alignItems: 'center', margin: '.5rem 0'}}>
        <UserAvatar user={user} size={96} style={{alignSelf: 'center', marginLeft: '12px'}} />
        <ul>
          <li>
            <Label>Sexe:</Label>
            <Value>{GENDER_LABELS[worker.gender]}</Value>
          </li>
          <li>
            <Label>Age:</Label>
            <Value>
              {moment(worker.birthDate).fromNow(true)}{' '}
              <small>({moment(worker.birthDate).format('DD/MM/YYYY')})</small>
            </Value>
          </li>
          <li>
            <Label>Nationalité:</Label>
            <Value>
              {NATIONALITY_LABELS[worker.nationality]} {alpha2Emoji(worker.nationality)}
            </Value>
          </li>
        </ul>
      </Row>
      <Tabs animated={false} defaultActiveKey="1">
        <Tabs.TabPane tab="Identité" key="1">
          <WorkerInfoPane worker={worker} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Expérience" key="2">
          <WorkerProfilePane worker={worker} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Missions (${enrollments.length})`} key="3" style={{maxWidth: '100%'}}>
          <EnrollmentList localStorageKey="WorkerEnrollmentList" queryFilter={{worker: worker._id}} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Candidatures (${applications.length})`} key="4" style={{maxWidth: '100%'}}>
          <ApplicationList localStorageKey="WorkerApplicationList" queryFilter={{worker: worker._id}} />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};

export default WorkerView;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  padding-right: 4px;
`;

const Value = styled.span``;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > h2 {
    margin-bottom: 0.25rem;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  & > .ant-btn:not(:last-of-type) {
    margin-right: 8px;
  }
`;
