// @preview https://preview.pro.ant.design/form/step-form/info
// @source https://github.com/ant-design/ant-design-pro/blob/master/src/pages/Forms/StepForm/index.js
// @source https://github.com/react-component/steps/blob/master/src/Step.jsx
// @docs https://ant.design/components/steps/

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actions as missionsActions} from 'src/store/modules/missions';
import {Form, StepperForm, FormComponentProps} from 'src/components/form';
import {Route, Redirect, RouteChildrenProps} from 'react-router';
import {message} from 'antd';
import {USE_FIXTURES} from 'src/config/env';
// import type {ContextRouter} from 'react-router-dom';
import {pick} from 'lodash';
import moment from 'moment';
import memoize from 'memoize-one';
// import type {FormProps} from 'antd'; // CRASHES!!

import MissionPositionsFieldsets, {
  fixture as missionPositionsFixture
} from 'src/components/form/fieldset/MissionPositionsFieldsets';
import MissionPlacesFieldsets from 'src/components/form/fieldset/MissionPlacesFieldsets';
import MissionDetailsFieldsets, {
  fixture as missionDefailtsFixture
} from 'src/components/form/fieldset/MissionDetailsFieldsets';
import MissionFormDatesStep from './MissionFormDatesStep';
import {momentFromDateString, momentFromTimeString} from '@joonca/library/src/utils/date';

type Props = RouteChildrenProps &
  FormComponentProps & {
    actions: any;
    isCreating: boolean;
    isUpdating: boolean;
    isFetching: boolean;
  };

export class MissionForm extends PureComponent<Props> {
  static getDerivedStepsValuesFromValues = memoize((key: string, values: any) => {
    console.count('getDerivedStepsValuesFromValues');
    console.warn('getDerivedStepsValuesFromValues', {key, values});

    // @TODO smart list displays
    const castedValues = {...values};
    if (castedValues.salary.$numberDecimal) {
      castedValues.salary = castedValues.salary.$numberDecimal * 1;
    }
    // @NOTE remove UTC "Z" suffix for time to avoid timezone drift
    castedValues.dateSlots = castedValues.dateSlots.map((dateSlot) => ({
      ...dateSlot,
      startDate: momentFromDateString(dateSlot.startDate),
      endDate: momentFromDateString(dateSlot.endDate),
      startTime: momentFromTimeString(dateSlot.startTime),
      endTime: momentFromTimeString(dateSlot.endTime)
    }));

    return [
      pick(
        castedValues,
        'headCount',
        'position',
        'positionDetails',
        // 'category',
        // 'designation',
        'experienceLevel',
        'educationDegree',
        'languageLevels',
        'salary',
        'salaryType'
      ),
      pick(castedValues, 'dateSlots', 'scheduleDetails', 'enrollmentDeadlineHours', 'meetingTimeMinutes'),
      pick(castedValues, 'place', 'placePrivacy', 'placePublicName', 'placeDetails'),
      pick(
        castedValues,
        'organization',
        'publicDetails',
        'privateDetails',
        'internalReference',
        'contactNumber',
        'picture',
        'status'
      )
    ];
  });

  static steps = {
    create: [
      {path: '/work/missions/create/positions', title: 'Postes'},
      {path: '/work/missions/create/dates', title: 'Dates'},
      {path: '/work/missions/create/places', title: 'Lieux'},
      {path: '/work/missions/create/details', title: 'Informations'}
    ],
    edit: [
      {path: '/work/missions/:id/edit/positions', title: 'Postes'},
      {path: '/work/missions/:id/edit/dates', title: 'Dates'},
      {path: '/work/missions/:id/edit/places', title: 'Lieux'},
      {path: '/work/missions/:id/edit/details', title: 'Informations'}
    ]
  };

  componentDidMount() {
    const {actions, form, isEditing} = this.props;
    const {params} = this.props.match;
    if (isEditing) {
      actions.getMission(params.id, {query: {}});
    } else {
      form.resetFields();
    }
  }

  handleSubmit = (values) => {
    console.warn('handleSubmit', values, JSON.stringify(values));
    const {isEditing} = this.props;
    if (isEditing) {
      this.performUpdateAction(values);
    } else {
      this.performCreateAction(values);
    }
  };

  performUpdateAction(payload) {
    const {actions} = this.props;
    const {params} = this.props.match;
    actions
      .updateMission({...payload, _sid: params.id})
      .then(() => {
        message.success(`Sucessfully updated mission!`);
      })
      .catch((err) => {
        console.warn('err', err);
        const text = err.body ? err.body.error : '';
        message.error(`Update failed${text ? ` : ${text}` : ''}`);
      });
  }

  performCreateAction(payload) {
    const {actions, history} = this.props;
    actions
      .createMission(payload)
      .then(() => {
        message.success(`Sucessfully created mission!`);
        history.push('/work/missions');
      })
      .catch((err) => {
        console.warn('err', err);
        const text = err.body ? err.body.error : '';
        message.error(`Create failed${text ? ` : ${text}` : ''}`);
      });
  }

  render() {
    // console.count(`${this.constructor.name}.render()`);
    const {values, isEditing} = this.props;
    const {getDerivedStepsValuesFromValues} = this.constructor;
    const {params} = this.props.match;
    const stepsValues = values ? getDerivedStepsValuesFromValues(params.id || 'create', values) : undefined;
    return (
      <StepperForm
        {...this.props}
        stepsValues={stepsValues}
        steps={MissionForm.steps}
        onSubmit={this.handleSubmit}
        key={params.id || 'create'}
      >
        <Route exact path="/work/missions/create" render={() => <Redirect to="/work/missions/create/positions" />} />
        <Route
          exact
          path="/work/missions/:id/edit"
          render={() => <Redirect to={`/work/missions/${params.id}/edit/positions`} />}
        />
        <Route
          path={['/work/missions/create/positions', '/work/missions/:id/edit/positions']}
          component={MissionPositionsFieldsets}
          values={USE_FIXTURES && !isEditing ? missionPositionsFixture : undefined}
        />
        <Route
          path={['/work/missions/create/dates', '/work/missions/:id/edit/dates']}
          component={MissionFormDatesStep}
        />
        <Route
          path={['/work/missions/create/places', '/work/missions/:id/edit/places']}
          component={MissionPlacesFieldsets}
        />
        <Route
          path={['/work/missions/create/details', '/work/missions/:id/edit/details']}
          component={MissionDetailsFieldsets}
          values={USE_FIXTURES && !isEditing ? missionDefailtsFixture : undefined}
        />
      </StepperForm>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {match} = ownProps;
  const isEditing = 'id' in match.params;
  return {
    isCreating: state.missions.resource.isCreating,
    isUpdating: state.missions.resource.isUpdating,
    isFetching: state.missions.resource.isFetchingItem,
    isEditing,
    values: isEditing ? state.missions.resource.item : null
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators(missionsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(MissionForm));
