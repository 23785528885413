import {Icon} from 'antd';
import moment from 'moment';
import React, {FunctionComponent} from 'react';
import {LANGUAGE_LEVEL_LABELS, NATIONALITY_LABELS} from 'src/config/labels';
import {Worker} from '@joonca/typings';

const CURRENT_YEAR = new Date().getFullYear();
const ICON_PROPS = {
  success: {type: 'check-circle', theme: 'twoTone', twoToneColor: 'limegreen'},
  warn: {type: 'exclamation-circle', theme: 'twoTone', twoToneColor: 'orange'}
};

type Props = {worker: Worker};

const WorkerProfilePane: FunctionComponent<Props> = ({worker}) => {
  return (
    <div>
      <h3>Experiences</h3>
      <Icon {...ICON_PROPS[worker.experiences.length > 0 ? 'success' : 'warn']} /> {worker.experiences.length}{' '}
      experience(s)
      <ul>
        {worker.experiences.map((experience, index) => (
          <li key={index}>
            {experience.organization}: {experience.title} de {moment(experience.startDate).format('MM/YYYY')} à{' '}
            {moment(experience.endDate).format('MM/YYYY')} (
            {moment(experience.endDate).diff(experience.startDate, 'month')} mois)
          </li>
        ))}
      </ul>
      <h3>Diplômes</h3>
      <Icon {...ICON_PROPS[worker.certificates.length > 0 ? 'success' : 'warn']} />{' '}
      {worker.certificates.length} diplôme(s)
      <ul>
        {worker.certificates.map((certificate, index) => (
          <li key={index}>
            {certificate.organization}: {certificate.title} à {certificate.location} en {certificate.year} (il
            y a {CURRENT_YEAR - certificate.year} ans)
          </li>
        ))}
      </ul>
      <h3>Permis</h3>
      <Icon {...ICON_PROPS[Object.keys(worker.licenses || {}).length > 0 ? 'success' : 'warn']} />{' '}
      {Object.keys(worker.licenses || {}).length} permis
      <ul>
        {Object.keys(worker.licenses || {}).map((license) => (
          <li key={license}>
            {license}: en {worker.licenses[license]} (il y a {CURRENT_YEAR - worker.licenses[license]} ans)
          </li>
        ))}
      </ul>
      <h3>Langues</h3>
      <Icon {...ICON_PROPS[Object.keys(worker.languages || {}).length > 0 ? 'success' : 'warn']} />{' '}
      {Object.keys(worker.languages || {}).length} langue(s)
      <ul>
        {Object.keys(worker.languages || {}).map((language) => (
          <li key={language}>
            {NATIONALITY_LABELS[language]}: {LANGUAGE_LEVEL_LABELS[worker.languages[language]]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkerProfilePane;
