import React from 'react';
import {Button, List as BaseList, Tag} from 'antd';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

const List = styled(BaseList)`
  &.ant-list {
    margin-bottom: 24px;
  }
`;

const DateSlotListItem = ({location, item, index, onDeleteItem}) => {
  // const {dates, time} = item;
  // const {startDate, endDate} = dates;
  // const {startTime, endTime} = time;
  const {startDate, startTime, endDate, endTime, ofVariableDuration, estimatedDriftHours} = item;
  const isSingleDate = startDate.isSame(endDate);
  const hours = endTime.diff(startTime, 'hours');
  return (
    <List.Item
      actions={[
        <Link to={`${location.pathname}/edit/${index}`} key="edit">
          edit
        </Link>,
        <a key="delete" onClick={() => onDeleteItem(index)}>
          delete
        </a>
      ]}
    >
      <div>
        <div>
          <Tag color="blue">{isSingleDate ? '1 journée' : `${endDate.diff(startDate, 'days') + 1} journées`}</Tag>
          {isSingleDate ? `Le ${startDate.format('L')}` : `Du ${startDate.format('L')} au ${endDate.format('L')}`}
        </div>
        <small>
          <Tag size="small" color="blue">
            {hours < 0 ? 24 + hours : hours} heures
          </Tag>{' '}
          <span>
            De {startTime.format('LT')} à {endTime.format('LT')} {endTime.isBefore(startTime) ? '(J+1)' : null}
            {ofVariableDuration ? `, +/-${estimatedDriftHours}h` : null}
          </span>
        </small>
        {/* <pre>
          <code>{JSON.stringify(item, null, 2)}</code>
        </pre> */}
      </div>
    </List.Item>
  );
};

export default function MissionDateSlotsList({items, history, location, onDeleteItem}) {
  return (
    <List
      size="large"
      header={
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <h4 style={{flex: 1}}>Dates de la mission</h4>
          <Button type="primary" htmlType="button" icon="plus" onClick={() => history.push(`${location.pathname}/add`)}>
            Ajouter une nouvelle plage
          </Button>
        </div>
      }
      pagination={{pageSize: 3}}
      bordered
      dataSource={items}
      renderItem={(item, index) => (
        <DateSlotListItem location={location} item={item} index={index} onDeleteItem={onDeleteItem} />
      )}
    />
  );
}
