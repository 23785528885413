// @TODO https://kyleamathews.github.io/typography.js/
// @source https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
import {presetPalettes} from '@ant-design/colors';

export {presetPalettes};

export const colors = {
  primary: '#1890ff',
  info: '#1890ff',
  success: '#52c41a',
  processing: '#1890ff',
  error: '#f5222d',
  highlight: '#f5222d',
  warning: '#faad14',
  normal: '#d9d9d9'
  // primary: '#128df8',
  // secondary: '#5e87d9',
  // third: '#baa0f2',
  // neutral: '#b7cada',
  // dark: '#333434'
};
