/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, DependencyList} from 'react';

export const useAsyncEffect = (
  asyncFunction: () => Promise<void>,
  deps?: DependencyList,
  abortCallback?: () => void
): void =>
  useEffect(() => {
    const resolveAsync = async (): Promise<void> => {
      await asyncFunction();
    };
    resolveAsync();
    return abortCallback;
  }, deps);
