import React from 'react';
import {Icon, Tag} from 'antd';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import styled from 'styled-components';
import MomentDate from 'src/components/content/MomentDate';
import {Moment} from 'moment';
// import {EnrollmentStatus} from 'src/typings/app/enrollmentStatusPlugin';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StaticTag = styled(Tag)`
  cursor: default !important;
  user-select: none;
`;
const TagWithIcon = styled.div`
  margin-bottom: 0.25rem;
  white-space: nowrap;
`;
const StyledDate = styled(MomentDate)`
  font-size: 10px;
  color: ${presetPalettes.grey[4]};
`;

// Status

type EnrollmentOpenStatus = 'confirmed' | 'performed';
type EnrollmentClosedStatus = 'cancelled' | 'aborted' | 'deserted' | 'terminated' | 'completed';
type EnrollmentStatus = EnrollmentOpenStatus | EnrollmentClosedStatus;

const ENROLLMENT_STATUS_LABELS: {[key in EnrollmentStatus]: string} = {
  confirmed: 'confirmée',
  aborted: 'annulée',
  cancelled: 'annulée',
  performed: 'effectuée',
  completed: 'effectuée',
  deserted: 'désertée',
  terminated: 'clôturée'
};

const STATUS_DISPLAY_CONFIG: {[key in EnrollmentStatus | 'default']: {iconType: string; tagColor: string}} = {
  confirmed: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  aborted: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  cancelled: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  performed: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  completed: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  terminated: {
    iconType: 'close-circle',
    tagColor: 'purple'
  },
  deserted: {
    iconType: 'exclamation-circle',
    tagColor: 'red'
  },
  default: {
    iconType: 'question-circle',
    tagColor: 'volcano'
  }
};

const getIconTypeForEnrollmentStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].iconType : STATUS_DISPLAY_CONFIG.default.iconType;

const getTagColorForEnrollmentStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].tagColor : STATUS_DISPLAY_CONFIG.default.tagColor;

type Props = {
  status: string;
  date?: Date | Moment | string;
};

export default function EnrollmentStatus({status, date}: Props) {
  const color = getTagColorForEnrollmentStatus(status);
  const label = ENROLLMENT_STATUS_LABELS[status] || status;
  return (
    <Container>
      <TagWithIcon>
        <Icon
          type={getIconTypeForEnrollmentStatus(status)}
          style={{color: presetPalettes[color][4], marginRight: '4px'}}
        />
        <StaticTag color={color}>{label}</StaticTag>
      </TagWithIcon>
      {date ? <StyledDate value={date} format="DD/MM/YY LT" tooltip="bottom" /> : null}
    </Container>
  );
}
