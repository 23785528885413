import React, {FunctionComponent, HTMLProps} from 'react';
import {classNames} from 'src/antd/utils';
import {ReactComponent as CircleOutlinedSvg} from './CircleOutlined.svg';

type Props = HTMLProps<HTMLSpanElement>;

export const CircleOutlined: FunctionComponent<Props> = ({className, ...otherProps}) => {
  return (
    <span
      role="img"
      aria-label="circle"
      className={classNames('anticon anticon-circle', className)}
      {...otherProps}
    >
      <CircleOutlinedSvg
        viewBox="64 64 896 896"
        focusable={false}
        width="1em"
        height="1em"
        fill="currentColor"
      />
    </span>
  );
};
