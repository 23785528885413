// @source https://github.com/ant-design/ant-design/blob/master/components/date-picker/RangePicker.tsx

import React, {PureComponent} from 'react';
import {DatePicker} from 'antd';
import invariant from 'invariant';
import {isMoment} from 'moment';
import {isUndefined} from 'lodash';

export default class DateRangePicker extends PureComponent {
  static getDerivedValue(value) {
    if (isUndefined(value)) {
      return undefined;
    }
    // console.warn('getDerivedValue', {
    //   isLocal: value.isLocal(),
    //   isUtc: value.isUtc(),
    //   format: value.format('YYYY-MM-DD'),
    //   iso: value.toISOString()
    // });
    invariant(isMoment(value) && value.isUtc(), 'Expected an utc moment date');
    return value;
  }

  static setDerivedValue(value) {
    return value.utc().startOf('day');
  }

  handleChange = (value) => {
    const {onChange} = this.props;
    if (onChange) {
      const {setDerivedValue} = this.constructor;
      const nextValue = setDerivedValue(value);
      onChange(nextValue);
    }
  };

  render() {
    const {onChange, value, ...otherProps} = this.props;
    const {getDerivedValue} = this.constructor;
    return <DatePicker mode="date" {...otherProps} value={getDerivedValue(value)} onChange={this.handleChange} />;
  }
}
