import React, {PureComponent} from 'react';
import {Input, Select, Button, Spin, Radio, message} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// @locals
import {IS_DEV} from 'src/config/env';
import {Form, FormItem, Fieldset, FormGroup, FormFooter, Switch} from 'src/components/form';
import FileInput from 'src/components/form/input/FileInput';
import {actions as positionsActions} from 'src/store/modules/positions';
import CategoryInput from 'src/components/form/input/CategoryInput';
import {DesignationSelect} from '@joonca/library/src/app';
import DesignationSkillsTransfer from 'src/components/form/transfer/DesignationSkillsTransfer';

// @typings
// import type {FormProps, ValidateCallback} from 'antd';
// import type {ContextRouter} from 'react-router-dom';

const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring
const Option = Select.Option; // eslint-disable-line
const RadioButton = Radio.Button; // eslint-disable-line
const RadioGroup = Radio.Group; // eslint-disable-line

export const fixture = {
  name: 'Serveur / Serveuse',
  description:
    "Effectue le service au comptoir, en salle, en terrasse, de boissons chaudes ou froides selon la législation relative à la consommation d'alcools. Entretient la verrerie, les équipements du bar et les locaux selon les règles d'hygiène et la réglementation sur les Etablissements Recevant du Public (ERP).",
  category: 'G18',
  designation: '19427'
};

type Props = {
  actions: any;
  isCreating: boolean;
  isUpdating: boolean;
  isFetching: boolean;
};

type State = {
  isEditing: boolean;
  isPreloading: boolean;
  isLoading: boolean;
};

export class WorkPositionForm extends PureComponent<Props & FormProps & ContextRouter, State> {
  state = {
    isEditing: false,
    isPreloading: true,
    isLoading: false
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const {match, isCreating, isUpdating, isFetching} = props;
    const isEditing = !!match.params.id;
    return {
      isEditing,
      isPreloading: isEditing && isFetching,
      isLoading: isEditing ? isUpdating : isCreating
    };
  }

  componentDidMount() {
    const {isEditing} = this.state;
    const {actions, form} = this.props;
    const {params} = this.props.match;
    if (isEditing) {
      actions.getPosition(params.id, {query: {foo: 'bar'}}).then(({body}) => {
        form.setFieldsValue(body);
      });
    } else if (IS_DEV) {
      form.setFieldsValue(fixture);
    }
  }

  handleCancel = (ev: SyntheticEvent<any>) => {
    const {history, location} = this.props;
    const {isEditing} = this.state;
    ev.preventDefault();
    const target = location.pathname
      .split('/')
      .slice(0, isEditing ? -2 : -1)
      .join('/');
    history.push(target);
  };

  handleSubmit = (ev: SyntheticEvent<any>) => {
    const {form} = this.props;
    const {isEditing} = this.state;
    ev.preventDefault();
    form.validateFields((err, values: {[s: string]: any}) => {
      if (err) {
        return;
      }
      console.warn('validateFields', {values});
      if (isEditing) {
        this.performUpdateAction(values);
      } else {
        this.performCreateAction(values);
      }
    });
  };

  performUpdateAction(payload: {[s: string]: any}) {
    const {actions} = this.props;
    const {params} = this.props.match;
    actions
      .updatePosition({...payload, _sid: params.id})
      .then(() => {
        message.success(`Sucessfully updated position!`);
      })
      .catch((err) => {
        const text = err.body ? err.body.error : '';
        message.error(`Update failed${text ? ` : ${text}` : ''}`);
      });
  }

  performCreateAction(payload: {[s: string]: any}) {
    const {actions, history} = this.props;
    actions
      .createPosition(payload)
      .then(() => {
        message.success(`Sucessfully created position!`);
        history.push('/work/positions');
      })
      .catch((err) => {
        const text = err.body ? err.body.error : '';
        message.error(`Create failed${text ? ` : ${text}` : ''}`);
      });
  }

  render() {
    console.count(`${this.constructor.name}.render()`);
    // console.warn(`${this.constructor.name}.render():props`, this.props);

    const {isPreloading, isLoading, isEditing} = this.state;
    const {params} = this.props.match;
    const {form} = this.props;
    const {getFieldDecorator} = form;
    const {category = [], designation = null} = form.getFieldsValue(['category', 'designation']);

    return (
      <Spin spinning={isPreloading} delay={100}>
        <Form onSubmit={this.handleSubmit} key={params.id || 'create'}>
          <Fieldset legend="Position">
            <FormGroup>
              <FormItem label="Name">
                {getFieldDecorator('name', {
                  rules: [{required: true}]
                })(<Input />)}
              </FormItem>
              <FormItem label="Draft" style={{flex: '0 1 auto'}}>
                {getFieldDecorator('isDraft', {initialValue: true, valuePropName: 'checked'})(<Switch />)}
              </FormItem>
            </FormGroup>

            <FormItem label="Description">
              {getFieldDecorator('description', {
                rules: [{required: false}]
              })(<TextArea rows={3} />)}
            </FormItem>
            <FormItem label="Picture">
              {getFieldDecorator('picture', {
                // valuePropName: 'fileList',
                getValueFromEvent: ({file}) => {
                  console.warn('getValueFromEvent', file.status, file.response);
                  return file && file.status === 'done'
                    ? `${file.response.uri}?t=${+new Date(file.response.createdAt)}`
                    : null;
                },
                rules: [{required: false}]
              })(<FileInput name="positions/:_sid/picture" level="public" />)}
            </FormItem>
          </Fieldset>
          <Fieldset legend="Labor">
            <FormGroup>
              <FormItem label="Catégorie Professionnelle">
                {getFieldDecorator('category', {
                  rules: [{required: true}]
                })(<CategoryInput />)}
              </FormItem>
            </FormGroup>
            <FormItem label="Poste">
              {getFieldDecorator('designation', {
                rules: [{required: true}]
              })(<DesignationSelect filter={category} disabled={!category.length} />)}
            </FormItem>
            <FormItem label="Compétences">
              {getFieldDecorator('skills', {
                rules: [{type: 'array', required: true}]
              })(<DesignationSkillsTransfer designation={designation} disabled={!designation} />)}
            </FormItem>
          </Fieldset>

          <FormFooter>
            <Button htmlType="button" onClick={this.handleCancel}>
              Cancel
            </Button>
            &nbsp;
            <Button type="primary" htmlType="submit" disabled={isPreloading} loading={isLoading}>
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </FormFooter>
        </Form>
      </Spin>
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => ({
    isCreating: state.positions.resource.isCreating,
    isUpdating: state.positions.resource.isUpdating,
    isFetching: state.positions.resource.isFetchingItem
  }),
  // mapDispatchToProps
  (dispatch: Function) => ({
    actions: bindActionCreators(positionsActions, dispatch)
  })
)(Form.create()(WorkPositionForm));
