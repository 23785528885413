import React from 'react';
import {Input} from 'antd';
// import type {FormProps} from 'antd'; // CRASHES!!
import FileInput from 'src/components/form/input/FileInput';
import {FormItem, Fieldset, FormGroup, Switch, FormComponentProps, WrappedFormUtils} from 'src/components/form';
import {Select} from 'antd';
import PhoneNumberInput from 'src/components/form/input/PhoneNumberInput';
import OrganizationSelect from 'src/components/form/select/OrganizationSelect';

const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring

export const fixture = {
  description:
    "Avec l'appui d'une équipe opérationnelle et sous la supervision de la D.A.F., vous aurez en charge les missions opérationnelles suivantes",
  contactNumber: '+33605222333'
};

type Props = {
  form: WrappedFormUtils;
};

export default function MissionDetailsFieldsets(props: Props) {
  const {form} = props;
  const {getFieldDecorator} = form;
  return (
    <Fieldset legend="Informations" {...props}>
      <FormItem label="Organization">
        {getFieldDecorator('organization', {
          rules: [{required: true}]
        })(<OrganizationSelect />)}
      </FormItem>
      <FormItem label="Consignes publiques">
        {getFieldDecorator('publicDetails', {
          rules: [{required: false}]
        })(<TextArea placeholder="Par exemple: Le port du casque est obligatoire." rows={3} />)}
      </FormItem>
      <FormItem label="Consignes privées">
        {getFieldDecorator('privateDetails', {
          rules: [{required: false}]
        })(<TextArea placeholder="Par exemple: Le code du portail d'accès à l'entrepôt est 8284." rows={3} />)}
      </FormItem>
      <FormItem label="Référence interne privée">
        {getFieldDecorator('internalReference', {
          rules: [{required: false}]
        })(<Input placeholder="Mission #123" />)}
      </FormItem>
      <FormGroup>
        <FormItem label="Support Email" style={{flex: 2}}>
          {getFieldDecorator('supportEmail', {
            // rules: [{required: true, message: 'Please input your phone number!'}]
          })(<Input />)}
        </FormItem>
        <FormItem label="Support PhoneNumber" style={{flex: 2}}>
          {getFieldDecorator('supportPhoneNumber', {
            // rules: [{required: true, message: 'Please input your phone number!'}]
          })(<PhoneNumberInput />)}
        </FormItem>
      </FormGroup>
      <FormGroup>
        <FormItem label="Status" style={{flex: 2}}>
          {getFieldDecorator('status')(
            <Select showSearch>
              <Select.Option value="draft">Draft</Select.Option>
              <Select.Option value="published">Published</Select.Option>
              <Select.Option value="cancelled">Cancelled</Select.Option>
              <Select.Option value="scheduled">Scheduled</Select.Option>
              <Select.Option value="aborted">Aborted</Select.Option>
              <Select.Option value="terminated">Terminated</Select.Option>
            </Select>
          )}
        </FormItem>
      </FormGroup>
      <FormItem label="Picture">
        {getFieldDecorator('picture', {
          // valuePropName: 'fileList',
          getValueFromEvent: ({file}) => {
            console.warn('getValueFromEvent', file.status, file.response);
            return file && file.status === 'done'
              ? `${file.response.uri}?t=${+new Date(file.response.createdAt)}`
              : null;
          },
          rules: [{required: false}]
        })(<FileInput name="picture" level="protected" />)}
      </FormItem>
    </Fieldset>
  );
}
