import {
  BookOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined
} from '@ant-design/icons';
import type {Timesheet, TimesheetStatus} from '@joonca/typings';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import React, {FunctionComponent, ReactNode} from 'react';
import {Tag, TagProps} from 'src/antd';
import {StatusTag} from './StatusTag';

export const TIMESHEET_STATUS_LABELS: {[s in TimesheetStatus]: string} = {
  draft: 'equissé',
  submitted: 'envoyé',
  corrected: 'corrigé',
  updated: 'modifié',
  reverted: 'restauré',
  validated: 'validé',
  terminated: 'clôturé'
};
export const TIMESHEET_STATUS_CAPTIONS: {[s in TimesheetStatus]: string} = {
  draft: "Le relevé d'heure n'a pas encore été envoyée au jober",
  submitted: "Le relevé d'heure est en attente de validation par le jober",
  corrected: 'Une correction de timesheet a été demandée par le jober',
  updated: "De nouveaux horaires ont été proposées sur le relevé d'heure",
  reverted: "Les corrections proposées sur le relevé d'heure ont été refusées",
  validated: "Le relevé d'heure a été validée et est donc finalisé",
  terminated: "Le relevé d'heure a été clôturée automatiquement par Joonca"
};
export const TIMESHEET_STATUS_COLORS: {[s in TimesheetStatus]: TagProps['color']} = {
  draft: 'cyan',
  submitted: 'blue',
  corrected: 'volcano',
  updated: 'volcano',
  reverted: 'volcano',
  validated: 'green',
  terminated: 'purple'
};
export const TIMESHEET_STATUS_ICONS: {[s in TimesheetStatus]: ReactNode} = {
  draft: <ExperimentOutlined />,
  submitted: <BookOutlined />,
  corrected: <ExclamationCircleOutlined />,
  updated: <ClockCircleOutlined />,
  reverted: <ExclamationCircleOutlined />,
  validated: <CheckCircleOutlined />,
  terminated: <CloseCircleOutlined />
};

export type TimesheetStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Timesheet['status']>;
  date?: Date | string | number;
};

export const TimesheetStatusTag: FunctionComponent<TimesheetStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip title={TIMESHEET_STATUS_CAPTIONS[value]} placement={placement} overlayStyle={{maxWidth: 200}}>
      <Tag icon={TIMESHEET_STATUS_ICONS[value]} color={TIMESHEET_STATUS_COLORS[value]} {...otherProps}>
        {TIMESHEET_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);
