import {combineReducers} from 'redux';
import auth from './modules/auth';
import applications from './modules/applications';
import enrollments, {State as EnrollmentState} from './modules/enrollments';
import timesheets, {State as TimesheetState} from './modules/timesheets';
import devices from './modules/devices';
import missions, {State as MissionState} from './modules/missions';
import invoices, {State as InvoiceState} from './modules/invoices';
import files from './modules/files';
import organizations from './modules/organizations';
import positions from './modules/positions';
import qualities from './modules/qualities';
import sessions from './modules/sessions';
import users, {State as UsersState} from './modules/users';
import workers from './modules/workers';

export interface State {
  applications: {[s: string]: any};
  auth: {[s: string]: any};
  devices: {[s: string]: any};
  enrollments: EnrollmentState;
  files: {[s: string]: any};
  invoices: InvoiceState;
  missions: MissionState;
  organizations: {[s: string]: any};
  positions: {[s: string]: any};
  qualities: {[s: string]: any};
  sessions: {[s: string]: any};
  timesheets: TimesheetState;
  users: UsersState;
  workers: {[s: string]: any};
}

export default combineReducers({
  applications,
  auth,
  devices,
  enrollments,
  files,
  invoices,
  missions,
  organizations,
  positions,
  qualities,
  sessions,
  timesheets,
  users,
  workers
});
