import React from 'react';
import {Input, InputNumber, Select} from 'antd';
import {Fieldset, FieldsetGroup, FormItem, FormGroup, WrappedFormUtils} from 'src/components/form';
import LanguageSelect from 'src/components/form/select/LanguageSelect';
import WorkPositionSelect from 'src/components/form/select/WorkPositionSelect';
// import type {FormProps} from 'antd';

const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring
const Option = Select.Option; // eslint-disable-line prefer-destructuring

export const fixture = {
  headCount: 2,
  position: '5c4b20fe945cea767633c088',
  positionDetails:
    'La mission consiste à effectuer le service en salle en journée. Bonne présentation exigée.',
  experienceLevel: 'A2',
  educationLevel: 'L5'
};

type Props = {
  form: WrappedFormUtils;
};

export default function MissionPositionFieldsets(props: Props) {
  const {form} = props;
  const {getFieldDecorator} = form;
  const {positionDetails = []} = form.getFieldsValue(['positionDetails']);
  // console.warn({positionDetails});
  return (
    <FieldsetGroup {...props}>
      <Fieldset legend="Postes">
        <FormGroup>
          <FormItem label="Nombre" style={{flex: '0 0 90px'}}>
            {getFieldDecorator('headCount', {
              initialValue: 1,
              rules: [{required: true}]
            })(<InputNumber min={1} max={999} />)}
          </FormItem>
          <FormItem label="Poste">
            {getFieldDecorator('position', {
              rules: [{required: true}]
            })(<WorkPositionSelect />)}
          </FormItem>
          {/* <FormItem label="Catégorie Professionnelle">
            {getFieldDecorator('category', {
              rules: [{required: true}]
            })(<CategoryInput />)}
          </FormItem> */}
        </FormGroup>
        <FormItem label="Consignes">
          {getFieldDecorator('positionDetails', {
            initialValue: '',
            rules: [{required: false}]
          })(
            <TextArea
              placeholder="Par exemple: La mission consiste à effectuer le service en salle en journée. Bonne présentation exigée."
              rows={3}
            />
          )}
        </FormItem>
        {/* <FormItem label="Poste">
          {getFieldDecorator('designation', {
            rules: [{required: true}]
          })(<DesignationSelect filter={category} disabled={!category.length} />)}
        </FormItem> */}
      </Fieldset>
      <Fieldset legend="Expérience">
        <FormGroup>
          <FormItem label="Ancienneté">
            {getFieldDecorator('experienceLevel', {
              rules: [{required: false}],
              initialValue: 'A1'
            })(
              <Select>
                <Option value="A1">Aucune</Option>
                <Option value="A2">Junior (0 à 1 an)</Option>
                <Option value="B1">Intermédiaire (1 à 3 ans)</Option>
                <Option value="B2">Avancé (plus de 3 ans)</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Niveau d'étude">
            {getFieldDecorator('educationLevel', {
              rules: [{required: false}],
              initialValue: 'L6'
            })(
              <Select>
                <Option value="L6">Aucun</Option>
                <Option value="L5">BEP / CAP</Option>
                <Option value="L4">BAC</Option>
                <Option value="L3">BTS / DUT</Option>
                <Option value="L2">License</Option>
                <Option value="L1">Master</Option>
              </Select>
            )}
          </FormItem>
        </FormGroup>
        <FormItem label="Langues">
          {getFieldDecorator('languageLevels', {
            rules: [{type: 'object', required: false}],
            initialValue: {fr: 'B1'}
          })(<LanguageSelect />)}
        </FormItem>
      </Fieldset>
      <Fieldset legend="Salaire">
        <FormGroup>
          <FormItem label="Montant brut">
            {getFieldDecorator('salary', {
              initialValue: 9.88,
              rules: [{required: true}]
            })(<InputNumber min={9.88} max={1000} step={0.01} style={{width: 200}} />)}
          </FormItem>
          <FormItem label="Rémunération" style={{flex: 2}}>
            {getFieldDecorator('salaryType', {
              rules: [{required: false}],
              initialValue: 'hourly'
            })(
              <Select>
                <Option value="hourly">Par heure</Option>
                <Option value="monthly">Par mois (pour 35h)</Option>
                <Option value="yearly">Par an (pour 35h)</Option>
              </Select>
            )}
          </FormItem>
        </FormGroup>
      </Fieldset>
    </FieldsetGroup>
  );
}
