import {combineReducers} from 'redux';
import {createResource /* , reduceReducers */} from 'redux-rest-resource';
import {HOST_API_URL} from 'src/config/env';
import {Resource, Mission} from '@joonca/typings';

const missionResource = createResource({
  name: 'mission',
  url: `${HOST_API_URL}/missions/:_sid`,
  credentials: 'include',
  actions: {
    publish: {method: 'POST', gerundName: 'publishing', url: './publish'},
    schedule: {method: 'POST', gerundName: 'scheduling', url: './schedule'},
    cancel: {method: 'POST', gerundName: 'cancelling', url: './cancel'},
    update: {
      assignResponse: true
    }
  }
});

const missionApplicationResource = createResource({
  name: 'missionApplication',
  pluralName: 'missionApplications',
  url: `${HOST_API_URL}/missions/:mission/applications/:_sid`,
  credentials: 'include',
  actions: {
    accept: {method: 'POST', gerundName: 'accepting', url: './accept'},
    reject: {method: 'POST', gerundName: 'rejecting', url: './reject'},
    update: {
      assignResponse: true
    }
  }
});

const missionEnrollmentResource = createResource({
  name: 'missionEnrollment',
  pluralName: 'missionEnrollments',
  url: `${HOST_API_URL}/missions/:mission/enrollments/:_sid`,
  credentials: 'include',
  actions: {
    accept: {method: 'POST', gerundName: 'accepting', url: './accept'},
    reject: {method: 'POST', gerundName: 'rejecting', url: './reject'},
    update: {
      assignResponse: true
    }
  }
});

const missionTimesheetResource = createResource({
  name: 'missionTimesheet',
  pluralName: 'missionTimesheets',
  url: `${HOST_API_URL}/missions/:mission/timesheets/:_sid`,
  credentials: 'include',
  actions: {
    // accept: {method: 'POST', gerundName: 'accepting', url: './accept'},
    // reject: {method: 'POST', gerundName: 'rejecting', url: './reject'},
    update: {
      assignResponse: true
    }
  }
});

const actions = {
  ...missionResource.actions,
  ...missionApplicationResource.actions,
  ...missionEnrollmentResource.actions,
  ...missionTimesheetResource.actions
};

const rootReducer = combineReducers({
  resource: missionResource.reducers,
  applications: combineReducers({
    resource: missionApplicationResource.reducers
  }),
  enrollments: combineReducers({
    resource: missionEnrollmentResource.reducers
  }),
  timesheets: combineReducers({
    resource: missionTimesheetResource.reducers
  })
});

export {actions};
export default rootReducer;

export interface State {
  resource: Resource<Mission>;
}
