import {InputNumber as BaseInputNumber} from 'antd';
import {InputNumberProps as BaseInputNumberProps} from 'antd/lib/input-number';
import {createElement, forwardRef, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';
import {sizeMixin} from '../Input/Input';

export type InputNumberProps = BaseInputNumberProps & {
  size?: SizeType;
};

const InputNumber: RefForwardingComponent<typeof BaseInputNumber, InputNumberProps> = (props, ref) => {
  const classPrefix = props.prefix || props.suffix ? 'ant-input-affix-wrapper-' : 'ant-input-';
  return createElement(StyledInputNumber, {
    ref,
    ...props,
    ...mapSizeProps(props, classPrefix)
  } as BaseInputNumberProps);
};

export default forwardRef(InputNumber);

// @source https://github.com/ant-design/ant-design/blob/master/components/input-number/style/index.less
const StyledInputNumber = styled(BaseInputNumber)`
  ${sizeMixin('xl')}/* &.ant-input {
    background: red;
  } */
`;
