import {CheckCircleOutlined, EuroOutlined, CloseCircleOutlined, ExperimentOutlined} from '@ant-design/icons';
import {Invoice, InvoiceStatus} from '@joonca/typings';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import React, {FunctionComponent, ReactNode} from 'react';
import {Tag, TagProps} from 'src/antd';
import {StatusTag} from './StatusTag';

export const INVOICE_STATUS_LABELS: {[s in InvoiceStatus]: string} = {
  draft: 'equissée',
  submitted: 'envoyée',
  paid: 'programmée',
  cancelled: 'annulée',
  terminated: 'clôturée',
  acknowledged: 'finalisée'
};
export const INVOICE_STATUS_CAPTIONS: {[s in InvoiceStatus]: string} = {
  draft: "La facture n'a pas encoré été envoyée au client",
  submitted: 'La facture a été envoyée au client',
  paid: 'La facture a été marquée comme payée par le client',
  cancelled: 'La facture a été annulée par le jober',
  terminated: 'La facture a été clôturée automatiquement par Joonca',
  acknowledged: 'Le paiement a été marqué comme reçu par le jober'
};
export const INVOICE_STATUS_BY_LABELS: {[s in InvoiceStatus]: string} = {
  draft: 'par le jober',
  submitted: 'par le jober',
  paid: 'par vous',
  cancelled: 'par le jober',
  terminated: 'par joonca',
  acknowledged: 'par le jober'
};
export const INVOICE_STATUS_COLORS: {[s in InvoiceStatus]: TagProps['color']} = {
  draft: 'cyan',
  submitted: 'blue',
  paid: 'success',
  cancelled: 'volcano',
  terminated: 'purple',
  acknowledged: 'success'
};
export const INVOICE_STATUS_ICONS: {[s in InvoiceStatus]: ReactNode} = {
  draft: <ExperimentOutlined />,
  submitted: <CheckCircleOutlined />,
  paid: <EuroOutlined />,
  cancelled: <CloseCircleOutlined />,
  terminated: <CloseCircleOutlined />,
  acknowledged: <CheckCircleOutlined />
};

export type InvoiceStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Invoice['status']>;
  date?: Date | string | number;
};

export const InvoiceStatusTag: FunctionComponent<InvoiceStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip title={INVOICE_STATUS_CAPTIONS[value]} placement={placement} overlayStyle={{maxWidth: 200}}>
      <Tag icon={INVOICE_STATUS_ICONS[value]} color={INVOICE_STATUS_COLORS[value]} {...otherProps}>
        {INVOICE_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);
