import {message, Upload} from 'antd';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
// import {Avatar} from '../Avatar/Avatar';
import {DeleteOutlined} from '@ant-design/icons';
import {Avatar, getOrganizationInitials} from 'src/components/avatar/Avatar/Avatar';
import styled from 'styled-components';
import {UploadProps} from 'antd/lib/upload';
import Button from 'src/components/button/Button/Button';
import {HOST_STORAGE_URL} from 'src/config/env';

export type AvatarPickerProps = Pick<UploadProps, 'action' | 'headers' | 'withCredentials'> & {
  value?: string;
  onDelete?: () => void;
};

export const AvatarPicker: FunctionComponent<AvatarPickerProps> = ({
  children,
  value,
  onDelete,
  ...otherUploadProps
}) => {
  return (
    <Container>
      <Avatar
        src={value ? `${HOST_STORAGE_URL}/files/contents/${value}` : undefined}
        size="xl"
        shape="square"
        type="primary"
      >
        {children}
      </Avatar>
      <Controls>
        <Actions>
          <Upload accept="image/*" name="file" showUploadList={false} {...otherUploadProps}>
            <Button>Modifier</Button>
          </Upload>
          {value && onDelete ? (
            <Button type="primary" onClick={onDelete}>
              <DeleteOutlined />
            </Button>
          ) : null}
        </Actions>
        <Caption>
          Vérifiez que l&apos;image soit bien au format PNG, JPG ou GIF et qu&apos;elle ne dépasse pas 5 Mo
        </Caption>
      </Controls>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  margin-bottom: 2.5rem;
  max-width: 32rem;
  > .ant-avatar {
    flex-shrink: 0;
  }
`;
const Controls = styled.div`
  margin-left: 1.75rem;
`;
const Actions = styled.div`
  > .ant-btn:last-of-type {
    margin-left: 1rem;
  }
`;
const Caption = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: var(--grey600);
`;

export default AvatarPicker;

export type UserAvatarPickerProps = AvatarPickerProps & {
  user: {firstName?: string; lastName?: string};
};

export const UserAvatarPicker: FunctionComponent<UserAvatarPickerProps> = ({user, ...otherProps}) => {
  const initials = useMemo<string>(
    () =>
      user && user.firstName && user.lastName
        ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`
        : '?',
    [user]
  );
  return <AvatarPicker {...otherProps}>{initials}</AvatarPicker>;
};

export type OrganizationAvatarPickerProps = AvatarPickerProps & {
  organization: {name?: string};
};

export const OrganizationAvatarPicker: FunctionComponent<OrganizationAvatarPickerProps> = ({
  organization,
  ...otherProps
}) => {
  const initials = useMemo<string>(() => getOrganizationInitials(organization), [organization]);
  return <AvatarPicker {...otherProps}>{initials}</AvatarPicker>;
};
