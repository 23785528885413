import {combineReducers} from 'redux';
import {createResource /* , reduceReducers */} from 'redux-rest-resource';
import {HOST_API_URL} from 'src/config/env';

const {actions, reducers: resouceReducers} = createResource({
  name: 'worker',
  url: `${HOST_API_URL}/workers/:_sid`,
  credentials: 'include',
  actions: {
    applications: {
      method: 'GET',
      isArray: true,
      name: 'fetchApplications',
      gerundName: 'fetchingApplications',
      url: './applications',
      reduce: (state, action) => {
        switch (action.status) {
          case 'pending':
            return {
              ...state,
              isFetchingApplications: true
            };
          case 'resolved':
            return {
              ...state,
              isFetchingApplications: false,
              lastUpdatedApplications: action.receivedAt,
              applications: action.body
            };
          case 'rejected':
            return {
              ...state,
              isFetchingApplications: false
            };
          default:
            return state;
        }
      }
    },
    enrollments: {
      method: 'GET',
      isArray: true,
      name: 'fetchEnrollments',
      gerundName: 'fetchingEnrollments',
      url: './enrollments',
      reduce: (state, action) => {
        switch (action.status) {
          case 'pending':
            return {
              ...state,
              isFetchingEnrollments: true
            };
          case 'resolved':
            return {
              ...state,
              isFetchingEnrollments: false,
              lastUpdatedEnrollments: action.receivedAt,
              enrollments: action.body
            };
          case 'rejected':
            return {
              ...state,
              isFetchingEnrollments: false
            };
          default:
            return state;
        }
      }
    },
    update: {
      assignResponse: true
    }
  }
});

const rootReducer = combineReducers({
  resource: resouceReducers
});

export {actions};
export default rootReducer;
