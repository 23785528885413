// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/tree/master/components/table

import React, {PureComponent, Fragment} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Table, Tag, Popconfirm, Tooltip} from 'antd';
import {Button, LinkButton, StaticMap} from '@joonca/library';
import {RouteChildrenProps} from 'react-router';
import {without} from 'lodash';
// @locals
import TableLayout, {TableHeader, TableContent} from 'src/components/TableLayout';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import {localeSorterForField, dateStringSorterForField} from '@joonca/library/src/utils/sort';
import {actions as missionsActions} from 'src/store/modules/missions';
import {HOST_API_URL} from 'src/config/env';
import {dateTableRender} from '@joonca/library/src/utils/table';
import {message, messageOnError} from '@joonca/library/src/app';
import WorkerExpandedRow from 'src/components/content/WorkerExpandedRow';
import ApplicationStatus from 'src/components/app/mission/ApplicationStatus';

// @NOTE avoid destructuring to prevent coverage issues
const ButtonGroup = Button.Group;

const QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1},
  position: {name: 1},
  organization: {name: 1}
};

const STATUS_COLOR_MAP = {
  applying: 'green',
  pending: 'blue',
  cancelled: 'red'
};

const expandedRowRender = (record) => (
  <div>
    <WorkerExpandedRow user={record.user} worker={record.worker} />
    {/* <div>
      <pre style={{margin: 0}}>
        <code>{JSON.stringify(record, null, 2)}</code>
      </pre>
    </div> */}
  </div>
);

type Props = RouteChildrenProps & {
  misssions: Array<any>;
  actions: {
    fetchMissions: Function;
  };
};
type State = {
  searchValue: string;
  expandedRowKeys: Array<string>;
};
export class MissionApplicationList extends PureComponent<Props, State> {
  static defaultProps = {
    missions: []
  };

  state: State = {
    searchValue: '',
    expandedRowKeys: []
  };

  columns = [
    // {
    //   title: 'Title',
    //   dataIndex: 'title',
    //   sorter: localeSorterForField('title')
    // },
    {
      title: 'Worker',
      dataIndex: 'user',
      render: ({firstName, lastName}, {_sid}) => {
        return (
          <a onClick={() => this.toggleExpanded(_sid)}>
            {firstName} {lastName}
          </a>
        );
      }
    },
    {
      title: 'Position',
      dataIndex: 'position',
      render: (value) => (value ? value.name : '')
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      render: (value) => (value ? value.name : '')
    },
    {
      title: 'Worker Status',
      dataIndex: 'workerStatus',
      render: (value, record) => (
        <ApplicationStatus type="worker" status={value} date={record.workerStatusDate} />
      )
    },
    {
      title: 'Worker Date',
      dataIndex: 'workerStatusDate',
      sorter: dateStringSorterForField('workerStatusDate'),
      render: dateTableRender
    },
    {
      title: 'Client Status',
      dataIndex: 'clientStatus',
      render: (value, record) => (
        <ApplicationStatus type="client" status={value} date={record.clientStatusDate} />
      )
    },
    {
      title: 'Client Date',
      dataIndex: 'clientStatusDate',
      sorter: dateStringSorterForField('clientStatusDate'),
      render: dateTableRender
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: dateStringSorterForField('createdAt'),
      render: dateTableRender,
      onCell: (record, rowIndex) => ({
        onClick: (ev) => {
          if (navigator.platform === 'MacIntel' && ev.metaKey /* || ev.ctrlKey */) {
            setTimeout(() => {
              window.open(`${HOST_API_URL}/applications/${record._sid}`);
            }, 0);
          }
        }
      })
    },
    {
      title: 'Actions',
      dataIndex: '_sid',
      key: 'actions',
      width: 16 * 2 + 32 * 4,
      // fixed: 'right',
      render: (_sid: string, record) => {
        const {id, actions} = this.props;
        // const {_sid: mission} = item;
        return (
          <ButtonGroup>
            <Tooltip title="Voir" placement="bottom">
              {/* <LinkButton ghost type="primary" icon="search" to={`/workers/${record.worker._sid}/view`} /> */}
              <Button
                ghost
                type="primary"
                icon="search"
                onClick={() => {
                  window.open(`/workers/${record.worker._sid}/view`);
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Confirmez-vous l'acceptation?"
              placement="topRight"
              onConfirm={async (ev) => {
                try {
                  await actions.acceptMissionApplication({mission: id, _sid});
                  message.success('Candidature correctement acceptée! 👍');
                  this.reloadState();
                } catch (err) {
                  messageOnError(err);
                }
              }}
              okText="Confirmer"
              cancelText="Annuler"
            >
              <Button ghost type="primary" icon="check" />
            </Popconfirm>
            <Popconfirm
              title="Confirmez-vous le rejet?"
              placement="topRight"
              onConfirm={async () => {
                try {
                  await actions.rejectMissionApplication({mission: id, _sid});
                  message.success('Candidature correctement rejetée! 👍');
                  this.reloadState();
                } catch (err) {
                  messageOnError(err);
                }
              }}
              okText="Confirmer"
              cancelText="Annuler"
            >
              <Button ghost type="primary" icon="stop" />
            </Popconfirm>
            <Popconfirm
              title="Confirmez-vous la suppression?"
              placement="topRight"
              onConfirm={async () => {
                try {
                  await actions.deleteMissionApplication({mission: id, _sid});
                  message.success('Candidature correctement supprimée! 👍');
                } catch (err) {
                  messageOnError(err);
                }
              }}
              okText="Confirmer"
              cancelText="Annuler"
            >
              <Button ghost type="primary" icon="close" />
            </Popconfirm>
          </ButtonGroup>
        );
      }
    }
  ];

  componentDidMount() {
    this.reloadState();
  }

  reloadState() {
    const {id, actions} = this.props;
    actions.fetchMissionApplications({mission: id}, {query: {population: QUERY_POPULATION}});
  }

  handleSearch = (searchValue: string) => {
    this.setState({searchValue});
  };

  toggleExpanded = (sid: string) => {
    const {expandedRowKeys} = this.state;
    if (expandedRowKeys.includes(sid)) {
      this.setState({expandedRowKeys: without(expandedRowKeys, sid)});
    } else {
      this.setState({expandedRowKeys: expandedRowKeys.concat(sid)});
    }
  };

  render() {
    const {items} = this.props;
    const {searchValue, expandedRowKeys} = this.state;
    return (
      <TableLayout>
        {/* <TableHeader>
          <SearchInput onSearch={this.handleSearch} />
          <LinkButton type="primary" size="large" icon="plus" to="/work/missions/create">
            Add Mission
          </LinkButton>
        </TableHeader> */}
        <TableContent>
          <SearchableTable
            expandedRowRender={expandedRowRender}
            expandIconAsCell={false}
            showCount={false}
            expandedRowKeys={expandedRowKeys}
            columns={this.columns}
            dataSource={items}
            searchValue={searchValue}
            locale={{
              filterConfirm: 'Ok',
              filterReset: 'Reset',
              emptyText: 'Pas encore de candidature pour le moment 😅!'
            }}
            rowKey="_sid"
          />
        </TableContent>
      </TableLayout>
    );
  }
}

const mapStateToProps = (state: any, ownProps: Props) => {
  const {match} = ownProps;
  const {isFetching, didInvalidate, items} = state.missions.applications.resource;
  return {
    isFetching,
    id: match.params.id,
    items: !didInvalidate ? items : null
  };
};

export default connect(
  mapStateToProps,
  // mapDispatchToProps
  (dispatch: Dispatch) => ({
    actions: bindActionCreators(missionsActions, dispatch)
  })
)(MissionApplicationList);

/*
        <div style={{position: 'relative'}}>
          <span style={{backgroundColor: 'rgba(255, 255, 255, .8)', zIndex: 2, display: 'block', position: 'relative'}}>
            <Tag color="blue">{value.postalCode}</Tag>
            <br />
            {value.locality}
          </span>
          <div style={{position: 'absolute', top: 0, left: 0, zIndex: 1}}>
            <StaticMap
              width={128}
              height={64}
              longitude={value.location.coordinates[0]}
              latitude={value.location.coordinates[1]}
              zoom={13}
              retina
            />
          </div>
        </div>
        */
