import styled from 'styled-components';
import {Form} from 'antd';

export default styled(Form.Item)`
  flex: 1;
  .ant-form-item-control {
    text-align: center;
  }
  button.ant-btn {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
`;
