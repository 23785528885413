import {CheckCircleOutlined} from '@ant-design/icons';
import CircleOutlined from './CircleOutlined';
import {Radio as BaseRadio} from 'antd';
import React, {createElement, FunctionComponent, ReactNode, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {RadioProps as BaseRadioProps, RadioGroupProps as BaseRadioGroupProps} from 'antd/lib/radio/interface';
import {RadioButtonProps as BaseRadioButtonProps} from 'antd/lib/radio/radioButton';
import {mapSizeProps, SizeType} from '../helpers/size';
import {red, green, blue} from '@ant-design/colors';

const {Button: BaseRadioButton, Group: BaseRadioGroup} = BaseRadio;

const StyledRadioGroup = styled(BaseRadioGroup)`
  &.ant-radio-group {
    overflow: hidden;
    .ant-input-number {
      &.ant-input-number-focused {
        box-shadow: none;
      }
      border-color: transparent;
    }
  }
  &.ant-radio-group-sm {
    .ant-radio-button-wrapper {
      height: 24px;
      padding: 0 7px;
      line-height: 22px;
    }
  }
  &.ant-radio-group-md {
    .ant-radio-button-wrapper {
      height: 32px;
      padding: 0 15px;
      line-height: 30px;
    }
  }
  &.ant-radio-group-lg {
    .ant-radio-button-wrapper {
      height: 40px;
      font-size: 16px;
      line-height: 38px;
    }
  }
  &.ant-radio-group-xl {
    .ant-radio-button-wrapper {
      height: 48px;
      font-size: 18px;
      line-height: ${48 - 2}px;
    }
  }

  &:not(.ant-radio-group-horizontal) {
    .ant-radio-button-wrapper {
      border-radius: 2px;
    }
  }
  &.ant-radio-group-flex-3 {
    display: flex;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
      flex: 0 0 calc(33.33% - 12px);
    }
  }
  &.ant-radio-group-flex-4 {
    display: flex;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
      flex: 0 0 calc(25% - 12px);
    }
  }
  &.ant-radio-group-flex-5 {
    display: flex;
    flex-wrap: wrap;
    .ant-radio-button-wrapper {
      flex: 0 0 calc(20% - 12px);
    }
  }
  &.ant-radio-group-grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, auto);
    .ant-radio-button-wrapper {
      display: block;
      margin: 0 12px 12px 0;
      &:not(.ant-radio-button-wrapper-checked) {
        border-left: 1px solid #d9d9d9;
      }
      &.ant-radio-button-wrapper-checked {
        border-left: 1px solid #1890ff;
      }
      &::before {
        display: none;
      }
    }
  }
  &.ant-radio-group-vertical {
    .ant-radio-button-wrapper {
      display: block;
      &:not(:first-of-type) {
        margin-top: 10px;
      }
      &:not(.ant-radio-button-wrapper-checked) {
        border: 1px solid #d9d9d9;
      }
      &.ant-radio-button-wrapper-checked {
        border: 1px solid #1890ff;
      }
    }
  }
`;

export type RadioGroupProps = Omit<BaseRadioGroupProps, 'size'> & {
  layout?: 'horizontal' | 'vertical' | 'flex-3' | 'flex-4' | 'flex-5' | 'grid';
  size?: SizeType;
  prefix?: ReactNode;
};

const RadioGroup: FunctionComponent<RadioGroupProps> = ({layout = 'horizontal', className = '', ...props}) => {
  return createElement(StyledRadioGroup, {
    className: `${className} ant-radio-group-${layout}`.trim(),
    ...(props as BaseRadioGroupProps)
  });
  // return createElement(StyledRadioGroup, {...props, ...mapSizeProps(props, 'ant-radio-group-')});
};

const StyledRadioButton = styled(BaseRadioButton)`
  display: inline-block;
  &.ant-radio-button-wrapper-checked {
    .anticon-circle {
      display: none;
    }
  }
  &:not(.ant-radio-button-wrapper-checked) {
    .anticon-check-circle {
      display: none;
    }
  }
`;

export type RadioButtonProps = Omit<BaseRadioButtonProps, 'size'> & {
  size?: SizeType;
};
const RadioButton: RefForwardingComponent<typeof BaseRadioButton, RadioButtonProps> = (
  {children, ...otherProps},
  ref
) => {
  return createElement(
    StyledRadioButton,
    {...otherProps, ref} as BaseRadioButtonProps,
    React.Children.map(children, (child) => (
      <>
        <CircleOutlined />
        <CheckCircleOutlined />
        <span style={{marginLeft: '.5rem'}}>{child}</span>
      </>
    ))
  );
};

const StyledRadio = styled(BaseRadio)`
  background: black;
`;

export type RadioProps = Omit<BaseRadioProps, 'size'> & {
  size?: SizeType;
};
const Radio: FunctionComponent<RadioProps> = ({children, ...otherProps}) => {
  return createElement(StyledRadio, otherProps, children);
};

export default Object.assign(Radio, {
  Group: RadioGroup,
  Button: React.forwardRef(RadioButton)
});
