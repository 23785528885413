export * from './Form';
export * from './FormInput';
export * from './FormItem';
export * from './FormList';
export * from './FormRow';
export * from './FormTextArea';
export * from './useForm';

import {Form as BaseForm} from './Form';
import {FormInput} from './FormInput';
import {FormRow} from './FormRow';
import {FormItem} from './FormItem';
import {FormList} from './FormList';
import {FormTextArea} from './FormTextArea';
import {useForm} from './useForm';

export const Form = Object.assign(BaseForm, {
  Item: FormItem,
  Input: FormInput,
  Row: FormRow,
  TextArea: FormTextArea,
  List: FormList,
  useForm
});
