// @docs https://ant.design/components/table/

import {ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {
  dateStringSorterForField,
  localeSorterForField,
  numericSorterForField,
  refNumericSorterForField
} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell, utcDateTableRender} from '@joonca/library/src/utils/table';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import {Popconfirm, Tooltip} from 'antd';
import {ColumnsType, ColumnType} from 'antd/lib/table';
import {get, groupBy, mapValues} from 'lodash';
import React, {FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import MissionStatus, {MISSION_STATUS_LABELS} from 'src/components/app/mission/MissionStatus';
import isClosedColumn from 'src/components/app/table/isClosedColumn';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as applicationsActions} from 'src/store/modules/applications';
import {actions as missionsActions} from 'src/store/modules/missions';
import {State} from 'src/store/reducers';
import type {Mission} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

const MISSION_SEARCH_KEYS = [
  '_id',
  '_sid',
  'status',
  'startsAt',
  'endsAt',
  'organization.name',
  'position.name',
  'place.name',
  'internalTitle',
  'internalReference',
  'providerId'
];

type Props = {
  localStorageKey?: string;
};

const MissionList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchMissions, deleteMission} = useActions(missionsActions, []);
  const {aggregateApplications} = useActions(applicationsActions, []);
  // const [applicationCountByMissions, setApplicationCountByMissions] = useState<{[s: string]: number}>({});
  const latestApplicationCountByMissions = useRef<{[s: string]: number}>({});
  // const [pendingApplicationCountByMissions, setPendingApplicationCountByMissions] = useState<{[s: string]: number}>({});
  const latestPendingApplicationCountByMissions = useRef<{[s: string]: number}>({});

  const [searchValue, setSearchValue] = useState<string>('');
  const missions = useSelector<State, Array<Mission>>((state) => state.missions.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () =>
      Promise.all([
        fetchMissions(
          {},
          {
            query: {
              population: {
                position: 1,
                organization: {name: 1},
                aggregation: {applications: 1, enrollments: 1}
              }
            }
          }
        ),
        aggregateApplications(
          {},
          {
            query: {
              pipeline: [
                {$group: {_id: {mission: '$mission'}, count: {$sum: 1}}},
                {
                  $project: {
                    _id: 0,
                    mission: '$_id.mission',
                    count: 1
                  }
                }
              ]
            }
          }
        ).then(({body: results}) => {
          const asCountMap = mapValues(groupBy(results, 'mission'), (v) => v[0].count);
          // setApplicationCountByMissions(asCountMap);
          latestApplicationCountByMissions.current = asCountMap;
        }),
        aggregateApplications(
          {},
          {
            query: {
              pipeline: [
                {$match: {clientStatus: 'pending'}},
                {$group: {_id: {mission: '$mission'}, count: {$sum: 1}}},
                {$project: {_id: 0, mission: '$_id.mission', count: 1}}
              ]
            }
          }
        ).then(({body: results}) => {
          const asCountMap = mapValues(groupBy(results, 'mission'), (v) => v[0].count);
          // setPendingApplicationCountByMissions(asCountMap);
          latestPendingApplicationCountByMissions.current = asCountMap;
        })
      ]),
    [fetchMissions]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<Mission>>(
    () => [
      // {
      //   title: 'Location',
      //   dataIndex: 'place.location',
      //   width: 128,
      //   render: (value, record) => (
      //     <div style={{position: 'relative'}}>
      //       <StaticMap
      //         width={128}
      //         height={64}
      //         longitude={value.coordinates[0]}
      //         latitude={value.coordinates[1]}
      //         zoom={13}
      //         retina
      //       />
      //       <span
      //         style={{
      //           fontSize: '11px',
      //           background: 'white',
      //           opacity: '0.85',
      //           position: 'absolute',
      //           bottom: '0px',
      //           right: '0px'
      //         }}
      //       >
      //         # {record.internalReference}
      //       </span>
      //     </div>
      //   )
      // },
      {
        title: 'Mission',
        dataIndex: '_sid',
        sorter: localeSorterForField('internalTitle'),
        render: (value: string, record) => (
          <>
            <Link to={`/work/missions/${value}/view`}>{get(record, 'internalTitle', 'SANS TITRE')}</Link>
            <div>
              <small>{get(record, 'internalReference', 'N/A')}</small>
            </div>
          </>
        )
      },
      // {
      //   title: 'Inscrits',
      //   dataIndex: 'enrollments',
      //   width: 64,
      //   // sorter: numericSorterForField('headCount'),
      //   sorter: refNumericSorterForField(latestApplicationCountByMissions, '_id'),
      //   render: (value, record) => {
      //     const count = latestApplicationCountByMissions.current[record._id] || 0;
      //     const pendingCountcount = latestPendingApplicationCountByMissions.current[record._id] || 0;
      //     return (
      //       <span>
      //         {count}
      //         <span style={{color: presetPalettes['grey'][2]}}>/{record.headCount}</span>
      //         {pendingCountcount > 0 ? (
      //           <Icon type={'exclamation-circle'} style={{color: presetPalettes['volcano'][4], marginLeft: '4px'}} />
      //         ) : null}
      //       </span>
      //     );
      //   }
      // },
      // {
      //   title: 'A traiter',
      //   dataIndex: 'pendingApplicationCountByMissions',
      //   width: 64,
      //   sorter: refNumericSorterForField(latestPendingApplicationCountByMissions, '_id'),
      //   render: (value, record) => {
      //     const count = latestPendingApplicationCountByMissions.current[record._id] || 0;
      //     return (
      //       <span>
      //         {count > 0 ? (
      //           <span style={{color: presetPalettes['volcano'][4]}}>
      //             <Icon type={'exclamation-circle'} style={{marginRight: '4px'}} />
      //             {count}
      //           </span>
      //         ) : (
      //           count
      //         )}
      //       </span>
      //     );
      //   }
      // },
      // {
      //   title: 'Position',
      //   dataIndex: 'position',
      //   render: value => (value ? value.name : '')
      // },
      // {
      //   title: 'Organization',
      //   dataIndex: 'organization.name',
      //   width: 128
      // },
      {
        title: 'Ville',
        dataIndex: ['place', 'postalCode'],
        sorter: localeSorterForField('place.postalCode'),
        width: 128,
        render: (value, record) => (
          <span style={{fontSize: '12px'}}>
            {value}
            <br />
            {get(record, 'place.locality', 'N/A')}
          </span>
        )
      },
      {
        title: 'Postes',
        dataIndex: 'headCount',
        width: 96,
        sorter: numericSorterForField('headCount'),
        render: (value) => <span>{value}</span>
      },
      {
        title: 'Candidats',
        dataIndex: 'applications',
        width: 96,
        // sorter: numericSorterForField('headCount'),
        sorter: refNumericSorterForField(latestApplicationCountByMissions, '_id'),
        render: (_value, record) => {
          const count = latestApplicationCountByMissions.current[record._id] || 0;
          const pendingCountcount = latestPendingApplicationCountByMissions.current[record._id] || 0;
          return (
            <span>
              {count}
              <span style={{color: presetPalettes['grey'][2]}}>/{record.headCount}</span>
              {pendingCountcount > 0 ? (
                <ExclamationCircleOutlined style={{color: presetPalettes['volcano'][4], marginLeft: '4px'}} />
              ) : null}
            </span>
          );
        }
      },
      {
        title: 'Début',
        dataIndex: 'startsAt',
        sorter: dateStringSorterForField('startsAt'),
        render: utcDateTableRender
      },
      {
        title: 'Fin',
        dataIndex: 'endsAt',
        sorter: dateStringSorterForField('endsAt'),
        render: utcDateTableRender
      },
      (isClosedColumn as unknown) as ColumnType<Mission>,
      {
        title: 'Statut',
        dataIndex: 'status',
        filters: Object.keys(MISSION_STATUS_LABELS).map((value) => ({
          value,
          text: MISSION_STATUS_LABELS[value]
        })),
        onFilter: (value, record) => record.status === value,
        render: (status: string) => <MissionStatus status={status} />
      },
      {
        title: 'Création',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('missions')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 4,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <Tooltip title="Voir" placement="bottom">
                <LinkButton ghost type="primary" icon="search" to={`/work/missions/${_sid}/view`} />
              </Tooltip>
              <Tooltip title="Editer" placement="bottom">
                <LinkButton disabled ghost type="primary" icon="edit" to={`/work/missions/${_sid}/edit`} />
              </Tooltip>
              <Tooltip title="Supprimer" placement="bottom">
                <Popconfirm
                  title="Are you sure?"
                  placement="topRight"
                  onConfirm={() => deleteMission({_sid})}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
                </Popconfirm>
              </Tooltip>
            </Button.Group>
          );
        }
      }
    ],
    [deleteMission, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon="plus" to="/missions/create">
          Add Mission
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={missions}
          searchKeys={MISSION_SEARCH_KEYS}
          searchValue={searchValue}
          rowKey="_sid"
          localStorageKey={localStorageKey || 'MissionList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default MissionList;
