const PLURALIZED_WORDS_MAP: {[s: string]: string} = {
  Inventoriste: 'Inventoristes'
};

const pluralize = (word: string, count: number): string => {
  const isPlural = count === 0 || count > 1;
  if (!isPlural) {
    return word;
  }
  if (!PLURALIZED_WORDS_MAP[word]) {
    console.warn(`Missing plural for word="${word}"`);
    return `${word}s`;
  }
  return PLURALIZED_WORDS_MAP[word];
};

export default pluralize;
