import React, {FunctionComponent} from 'react';
import {Switch} from 'react-router-dom';

import AppLayout from 'src/layouts/AppLayout';
import FileList from 'src/views/files/FileList';
import FileView from 'src/views/files/FileView';
import PrivateRoute from '../helpers/PrivateRoute';

type Props = {};

const FileRoutes: FunctionComponent<Props> = () => (
  <Switch>
    <PrivateRoute exact path="/files" component={FileList} layout={AppLayout} />
    <PrivateRoute path="/files/:id/view" component={FileView} layout={AppLayout} />
    {/* <PrivateRoute exact path="/files/create" component={FileForm} layout={AppLayout} /> */}
  </Switch>
);

export const breadcrumbs = {
  '/files': {label: 'Fichiers', icon: 'hdd'},
  '/files/create': {label: 'Create', icon: 'edit'},
  '/files/:id/view': {label: 'View', icon: 'search'}
};

export default FileRoutes;
