import React, {FunctionComponent, useMemo, useState, useEffect} from 'react';
import {ViewState} from 'react-map-gl';
import {MapView} from 'src/components/map';
import {default as PlaceSelect, Props as PlaceSelectProps} from 'src/components/place/PlaceSelect/PlaceSelect';
import styled from 'styled-components';

export type Props = PlaceSelectProps & {flyToZoom?: number};

const PlacePicker: FunctionComponent<Props> = ({flyToZoom = 16, value, ...otherProps}) => {
  const [mapIsVisible, setMapIsVisible] = useState<boolean>(false);
  const viewport = useMemo<ViewState | null>(
    () =>
      value && value.center ? {longitude: value.center[0], latitude: value.center[1], zoom: flyToZoom} : null,
    [value]
  );

  useEffect(() => {
    setTimeout(() => {
      setMapIsVisible(true);
    }, 500);
  }, []);

  return (
    <Container>
      <StyledPlaceSelect value={value} limit={6} {...otherProps} />
      {mapIsVisible ? <StyledMapView height={300} viewport={viewport} /> : <StyledLoadingMapView />}
    </Container>
  );
};

export default PlacePicker;

const Container = styled.div.attrs({className: 'ant-row'})`
  justify-content: flex-end;
  align-items: stretch;
`;

const StyledPlaceSelect = styled(PlaceSelect)`
  margin-bottom: 12px;
`;

const StyledMapView = styled(MapView)`
  border-radius: 4px;
  overflow: hidden;
`;

const StyledLoadingMapView = styled.div`
  background: #efe9e1;
  height: 300px;
  width: 100%;
`;
