import React, {FunctionComponent, ComponentClass, ComponentType} from 'react';
import {Route, RouteProps, RouteComponentProps} from 'react-router-dom';

type Props = RouteProps & {
  layout?: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
};

const RouteWithLayout: FunctionComponent<Props> = ({layout, component, ...otherProps}) => {
  if (!component) {
    return null;
  }
  return (
    <Route
      {...otherProps}
      render={(props: typeof otherProps) =>
        layout
          ? React.createElement(layout, props, React.createElement(component, props))
          : React.createElement(component, props)
      }
    />
  );
};

export default RouteWithLayout;
