import React, {Fragment, Component} from 'react';
import {Button, Spin, Input} from 'antd';
import {Route, RouteChildrenProps} from 'react-router';
import {FormFooter, FormItem, FormComponentProps, FormGroup} from 'src/components/form';
import {InputNumber} from 'antd';
import {USE_FIXTURES} from 'src/config/env';
import memoize from 'memoize-one';
import moment, {isMoment} from 'moment';
import MissionDateSlotFieldset, {fixture} from 'src/components/form/fieldset/MissionDateSlotFieldset';
import MissionDateSlotsList from 'src/components/list/MissionDateSlotsList';
import MomentDate from 'src/components/content/MomentDate';
import {combineMomentDateTime} from '@joonca/library/src/utils/date';

// import type {FormProps} from 'antd';
// import type {ContextRouter} from 'react-router-dom';

const DEFAULTS = {
  enrollmentDeadlineHours: 2,
  meetingTimeMinutes: 15
};

const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring

type Props = RouteChildrenProps &
  FormComponentProps & {
    actions: any;
    values: any;
    isEditing: boolean;
    isFetching: boolean;
    onChange: (...args: any[]) => void;
  };

export default class MissionFormDatesStep extends Component<Props> {
  static defaultProps = {
    values: {
      dateSlots: []
    }
  };

  getDerivedValues = memoize((values) => {
    if (!values) {
      return values;
    }

    const momentKeys = ['startDate', 'endDate', 'startTime', 'endTime'];
    const castedValues = momentKeys.reduce(
      (soFar, key) => {
        if (momentKeys.includes(key) && !isMoment(values[key])) {
          soFar[key] = moment(values[key]); // eslint-disable-line no-param-reassign
        }
        return soFar;
      },
      {...values}
    );

    const {startDate, endDate, startTime, endTime} = castedValues;
    const {ofVariableDuration, estimatedDriftHours, weekDays} = values;
    const derivedValues = {times: {startTime, endTime}, weekDays, ofVariableDuration, estimatedDriftHours};
    if (startDate.isSame(endDate)) {
      derivedValues.date = startDate;
      derivedValues.singleDate = true;
    } else {
      derivedValues.dates = {startDate, endDate};
    }
    derivedValues.everyDay = !weekDays || weekDays.length === 7;
    console.warn('getDerivedValues', {derivedValues});
    return derivedValues;
  });

  static getIntegratedValues(values) {
    const {
      singleDate,
      dates,
      date,
      everyDay,
      weekDays,
      ofVariableDuration = false,
      times,
      estimatedDriftHours
    } = values;
    const integratedValues = {ofVariableDuration, estimatedDriftHours};
    // Dates
    if (singleDate) {
      integratedValues.startDate = date;
      integratedValues.endDate = date;
    } else {
      integratedValues.startDate = dates.startDate;
      integratedValues.endDate = dates.endDate;
    }
    // Days
    if (everyDay) {
      integratedValues.weekDays = [1, 2, 3, 4, 5, 6, 0];
    } else {
      integratedValues.weekDays = weekDays;
    }
    // Time
    integratedValues.startTime = times.startTime;
    integratedValues.endTime = times.endTime;
    console.warn('getIntegratedValues', {values, integratedValues});
    return integratedValues;
  }

  checkDates = (rule, value, callback) => {
    const {values: parentValues} = this.props;
    const {dateSlots} = parentValues;
    if (!dateSlots.length) {
      callback();
      return;
    }
    const {field} = rule;
    callback();
    // switch (field) {
    //   case 'dates': {
    //     const hasError = dateSlots.some(({startDate, endDate}) => );
    //     callback('damn!');
    //     return;
    //   }
    //   case 'date':
    //     callback('damn2!');
    //     return;
    //   default:
    //     callback();
    // }
  };

  handleSubmitClick = (ev: SyntheticEvent<EventTarget>, itemIndex: number) => {
    const {form, match, history, values, onChange} = this.props;
    const {getIntegratedValues} = this.constructor;
    const {dateSlots} = values;
    ev.preventDefault();
    // $FlowFixMe
    form.validateFields((err, currentValues) => {
      if (err) {
        return;
      }
      const integratedValues = getIntegratedValues(currentValues);
      if (itemIndex) {
        dateSlots[itemIndex] = integratedValues;
        onChange({dateSlots: dateSlots.slice()});
      } else {
        onChange({dateSlots: dateSlots.concat([integratedValues])});
      }
      history.push(`${match.url}/dates`);
    });
  };

  handleCancelClick = () => {
    const {history, match} = this.props;
    history.push(`${match.url}/dates`);
  };

  handleDeleteItem = (index: number) => {
    const {values, onChange} = this.props;
    const {dateSlots} = values;
    dateSlots.splice(index, 1);
    onChange({dateSlots: dateSlots.slice()});
  };

  renderList = () => {
    const {form, values: parentValues} = this.props;
    const {dateSlots} = parentValues;
    const {getFieldDecorator} = form;
    const {
      enrollmentDeadlineHours = DEFAULTS.enrollmentDeadlineHours,
      meetingTimeMinutes = DEFAULTS.meetingTimeMinutes
    } = form.getFieldsValue(['enrollmentDeadlineHours', 'meetingTimeMinutes']);
    const startsAt = dateSlots.length
      ? moment.utc(combineMomentDateTime(dateSlots[0].startDate, dateSlots[0].startTime))
      : null;

    startsAt &&
      console.warn('render', {
        isLocal: startsAt.isLocal(),
        isUtc: startsAt.isUtc(),
        format: startsAt.format('HH:mm:ss'),
        iso: startsAt.toISOString(),
        enrollmentDeadlineHours,
        meetingTimeMinutes
      });
    return (
      <>
        <MissionDateSlotsList onDeleteItem={this.handleDeleteItem} items={dateSlots} {...this.props} />
        <FormItem label="Details">
          {getFieldDecorator('scheduleDetails', {
            rules: [{required: false}]
          })(
            <TextArea
              placeholder="Par exemple: Merci de vous présenter 20 minutes avant l'heure de début de la mission."
              rows={3}
            />
          )}
        </FormItem>
        <FormGroup>
          <FormItem label="Date de fin d'inscription">
            {getFieldDecorator('enrollmentDeadlineHours', {
              initialValue: DEFAULTS.enrollmentDeadlineHours,
              rules: [{required: true}]
            })(<InputNumber min={1} max={999} />)}
            <span> heure(s) avant le début de la mission</span>
            {startsAt ? (
              <span>
                :{' '}
                <MomentDate
                  value={startsAt}
                  format="[le] L [à] HH[h]mm"
                  subtract={enrollmentDeadlineHours}
                  unit="hours"
                />
              </span>
            ) : null}
          </FormItem>
        </FormGroup>
        <FormItem label="Date et heure de première présentation" style={{flex: '0 0 90px'}}>
          {getFieldDecorator('meetingTimeMinutes', {
            initialValue: DEFAULTS.meetingTimeMinutes,
            rules: [{required: true}]
          })(<InputNumber min={1} max={999} />)}
          <span> minutes avant le début de la mission</span>
          {startsAt ? (
            <span>
              : <MomentDate value={startsAt} format="[le] L [à] HH[h]mm" subtract={meetingTimeMinutes} unit="minutes" />
            </span>
          ) : null}
        </FormItem>

        {/* <FormItem label="Deadline">
          {getFieldDecorator('deadlineDeltaTime', {
            initialValue: moment(new Date(864e5)),
            rules: [{type: 'object', required: true}]
          })(<TimePicker format="HH:mm" minuteStep={15} />)}
        </FormItem> */}
      </>
    );
  };

  renderForm = ({match}: RouteChildrenProps) => {
    const {form, values: parentValues, isFetching} = this.props;
    const isEditing = 'index' in match!.params;
    const values = isEditing ? this.getDerivedValues(parentValues.dateSlots[match!.params.index]) : undefined;
    const isPreloading = isEditing && isFetching;
    return (
      <Spin spinning={isPreloading} delay={100}>
        <MissionDateSlotFieldset
          form={form}
          values={USE_FIXTURES && !isEditing ? fixture : values}
          validator={this.checkDates}
        />
        <FormFooter>
          <Button htmlType="button" onClick={this.handleCancelClick}>
            Cancel
          </Button>
          &nbsp;
          <Button type="primary" htmlType="submit" onClick={(ev) => this.handleSubmitClick(ev, match.params.index)}>
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </FormFooter>
      </Spin>
    );
  };

  render() {
    const {match, isEditing, values} = this.props;
    console.count(`${this.constructor.name}.render()`);
    // console.warn(`${this.constructor.name}.render()`, {values});
    const shouldRedirect = !isEditing && !values.dateSlots.length;
    return (
      <Fragment>
        {/* <Route
          exact
          path={`${match.path}/dates`}
          render={() => (shouldRedirect ? <Redirect to={`${match.path}/dates/add`} /> : null)}
        /> */}
        <Route exact path={`${match!.path}/dates`} render={this.renderList} />
        <Route exact path={`${match!.path}/dates/add`} render={this.renderForm} />
        <Route exact path={`${match!.path}/dates/edit/:index`} render={this.renderForm} />
      </Fragment>
    );
  }
}
