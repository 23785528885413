import React, {cloneElement, Children, PureComponent, Fragment} from 'react';
import {pick} from 'lodash';
import styled from 'styled-components';
import {time, timeEnd} from '@joonca/library/src/utils/console';
import memoize from 'memoize-one';
import {FormItem} from 'src/components/form';

import {Icon, Select} from 'antd';

const Option = Select.Option; // eslint-disable-line prefer-destructuring

export {Option};

const NestedOptionList = styled('ul')`
  width: 100%;
  margin-bottom: 0;
  padding-inline-start: 24px;
  > li {
    display: flex;
    align-items: center;
    > span {
      margin-left: 8px;
      margin-right: 24px;
      width: 220px;
      flex: 1 1 100%;
      /* text-transform: uppercase; */
    }
    > *:nth-child(2) {
      background: 'red';
    }
  }
`;

const NestedFormItem = styled(FormItem)`
  &.ant-form-item {
    margin-bottom: 0px;
  }
`;

const NestedOptionListItem = ({children, label}) => (
  <li>
    <Icon type="caret-right" />
    <span>{label}</span>
    {children}
    <div style={{flex: '1 1 100%'}} />
  </li>
);

export default class NestedSelect extends PureComponent {
  state = {
    parentValue: [],
    nestedValue: {}
  };

  static getDerivedStateFromProps(props, state) {
    const {value} = props;
    if (!value) {
      return state;
    }
    return {
      ...state,
      parentValue: Object.keys(value),
      nestedValue: value
    };
  }

  // @NOTE does not work
  static getDerivedChildrenMapValues = memoize((children) => {
    time('NestedSelect.getDerivedChildrenMapValues()');
    const result = Children.toArray(children).reduce((soFar, child) => {
      const {value, children: grandChildren} = child.props;
      soFar[value] = grandChildren; // eslint-disable-line no-param-reassign
      return soFar;
    }, {});
    timeEnd('NestedSelect.getDerivedChildrenMapValues()');
    return result;
  });

  get childrenValuesMap() {
    const {children} = this.props;
    return this.constructor.getDerivedChildrenMapValues(children);
  }

  handleSelectChange = (selectValue: Array<string>) => {
    const {nestedDefaultValue, onChange} = this.props;
    const {nestedValue: prevValue} = this.state;
    const selectValueAsObject = selectValue.reduce((soFar, value) => {
      soFar[value] = nestedDefaultValue || ''; // eslint-disable-line no-param-reassign
      return soFar;
    }, {});
    const nestedValue = {...selectValueAsObject, ...pick(prevValue, selectValue)};
    this.setState({parentValue: selectValue, nestedValue});
    if (onChange) {
      onChange(nestedValue);
    }
  };

  handleNestedSelectChangeForValue = (selectValue, parentValue) => {
    const {onChange} = this.props;
    const {nestedValue: prevValue} = this.state;
    const nestedValue = {...prevValue, [parentValue]: selectValue};
    this.setState({nestedValue});
    if (onChange) {
      onChange(nestedValue);
    }
  };

  render() {
    const {children, nestedInput, nestedLabel, nestedDefaultValue, ...otherProps} = this.props;
    const {parentValue, nestedValue} = this.state;
    return (
      <Fragment>
        <Select mode="multiple" {...otherProps} onChange={this.handleSelectChange} value={parentValue}>
          {children}
        </Select>
        {parentValue.length ? (
          <NestedFormItem label={nestedLabel}>
            <NestedOptionList>
              {parentValue.map((value) => (
                <NestedOptionListItem key={value} label={this.childrenValuesMap[value]}>
                  {cloneElement(nestedInput, {
                    onChange: (childValue) => this.handleNestedSelectChangeForValue(childValue, value),
                    value: nestedValue[value]
                  })}
                </NestedOptionListItem>
              ))}
            </NestedOptionList>
          </NestedFormItem>
        ) : null}
      </Fragment>
    );
  }
}

/*
            <li key={language}>
              <Icon type="caret-right" />
              <span>{LANGUAGES_MAP[language]}</span>
              {getFieldDecorator(`languageLevels[${language}]`, {
                rules: [{required: false}],
                initialValue: 'elementary'
              })(
                <Select>
                  <Option value="none">Aucun</Option>
                  <Option value="elementary">Basique</Option>
                  <Option value="professional-working">Avancé</Option>
                  <Option value="full-professional">Courant</Option>
                  <Option value="first-language">Maternelle</Option>
                </Select>
              )}
              <div style={{flex: '1 1 100%'}} />
            </li>
            */
