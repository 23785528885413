// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders
// @source https://github.com/ant-design/ant-design/blob/master/components/select/index.tsx

import {Select as BaseSelect} from 'antd';
import {SelectProps as BaseSelectProps, SelectValue} from 'antd/lib/select';
import React, {forwardRef, ReactElement} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';

const {Option} = BaseSelect;

type Size = 'xl' | 'xxl';

const SIZES: {[key in Size]: {fontSize: number; height: number; padding: number}} = {
  xl: {fontSize: 18, height: 46, padding: 18},
  xxl: {fontSize: 24, height: 70, padding: 22}
};

const sizeMixin = (size: Size): FlattenInterpolation<unknown> => css`
  &.ant-select {
    /* &:hover {
      cursor: pointer;
    } */
    &.ant-select-${size} {
      font-size: ${SIZES[size].fontSize}px;
      .ant-select-arrow {
        right: ${SIZES[size].fontSize * 2}px;
      }
    }
    &.ant-select-single.ant-select-${size}:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: ${SIZES[size].height}px;
        padding: 0 ${SIZES[size].padding}px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: ${SIZES[size].height}px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: ${SIZES[size].height - 2}px;
      }
    }
    &.ant-select-multiple.ant-select-${size}:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: ${SIZES[size].height}px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: ${SIZES[size].height - 12}px;
        height: ${SIZES[size].height - 12}px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: ${SIZES[size].height - 2}px;
      }
    }
  }
`;

const StyledSelect = styled(BaseSelect)`
  /* &.ant-input {
    background: red;
  } */
  /* &.ant-select {
    &.ant-select-xl {
      font-size: 16px;
    }
    &.ant-select-single.ant-select-xl:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 40px;
      }
      .ant-select-selection-placeholder {
        line-height: 40px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 38px;
      }
    }
  } */
  ${sizeMixin('xl')}
  ${sizeMixin('xxl')}
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

// @url https://github.com/styled-components/styled-components/issues/1803#issuecomment-573196034
export type SelectProps<T> = Omit<BaseSelectProps<T>, 'size'> & {
  size?: SizeType;
};

const SelectWithoutRef = <ValueType extends SelectValue = SelectValue>(
  props: React.PropsWithChildren<SelectProps<ValueType>>,
  ref: React.Ref<typeof BaseSelect>
): ReactElement | null => {
  return (
    <StyledSelect<ValueType>
      ref={ref as React.Ref<BaseSelect<ValueType>>}
      {...(props as BaseSelectProps<ValueType>)}
      {...(mapSizeProps(props, 'ant-select-') as BaseSelectProps<ValueType>)}
    />
  );
};
// const SelectWithoutRef: RefForwardingComponent<typeof BaseSelect, SelectProps<SelectValue>> = (props, ref) => {
//   // const classPrefix = props.prefix || props.suffix ? 'ant-Select-affix-wrapper-' : 'ant-Select-';
//   return createElement(StyledSelect, {
//     ref,
//     ...props,
//     ...mapSizeProps(props, 'ant-select-')
//   } as BaseSelectProps<SelectValue>);
// };
export const Select = forwardRef(SelectWithoutRef);

export default Object.assign(Select, {
  Option
});
