import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {WrappedFormUtils} from '.';

// import type {Node} from 'react';

const StyledFieldset = styled('fieldset')`
  /* font-size: 1.28rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1.28571rem;
  color: #1b1e2b; */
`;

const Legend = styled('legend')`
  /* font-size: 1.28rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1.28571rem;
  color: #1b1e2b; */
`;

type Props = {
  children?: Node;
  values?: {[s: string]: any};
  form: WrappedFormUtils;
  legend?: string;
};

export default class Fieldset extends PureComponent<Props> {
  state = {
    lastValues: null
  };

  // static getDerivedStateFromProps(props: Props, state: State) {
  //   console.warn(`${super.name}#getDerivedStateFromProps()`, {props});
  //   return state;
  // }

  componentDidMount() {
    const {values, form} = this.props;
    if (form && values) {
      console.warn(`${this.constructor.name}.componentDidMount()`, {values});
      this.setState({lastValues: values}, () => {
        form.setFieldsValue(values);
      });
    }
  }

  componentDidUpdate() {
    const {values, form} = this.props;
    const {lastValues} = this.state;
    if (form && values !== lastValues) {
      // console.warn(`${this.constructor.name}.componentDidUpdate()`, {values});
      // eslint-disable-next-line
      this.setState({lastValues: values}, () => {
        form.setFieldsValue(values);
      });
    }
  }

  render() {
    const {legend, children} = this.props;
    return (
      <StyledFieldset>
        {legend ? <Legend>{legend}</Legend> : null}
        {children}
      </StyledFieldset>
    );
  }
}
