const black = '#000';
const white = '#fff';
const blue = {
  100: '#e8f5ff',
  200: '#d1eaff',
  300: '#a3d6ff',
  400: '#068dfe',
  500: '#0890ff',
  600: '#077ee0',
  700: '#066fc4',
  800: '#0765b3',
  900: '#04508f'
};
const green = {
  100: '#e8ffee',
  200: '#cdfad8',
  300: '#9cf0b1',
  400: '#78de91',
  500: '#54d575',
  600: '#3ec961',
  700: '#34b353',
  800: '#27a847',
  900: '#118f31'
};
const purple = {
  100: '#efedff',
  200: '#d4cffc',
  300: '#aea3ff',
  400: '#8f82f5',
  500: '#6b5aed',
  600: '#5a48e0',
  700: '#4939c4',
  800: '#302396',
  900: '#1c0f7d'
};
const red = {
  100: '#ffe8e8',
  200: '#ffbaba',
  300: '#ffa1a1',
  400: '#ff8787',
  500: '#f84f4f',
  600: '#e02d2d',
  700: '#c41d1d',
  800: '#a61212',
  900: '#940303'
};
const turquoise = {
  100: '#e8fffe',
  200: '#d1fffd',
  300: '#a3fffa',
  400: '#63ebe4',
  500: '#07cec4',
  600: '#00b9af',
  700: '#00a199',
  800: '#038a83',
  900: '#026e68'
};
const yellow = {
  100: '#fffae6',
  200: '#fff0b3',
  300: '#ffe380',
  400: '#ffd000',
  500: '#ffab00',
  600: '#ff941f',
  700: '#ff8400',
  800: '#eb7900',
  900: '#cf6b00'
};
const grey = {
  100: '#fafafc',
  200: '#f5f6fa',
  300: '#f0f1f5',
  400: '#e9eaf0',
  500: '#d5d8e3',
  600: '#9fa3b5',
  700: '#6a6f85',
  800: '#464c63',
  900: '#262a3e'
};

export default {
  black,
  white,
  blue,
  green,
  purple,
  red,
  turquoise,
  yellow,
  grey
  // primary: purple,
  // secondary: turquoise,
  // info: blue,
  // warning: yellow,
  // danger: red
};
