import {RuleType} from 'rc-field-form/lib/interface';
import React, {FunctionComponent, useMemo} from 'react';
import {Input, InputProps} from '../input';
import {FormItem, FormItemProps} from './FormItem';

const RULE_MESSAGES = {
  required: 'saisie requise',
  pattern: 'saisie invalide',
  type: 'saisie invalide'
};

const classNames = (...args: (string | undefined)[]): string => args.filter(Boolean).join(' ');

export type FormInputProps = Pick<FormItemProps, 'icon' | 'label' | 'rules' | 'initialValue'> &
  Omit<InputProps, 'pattern' | 'type'> & {
    // required?: boolean;
    pattern?: RegExp;
    type?: RuleType;
  };

export const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  icon,
  label,
  rules: propRules = [],
  initialValue,
  required,
  pattern,
  className,
  disabled,
  style,
  type,
  ...otherProps
}) => {
  const itemName = name && name.includes('.') ? name.split('.') : name;
  const rules = useMemo<NonNullable<FormItemProps['rules']>>(
    () =>
      propRules
        .concat(required ? [{required: true, message: RULE_MESSAGES.required}] : [])
        .concat(pattern ? [{pattern, message: RULE_MESSAGES.pattern}] : [])
        .concat(type ? [{type, message: RULE_MESSAGES.type}] : []),
    [propRules, required, pattern, type]
  );
  // const inputPattern = pattern ? pattern.toString().slice(1, -1) : undefined;
  return (
    <FormItem
      name={itemName}
      icon={icon}
      label={label}
      rules={rules}
      initialValue={initialValue}
      className={classNames(
        className,
        'ant-form-item-is-input',
        rules.length ? 'ant-form-item-has-rules' : undefined,
        disabled ? 'ant-form-item-disabled' : undefined
      )}
      style={style}
    >
      <Input disabled={disabled} {...otherProps} />
    </FormItem>
  );
};

/*
// @source https://github.com/ant-design/ant-design/tree/master/components/form

import {Form as BaseForm} from 'antd';
import {InputProps} from 'antd/lib/input';
import React, {FunctionComponent, useMemo} from 'react';
import Input from './../Input/Input';
import {FormItemProps} from './Form';
import {LockOutlined} from '@ant-design/icons';
import {colors} from '@joonca/library';

const {Item} = BaseForm;

export type FormInputProps = Omit<FormItemProps, 'name'> &
  Omit<InputProps, 'name' | 'required' | 'pattern'> & {
    name: string;
    required?: boolean;
    pattern?: RegExp;
  };

export const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  label,
  rules: propRules = [],
  required,
  disabled,
  pattern,
  type,
  placeholder,
  size = 'large',
  ...otherInputProps
}) => {
  const itemName = name && name.includes('.') ? name.split('.') : name;
  const rules = useMemo<FormItemProps['rules']>(
    () =>
      propRules
        .concat(required ? [{required: true, message: 'saisie requise'}] : [])
        .concat(pattern ? [{pattern, message: 'saisie invalide'}] : [])
        .concat(type ? [{type, message: 'saisie invalide'}] : []),
    [propRules, required, pattern, type]
  );
  return (
    <Item label={label} name={itemName} disabled={disabled} rules={rules}>
      <Input
        suffix={disabled ? <LockOutlined style={{color: colors.grey[600]}} /> : null}
        type={type}
        size={size}
        placeholder={String(label) || placeholder}
        disabled={disabled}
        {...otherInputProps}
      />
    </Item>
  );
};

*/
