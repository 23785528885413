const offset = 127397;
const A = 65;
const Z = 90;

// @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const alpha2Emoji = (code: string): string => {
  if (!code || code.length !== 2) {
    throw new Error('Not an alpha2 country code');
  }
  const upperCaseCode = code.toUpperCase();
  const f = upperCaseCode.codePointAt(0) as number;
  const s = upperCaseCode.codePointAt(1) as number;
  if (f > Z || f < A || s > Z || s < A) {
    throw new Error('Not an alpha2 country code');
  }
  return String.fromCodePoint(f + offset) + String.fromCodePoint(s + offset);
};
