import Geocoding, {
  GeocodeFeature,
  GeocodeRequest,
  GeocodeResponse,
  GeocodeService
} from '@mapbox/mapbox-sdk/services/geocoding';
import Select, {LabeledValue, SelectProps} from 'antd/lib/select';
import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDebounce} from 'src/hooks';
import {useIsMounted} from '../hooks';

const {REACT_APP_MAPBOX_API_ACCESS_TOKEN: defaultAccessToken} = process.env;

// @NOTE GeocodeFeature['id'] does not properly resolve back a full place_name
export type PlaceSelectValue = GeocodeFeature['place_name'];

export type PlaceSelectProps = SelectProps<PlaceSelectValue> &
  Pick<GeocodeRequest, 'countries' | 'language' | 'proximity' | 'limit'> & {
    accessToken?: string;
    // value?: PlaceSelectValue;
    // onChange?: (value: PlaceSelectValue) => void;
    onFeatureSelect?: (feature: GeocodeFeature) => void;
  };

const optionFromFeature = (feature: GeocodeFeature): LabeledValue => ({
  label: feature.place_name,
  value: feature.id
});

export const placeSelectDefaults: Pick<GeocodeRequest, 'countries' | 'language' | 'proximity' | 'limit'> = {
  countries: ['FR'],
  language: ['fr-FR'],
  proximity: [2.349014, 48.864716],
  limit: 5
};

export const PlaceSelect: FunctionComponent<PlaceSelectProps> = ({
  value,
  onChange,
  onFeatureSelect,
  accessToken = defaultAccessToken || '',
  countries = placeSelectDefaults.countries,
  language = placeSelectDefaults.language,
  proximity = placeSelectDefaults.proximity,
  limit = placeSelectDefaults.limit,
  ...otherProps
}) => {
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const {current: geocodingService} = useRef<GeocodeService>(Geocoding({accessToken}));
  const latestFeatures = useRef<GeocodeFeature[]>([]);
  const isMounted = useIsMounted();

  const handleSelect = useCallback<NonNullable<SelectProps<PlaceSelectValue>['onSelect']>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (value, option) => {
      const feature = latestFeatures.current.find((feature) => feature.id === value);
      if (!feature) {
        throw new Error(`Unexpected missing feature with id="${value}"`);
      }
      setIsSearching(false);
      if (onFeatureSelect) {
        onFeatureSelect(feature);
      }
      if (onChange) {
        onChange(feature.place_name, option);
      }
    },
    [onFeatureSelect, onChange]
  );

  const handleSearch = useCallback(
    async (searchValue) => {
      if (!searchValue) {
        return;
      }
      setIsSearching(true);
      const {body, statusCode} = await geocodingService
        .forwardGeocode({
          query: searchValue,
          mode: 'mapbox.places',
          countries,
          language,
          types: ['address', 'poi'],
          limit,
          proximity
        })
        .send();
      if (statusCode !== 200) {
        console.warn(`Failed to forwardGeocode with Mapbox`, body);
        return;
      }
      const {features} = body as GeocodeResponse;
      latestFeatures.current = features;
      if (isMounted()) {
        setOptions(features.map(optionFromFeature));
      }
    },
    [geocodingService, countries, language, limit, proximity, isMounted]
  );

  // @NOTE Trigger search to re-build a valid options set when receiving a new value
  const isValidOption = useMemo<boolean>(() => options.some((option) => option.value === value), [
    value,
    options
  ]);
  useEffect(() => {
    async function applyEffect(): Promise<void> {
      if (!value || isValidOption || isSearching) {
        return;
      }
      await handleSearch(value);
    }
    applyEffect();
  }, [value, handleSearch, isValidOption, isSearching]);

  const debouncedSearch = useDebounce(handleSearch, 500, {leading: false});

  return (
    <Select
      value={value}
      showSearch
      options={options}
      onSelect={handleSelect}
      onSearch={debouncedSearch}
      filterOption={false}
      notFoundContent={null}
      {...otherProps}
    />
  );
};
