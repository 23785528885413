import React from 'react';
import {Input, Select} from 'antd';
import {FormItem, FormGroup, Fieldset, WrappedFormUtils} from 'src/components/form';
// import MapView, {Marker} from 'src/components/map/MapView';
// import MapPin from 'src/components/map/MapPin';
// import AddressAutocomplete from 'src/components/form/autocomplete/AddressAutocomplete';
import {PlacePicker} from '@joonca/library';

const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring
const Option = Select.Option; // eslint-disable-line prefer-destructuring

// export const fixture = {
//   location: {
//     id: 'address.1416759036',
//     type: 'Feature',
//     place_type: ['address'],
//     relevance: 0.96,
//     properties: {},
//     'text_fr-FR': 'Rue Diderot',
//     'place_name_fr-FR': '15 Rue Diderot, 78110 Le Vésinet, France',
//     text: 'Rue Diderot',
//     place_name: '15 Rue Diderot, 78110 Le Vésinet, France',
//     center: [2.125835, 48.897349],
//     geometry: {type: 'Point', coordinates: [2.125835, 48.897349]},
//     address: '15',
//     context: [
//       {id: 'postcode.6151385510293060', 'text_fr-FR': '78110', text: '78110'},
//       {
//         id: 'place.6151385511225310',
//         wikidata: 'Q641607',
//         'text_fr-FR': 'Le Vésinet',
//         'language_fr-FR': 'fr',
//         text: 'Le Vésinet',
//         language: 'fr'
//       },
//       {
//         id: 'region.28017',
//         short_code: 'FR-78',
//         wikidata: 'Q12820',
//         'text_fr-FR': 'Yvelines',
//         'language_fr-FR': 'fr',
//         text: 'Yvelines',
//         language: 'fr'
//       },
//       {
//         id: 'country.3148',
//         short_code: 'fr',
//         wikidata: 'Q142',
//         'text_fr-FR': 'France',
//         'language_fr-FR': 'fr',
//         text: 'France',
//         language: 'fr'
//       }
//     ]
//   }
// };

type Props = {
  form: WrappedFormUtils;
};

export default function MissionPlaceFieldsets(props: Props) {
  const {form} = props;
  const {getFieldDecorator} = form;
  const {place = null, placePrivacy = 'private'} = form.getFieldsValue(['place', 'placePrivacy']);
  const publicLocationInitialValue = place ? `${place.postalCode} ${place.locality}, ${place.country}` : undefined;
  return (
    <Fieldset legend="Lieux" {...props}>
      <FormItem label="Exact Location">
        {getFieldDecorator('place', {
          rules: [{type: 'object', required: false}]
        })(<PlacePicker />)}
      </FormItem>
      <FormGroup>
        <FormItem label="Privacy" style={{flex: '0 0 150px'}}>
          {getFieldDecorator('placePrivacy', {initialValue: 'private'})(
            <Select>
              <Option value="private">Private</Option>
              <Option value="public">Public</Option>
            </Select>
          )}
        </FormItem>
        <FormItem label="Public Location">
          {getFieldDecorator('placePublicName', {
            initialValue: publicLocationInitialValue
          })(<Input disabled={placePrivacy !== 'private'} />)}
        </FormItem>
      </FormGroup>
      <FormItem label="Consignes">
        {getFieldDecorator('placeDetails', {
          rules: [{required: false}]
        })(
          <TextArea
            placeholder="Par exemple: Rendez-vous au service livraison des marchandises à l'arrière du bâtiment."
            rows={3}
          />
        )}
      </FormItem>
    </Fieldset>
  );
}
