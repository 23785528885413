// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import React, {PureComponent} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Tag, Popconfirm} from 'antd';
import {Button, LinkButton} from '@joonca/library';
// @locals
import TableLayout, {TableHeader, TableContent} from 'src/components/TableLayout';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import Image from 'src/components/content/Image';
import {localeSorterForField /* , dateStringSorterForField */} from '@joonca/library/src/utils/sort';
import {actions as positionsActions} from 'src/store/modules/positions';
import {HOST_API_URL} from 'src/config/env';
// @types
// import type {Dispatch, State as StoreState} from 'src/store/typings';
// import type {ContextRouter} from 'react-router-dom';

// @NOTE avoid destructuring to prevent coverage issues
const ButtonGroup = Button.Group;

type Props = ContextRouter & {
  list: Array<any>;
  actions: {
    fetchPositions: Function;
    deletePositions: Function;
  };
};
type State = {
  searchValue: string;
};
export class WorkPositionList extends PureComponent<Props, State> {
  state = {
    searchValue: ''
  };

  columns = [
    {
      title: 'Picture',
      dataIndex: 'picture',
      render: (value: string) => <Image source={{uri: value}} style={{maxHeight: '32px'}} />
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: localeSorterForField('name')
    },
    {
      title: 'Category',
      dataIndex: 'category',
      defaultSortOrder: 'ascend',
      sorter: localeSorterForField('category'),
      render: (value: string) => <Tag color="blue">{value}</Tag>
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      defaultSortOrder: 'ascend',
      sorter: localeSorterForField('designation'),
      render: (value: string) => <Tag color="blue">{value}</Tag>
    },
    {
      title: 'Actions',
      dataIndex: '_sid',
      key: 'actions',
      width: 126,
      render: (_sid: string) => {
        const {actions, location} = this.props;
        return (
          <ButtonGroup>
            <Button ghost type="primary" icon="search" target="_blank" href={`${HOST_API_URL}/positions/${_sid}`} />
            <LinkButton ghost type="primary" icon="edit" to={`${location.pathname}/edit/${_sid}`} />
            <Popconfirm
              title="Are you sure?"
              placement="topRight"
              onConfirm={() => actions.deletePosition({_sid})}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button ghost type="primary" icon="delete" />
            </Popconfirm>
          </ButtonGroup>
        );
      }
    }
  ];

  componentDidMount() {
    const {actions} = this.props;
    actions.fetchPositions();
  }

  handleSearch = (searchValue: string) => {
    this.setState({searchValue});
  };

  render() {
    const {list, location} = this.props;
    const {searchValue} = this.state;
    return (
      <TableLayout>
        <TableHeader>
          <SearchInput onSearch={this.handleSearch} />
          <LinkButton type="primary" size="large" icon="plus" to={`${location.pathname}/create`}>
            Create Position
          </LinkButton>
        </TableHeader>
        <TableContent>
          <SearchableTable
            columns={this.columns}
            dataSource={list}
            searchValue={searchValue}
            rowKey="code_domaine_professionnel"
          />
        </TableContent>
      </TableLayout>
    );
  }
}

export default connect(
  // mapStateToProps
  (state: StoreState) => ({
    list: state.positions.resource.items
  }),
  // mapDispatchToProps
  (dispatch: Dispatch) => ({
    actions: bindActionCreators(positionsActions, dispatch)
  })
)(WorkPositionList);
