// @docs https://ant.design/components/table/

import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, LinkButton} from '@joonca/library';
import {localeSorterForField} from '@joonca/library/src/utils/sort';
import {Popconfirm, Tag} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {FunctionComponent, useMemo, useState} from 'react';
import dataSource from 'src/assets/competences.json';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';

type Props = {
  localStorageKey?: string;
};

const LaborSkillList: FunctionComponent<Props> = ({localStorageKey}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const columns = useMemo<ColumnsType<Record<string, unknown>>>(
    () => [
      {
        title: 'Code',
        dataIndex: 'code_ogr',
        defaultSortOrder: 'ascend',
        sorter: localeSorterForField('code_ogr'),
        render: (value: string) => <Tag color="blue">{value}</Tag>
      },
      {
        title: 'Label',
        dataIndex: 'libelle_competence',
        sorter: localeSorterForField('libelle_competence')
      },
      {
        title: 'Type',
        dataIndex: 'libelle_type_competence',
        sorter: localeSorterForField('libelle_type_competence')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <LinkButton disabled ghost type="primary" icon={<EditOutlined />} to={`/labor/categories/edit/${_sid}`} />
              <Popconfirm title="Are you sure?" placement="topRight" okText="Delete" cancelText="Cancel">
                <Button disabled ghost type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    []
  );

  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon={<PlusOutlined />} to="/labor/categories/create">
          Add Category
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={dataSource}
          searchValue={searchValue}
          rowKey="code_ogr"
          localStorageKey={localStorageKey || 'LaborSkillList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default LaborSkillList;
