/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import {ColumnType} from 'antd/lib/table';
import React, {FunctionComponent, ReactNode} from 'react';
import {Document} from '@joonca/typings';

type Props = {
  value: boolean;
};

export const LockedIcon: FunctionComponent<Props> = ({value}) => {
  return value ? (
    <LockOutlined style={{color: presetPalettes['grey'][4]}} />
  ) : (
    <UnlockOutlined style={{color: presetPalettes['green'][4]}} />
  );
};

export const isClosedColumn = <T extends Document & {isClosed: boolean}>(): ColumnType<T> => ({
  title: 'Verrou',
  dataIndex: 'isClosed',
  filters: [
    {value: false, text: 'Ouvert'},
    {value: true, text: 'Fermé'}
  ],
  onFilter: (value, record): boolean => record.isClosed === value,
  // sorter: numericSorterForField('isClosed'),
  width: 96,
  render: (value): ReactNode => <LockedIcon value={value} />
});

export default isClosedColumn;
