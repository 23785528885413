// @flow

import React, {PureComponent} from 'react';
import {Input, Select, Button, Spin, Radio, message} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// @locals
import {USE_FIXTURES} from 'src/config/env';
import {Form, FormItem, FormComponentProps, FormGroup} from 'src/components/form';
import FileInput from 'src/components/form/input/FileInput';
import AddressFieldset from 'src/components/form/fieldset/AddressFieldset';
import OrganizationLegalFieldset from 'src/components/OrganizationLegalFieldset';
import {tailFormItemLayout} from '@joonca/library/src/utils/layout';
import {actions as organizationsActions} from 'src/store/modules/organizations';
import {RouteChildrenProps} from 'react-router';
import PhoneNumberInput from 'src/components/form/input/PhoneNumberInput';
// @typings
// import type {FormProps, ValidateCallback} from 'antd';
// import type {ContextRouter} from 'react-router-dom';

const Option = Select.Option; // eslint-disable-line
const RadioButton = Radio.Button; // eslint-disable-line
const RadioGroup = Radio.Group; // eslint-disable-line

export const fixture = {
  name: 'Magenta',
  contactEmail: 'olivier@mgcrea.io',
  supportEmail: 'contact@mgcrea.io',
  legal: {
    name: 'MAGENTA CREATIONS',
    form: 'SARL',
    number: '51255026000016',
    sector: '6201Z'
  },
  address: {
    firstLine: '8 Rue des Anciennes Granges',
    postalCode: '78860',
    locality: 'Saint Nom La Bretèche',
    country: 'FR'
  }
};

type Props = RouteChildrenProps & FormComponentProps;

type State = {
  isEditing: boolean;
  isPreloading: boolean;
  isLoading: boolean;
};

export class OrganizationForm extends PureComponent<Props, State> {
  state = {
    isEditing: false,
    isPreloading: true,
    isLoading: false
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const {match, isCreating, isUpdating, isFetching} = props;
    const isEditing = !!match.params.id;
    return {
      isEditing,
      isPreloading: isEditing && isFetching,
      isLoading: isEditing ? isUpdating : isCreating
    };
  }

  componentDidMount() {
    const {isEditing} = this.state;
    const {actions, form} = this.props;
    const {params} = this.props.match;
    if (isEditing) {
      console.error('resetFields');
      form.resetFields();
      actions.getOrganization(params.id, {query: {foo: 'bar'}}).then(({body}) => {
        form.setFieldsValue(body);
      });
    } else if (USE_FIXTURES) {
      form.setFieldsValue(fixture);
    } else {
      // @NOTE needed?
      // form.resetFields();
    }
  }

  handleCancel = (ev: SyntheticEvent<any>) => {
    const {history} = this.props;
    ev.preventDefault();
    history.push('/organizations');
  };

  handleSubmit = (ev: SyntheticEvent<any>) => {
    const {form} = this.props;
    const {isEditing} = this.state;
    ev.preventDefault();
    form.validateFields((err, values: {[s: string]: any}) => {
      if (err) {
        return;
      }
      console.warn('validateFields', {values});
      if (isEditing) {
        this.performUpdateAction(values);
      } else {
        this.performCreateAction(values);
      }
    });
  };

  performUpdateAction(payload: {[s: string]: any}) {
    const {actions} = this.props;
    const {params} = this.props.match;
    actions
      .updateOrganization({...payload, _sid: params.id})
      .then(() => {
        message.success(`Sucessfully updated user!`);
      })
      .catch((err) => {
        const text = err.body ? err.body.error : '';
        message.error(`Update failed${text ? ` : ${text}` : ''}`);
      });
  }

  performCreateAction(payload: {[s: string]: any}) {
    const {actions, history} = this.props;
    actions
      .createOrganization(payload)
      .then(() => {
        message.success(`Sucessfully created user!`);
        history.push('/organizations');
      })
      .catch((err) => {
        const text = err.body ? err.body.error : '';
        message.error(`Create failed${text ? ` : ${text}` : ''}`);
      });
  }

  render() {
    console.count(`${this.constructor.name}.render()`);
    // console.warn(`${this.constructor.name}.render():props`, this.props);

    const {isPreloading, isLoading, isEditing} = this.state;
    const {getFieldDecorator} = this.props.form;
    const {params} = this.props.match;

    return (
      <Spin spinning={isPreloading} delay={100}>
        <Form onSubmit={this.handleSubmit} key={params.id || 'create'}>
          <FormItem label="Name">
            {getFieldDecorator('name', {
              rules: [{required: true}]
            })(<Input />)}
          </FormItem>
          <FormItem label="Contact Email">
            {getFieldDecorator('contactEmail', {
              rules: [{type: 'email'}, {required: true}]
            })(<Input />)}
          </FormItem>
          <FormGroup>
            <FormItem label="Support Email">
              {getFieldDecorator('supportEmail', {
                rules: [{type: 'email'}, {required: false}]
              })(<Input />)}
            </FormItem>
            <FormItem label="Support PhoneNumber">
              {getFieldDecorator('supportPhoneNumber', {
                rules: [{required: false}]
              })(<PhoneNumberInput />)}
            </FormItem>
          </FormGroup>
          <FormItem label="Logo">
            {getFieldDecorator('avatar', {
              // valuePropName: 'fileList',
              getValueFromEvent: ({file}) => {
                console.warn('getValueFromEvent', file.status, file.response);
                return file && file.status === 'done'
                  ? `${file.response.uri}?t=${+new Date(file.response.createdAt)}`
                  : null;
              },
              rules: [{required: false}]
            })(<FileInput name="avatar" level="protected" />)}
          </FormItem>
          <OrganizationLegalFieldset fieldPrefix="legal" form={this.props.form} />
          <AddressFieldset fieldPrefix="address" form={this.props.form} />

          <FormItem {...tailFormItemLayout}>
            <Button htmlType="button" onClick={this.handleCancel}>
              Cancel
            </Button>
            &nbsp;
            <Button type="primary" htmlType="submit" disabled={isPreloading} loading={isLoading}>
              {isEditing ? 'Update' : 'Create'}
            </Button>
          </FormItem>
        </Form>
      </Spin>
    );
  }
}

export default connect(
  // mapStateToProps
  (state) => ({
    isCreating: state.organizations.resource.isCreating,
    isUpdating: state.organizations.resource.isUpdating,
    isFetching: state.organizations.resource.isFetchingItem
  }),
  // mapDispatchToProps
  (dispatch: Function) => ({
    actions: bindActionCreators(organizationsActions, dispatch)
  })
)(Form.create()(OrganizationForm));
