import styled from 'styled-components';
import {Form} from 'antd';
export {FormComponentProps} from 'antd/lib/form';

// export default styled(Form)`
//   &.ant-form {
//     /* margin: 0 12.5%; */
//     margin: 0 6px;
//     max-width: 650px;
//   }
// `;

Form.create = () => (id) => id;

export default Form;
