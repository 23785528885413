import {isInteger} from 'lodash';
import React, {FunctionComponent, HTMLProps} from 'react';

const DIGITS = [
  'aucun',
  'un',
  'deux',
  'trois',
  'quatre',
  'cinq',
  'six',
  'sept',
  'huit',
  'neuf',
  'dix',
  'onze',
  'douze',
  'treize',
  'quatorze',
  'quinze',
  'seize'
];

const FEMINIME_SUFFIXES = [
  'ade',
  'aille',
  'ance',
  'ée',
  'elle',
  'emme',
  'ence',
  'ère',
  'esse',
  'ette',
  'euse',
  'gue',
  'ie',
  'ienne',
  'ine',
  'ion',
  'ise',
  'onne',
  'té',
  'tié',
  'trice',
  'ure',
  'ute'
];

const FEMININE_REGEX = new RegExp(`(${FEMINIME_SUFFIXES.join('|')})$`, 'i');

export type NumeralProps = HTMLProps<HTMLSpanElement> & {
  value: number;
  label?: string;
  plural?: string;
  withoutValue?: boolean;
  withoutLabel?: boolean;
  asNumber?: boolean;
  precision?: number;
};

export const Numeral: FunctionComponent<NumeralProps> = ({
  value,
  label = '',
  plural = `${label}s`,
  withoutValue,
  withoutLabel,
  asNumber,
  precision = 0,
  ...otherProps
}) => {
  let valueAsText = (value || 0).toFixed(precision);

  if (!asNumber) {
    if (value === 0 || value === 1) {
      valueAsText = label.match(FEMININE_REGEX) ? `${DIGITS[value]}e` : DIGITS[value];
    } else {
      valueAsText = isInteger(value) && value < DIGITS.length ? DIGITS[value] : valueAsText;
    }
  }

  const labelText = value !== 1 ? plural : label;
  return (
    <span {...otherProps}>
      {[!withoutValue ? valueAsText : '', !withoutLabel ? labelText : ''].filter(Boolean).join(' ')}
    </span>
  );
};
