import styled from 'styled-components';

export const TableHeader = styled('div')`
  min-height: 64px;
  display: flex;
  /* flex: 0; */
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  /* & .ant-input-search {
    max-width: 640px;
  } */
  > button.ant-btn {
    margin-left: 8px;
  }
`;
export const TableContent = styled('div')`
  /* display: flex;
  flex-direction: column;
  flex: 1; */
`;
export const TableFooter = styled('div')``;

const TableLayout = styled('div')`
  /* display: flex;
  flex-direction: column;
  flex: 1; */
`;
TableLayout.Header = TableHeader;
TableLayout.Content = TableContent;
TableLayout.Footer = TableFooter;

export default TableLayout;
