// Application name
export const PACKAGE_NAME: string = global.__PACKAGE_NAME__ || '';
export const PACKAGE_VERSION: string = global.__PACKAGE_VERSION__ || '';
export const GIT_COMMIT_HASH: string = global.__GIT_COMMIT_HASH__ || '';
export const GIT_COMMIT_DATE: string = global.__GIT_COMMIT_DATE__ || '';
export const RELEASE_NAME = `${PACKAGE_VERSION}.${GIT_COMMIT_HASH}`;

// Node environment
export const NODE_ENV: string = process.env.NODE_ENV || 'development';
export const IS_DEV: boolean = NODE_ENV === 'development';
export const IS_PROD: boolean = NODE_ENV === 'production';
export const IS_TEST: boolean = NODE_ENV === 'test';

Object.assign(window, {
  __VERSION__: {
    name: PACKAGE_NAME,
    version: PACKAGE_VERSION,
    commit: {
      hash: GIT_COMMIT_HASH,
      date: GIT_COMMIT_DATE
    }
  },
  __ENV__: NODE_ENV
});

// Hostname environment
const {hostname: CURRENT_HOSTNAME} = window.location;
const HOSTNAME = process.env.REACT_APP_HOSTNAME_OVERRIDE || CURRENT_HOSTNAME;
const IS_LOCALHOST = HOSTNAME === 'localhost';
const DOMAIN = HOSTNAME.replace(/(admin|app)\./, '');
export const HOST_API_URL: string = IS_LOCALHOST ? '/api' : `https://api.${DOMAIN}`;
export const HOST_AUTH_URL: string = IS_LOCALHOST ? '/auth' : `https://auth.${DOMAIN}`;
export const HOST_STORAGE_URL: string = IS_LOCALHOST ? '/storage' : `https://storage.${DOMAIN}`;
export const HOSTNAME_ENDPOINTS = {
  api: HOST_API_URL,
  auth: HOST_AUTH_URL,
  storage: HOST_STORAGE_URL
};

// Development

export const USERNAME: string = process.env.REACT_APP_USERNAME || '';
export const PASSWORD: string = process.env.REACT_APP_PASSWORD || '';
export const PUBLIC_URL: string = process.env.PUBLIC_URL || '';

export const USE_FIXTURES: boolean = IS_DEV && !!process.env.REACT_APP_USE_FIXTURES;
export const MAPBOX_API_ACCESS_TOKEN: string = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || '';

export const SMIC_VALUE = process.env.REACT_APP_SMIC_VALUE
  ? parseInt(process.env.REACT_APP_SMIC_VALUE, 10)
  : 10.15; // 2020
export const SMIC_VALUE_MAP = {
  hourly: SMIC_VALUE,
  daily: SMIC_VALUE * 7,
  monthly: parseFloat((SMIC_VALUE * 7 * (21 + 2 / 3)).toFixed(2)),
  yearly: SMIC_VALUE * 7 * 260
};

if (__DEV__) {
  Object.assign(global, {
    log: console.log.bind(console),
    jlog: (value: unknown) => console.log(JSON.stringify(value, null, 2))
  });
} else {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  Object.assign(window, {log: () => {}, jlog: () => {}});
}
