import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExperimentOutlined
} from '@ant-design/icons';
import {Mission, MissionStatus} from '@joonca/typings';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import React, {FunctionComponent, ReactNode} from 'react';
import {Tag, TagProps} from 'src/antd';
import {StatusTag} from './StatusTag';

export const MISSION_STATUS_LABELS: {[s in MissionStatus]: string} = {
  draft: 'equissée',
  published: 'publiée',
  scheduled: 'programmée',
  cancelled: 'annulée',
  aborted: 'avortée',
  terminated: 'clôturée',
  ended: 'finalisée'
};
export const MISSION_STATUS_CAPTIONS: {[s in MissionStatus]: string} = {
  draft: "Le projet de mission n'a pas encore été publié",
  published: 'Le projet de mission est visible et tous les candidats peuvent postuler',
  scheduled: 'La mission aura bien lieu, les candidats acceptés ont été convoqués',
  cancelled: 'La mission a été annulée',
  aborted: 'La mission a été avortée',
  terminated: 'La mission a été clôturée automatiquement par Joonca',
  ended: "La mission s'est déroulée correctement"
};
export const MISSION_STATUS_COLORS: {[s in MissionStatus]: TagProps['color']} = {
  draft: 'cyan',
  published: 'blue',
  scheduled: 'blue',
  cancelled: 'volcano',
  aborted: 'volcano',
  terminated: 'purple',
  ended: 'success'
};
export const MISSION_STATUS_ICONS: {[s in MissionStatus]: ReactNode} = {
  draft: <ExperimentOutlined />,
  published: <CheckCircleOutlined />,
  scheduled: <ClockCircleOutlined />,
  cancelled: <CloseCircleOutlined />,
  aborted: <CloseCircleOutlined />,
  terminated: <CloseCircleOutlined />,
  ended: <CheckCircleOutlined />
};

export type MissionStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Mission['status']>;
  date?: Date | string | number;
};

export const MissionStatusTag: FunctionComponent<MissionStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip title={MISSION_STATUS_CAPTIONS[value]} placement={placement} overlayStyle={{maxWidth: 200}}>
      <Tag icon={MISSION_STATUS_ICONS[value]} color={MISSION_STATUS_COLORS[value]} {...otherProps}>
        {MISSION_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);
