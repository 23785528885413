import {presetPalettes, generate} from '@ant-design/colors';
import qontoColors from './qonto.colors';
import antdVars from './antd.vars';
import type {PresetColorType as AntDesignPresetColorType} from 'antd/lib/_util/colors';

presetPalettes.primary = generate(antdVars['primary-color']);
window.presetPalettes = presetPalettes;
export {presetPalettes};

export type PresetColorType = AntDesignPresetColorType & {};

export const gradientColors = [presetPalettes.geekblue[5], presetPalettes.geekblue[4]];

export const colors = {
  ...qontoColors,
  primary: antdVars['primary-color'], // '#128df8'
  secondary: '#5e87d9',
  third: '#baa0f2',
  neutral: '#b7cada',
  dark: '#333434',
  light: '#f9fafb',
  lightest: '#ffffff',
  alert: '#f13737',
  transparent: 'transparent',
  success: 'limegreen'
};

export type Theme =
  | 'blue'
  | 'green'
  | 'purple'
  | 'red'
  | 'turquoise'
  | 'yellow'
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger';

export type Contrast = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

// export declare const PresetStatusColorTypes: ["success", "processing", "error", "default", "warning"];
// export declare const PresetColorTypes: ["pink", "red", "yellow", "orange", "cyan", "green", "blue", "purple", "geekblue", "magenta", "volcano", "gold", "lime"];
// export declare type PresetColorType = ElementOf<typeof PresetColorTypes>;
// export declare type PresetStatusColorType = ElementOf<typeof PresetStatusColorTypes>;
