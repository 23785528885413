// @source https://github.com/ant-design/ant-design/tree/master/components/radio

import {Timeline as AntDesignTimeline} from 'antd';
import {TimelineProps as AntDesignTimelineProps} from 'antd/lib/timeline';
import React, {FunctionComponent} from 'react';

export type TimelineProps = AntDesignTimelineProps;

export const Timeline: FunctionComponent<TimelineProps> = ({...otherProps}) => {
  return <AntDesignTimeline {...otherProps} />;
};
