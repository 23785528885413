import {presetPalettes} from '@ant-design/colors';

export {presetPalettes};

export const gradientColors = [presetPalettes.geekblue[5], presetPalettes.geekblue[4]];

export const colors = {
  primary: '#128df8',
  secondary: '#5e87d9',
  third: '#baa0f2',
  neutral: '#b7cada',
  dark: '#333434',
  light: '#f9fafb',
  lightest: '#ffffff',
  alert: '#f13737',
  transparent: 'transparent',
  success: 'limegreen'
};
