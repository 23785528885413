import countries from 'src/assets/countries.json';

export const GENDER_LABELS = {
  male: 'Homme',
  female: 'Femme'
};

export const LANGUAGE_LEVEL_LABELS = {
  A1: 'Débutant',
  A2: 'Elémentaire',
  B1: 'Intermédiaire',
  C1: 'Avancé',
  C2: 'Courant'
};

export const LEGAL_STATUS_LABELS = {
  none: 'Non inscrit',
  pending: 'En cours',
  done: 'Inscrit'
};

export const LEGAL_FORM_LABELS = {
  AE: 'Auto-entrepreneur'
};

export const NATIONALITY_LABELS: Array<{[s: string]: string}> = countries.reduce((soFar, country) => {
  soFar[country.code.toLowerCase()] = country.nationality; // eslint-disable-line
  return soFar;
}, {});

// Hotfix
NATIONALITY_LABELS.en = 'Anglaise';
