// @docs https://ant.design/components/upload/
// @source https://github.com/ant-design/ant-design/blob/master/components/upload/Upload.tsx

import React, {PureComponent} from 'react';
import {message, Upload, Icon} from 'antd';
import styled from 'styled-components';
import {HOST_STORAGE_URL} from 'src/config/env';

export const Dragger = styled(Upload.Dragger)`
  background-color: red;
  & .ant-upload {
    /* max-width: 320px; */
    display: ${(props) => (props.disabled ? 'none' : 'block')};
  }
`;

type Props = {
  key: string;
  level: string;
  multiple: boolean;
};
type State = {
  fileList: Array<any>;
};
export default class FileInput extends PureComponent<Props, State> {
  state = {
    fileList: []
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const {value} = props;
    const {fileList} = state;
    // Hacky way to handle initial value
    if (value && !fileList.length) {
      return {
        pristine: false,
        fileList: [
          {
            uid: value,
            name: value.split('/').pop(),
            status: 'done',
            url: `${HOST_STORAGE_URL}/files/contents/${value}`
          }
        ]
      };
    }
    return state;
  }

  get isControlled() {
    return 'fileList' in this.props;
  }

  handleChange = ({file, fileList}) => {
    const {onChange} = this.props;
    if (!this.isControlled) {
      this.setState({fileList: fileList.slice()});
    }
    if (onChange) {
      onChange({file});
    }
    if (file.status === 'done') {
      message.success(`${file.name} file uploaded successfully.`);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
  };

  handleBeforeUpload = (file) => {
    const {onChange} = this.props;
    // console.warn('handleBeforeUpload', {file});
    if (!this.isControlled) {
      this.setState(({fileList}) => ({
        // fileList: [...fileList, file]
        fileList: [file]
      }));
    }
    if (onChange) {
      onChange({file});
    }
    return true;
  };

  handleRemove = (file) => {
    const {onChange} = this.props;
    if (!this.isControlled) {
      this.setState(({fileList}) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList
        };
      });
    }
    if (onChange) {
      onChange({file});
    }
  };

  render() {
    const {name, level} = this.props;
    const {fileList} = this.state;
    const disabled = fileList.length >= 1;

    const props = {
      accept: 'image/jpeg',
      name: 'file',
      multiple: false,
      listType: 'picture',
      action: `${HOST_STORAGE_URL}/files/upload?key=${name}.jpg&level=${level}`,
      showUploadList: {showPreviewIcon: true, showRemoveIcon: true},
      // action: 'https://jsonplaceholder.typicode.com/posts/',
      // action: 'https://request.mgcrea.io',
      withCredentials: true
    };

    return (
      <Dragger
        {...props}
        fileList={fileList}
        // defaultFileList={defaultFileList}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        beforeUpload={this.handleBeforeUpload}
        disabled={false}
      >
        <p className="ant-upload-drag-icon">
          <Icon type="cloud" />
        </p>
        <p className="ant-upload-hint">Click or drag file to this area to upload</p>
      </Dragger>
    );
  }
}

/*
  lastModified: 1372070469000
  lastModifiedDate: Mon Jun 24 2013 12:41:09 GMT+0200 (Central European Summer Time) {}
  name: "iphone7.jpg"
  originFileObj: File(127158) {uid: "rc-upload-1541686702055-2", name: "iphone7.jpg", lastModified: 1372070469000, lastModifiedDate: Mon Jun 24 2013 12:41:09 GMT+0200 (Central European Summer Time), webkitRelativePath: "", …}
  percent: 100
  response: {fileName: "profile2.jpg", originalFileName: "iphone7.jpg", mimeType: "image/jpeg", size: "127158", uri: "private/5be2cc92b850c80533ed8cd9/profile2.jpg", …}
  size: 127158
  status: "done"
  thumbUrl: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD"
  type: "image/jpeg"
  uid: "rc-upload-1541686702055-2"
*/
