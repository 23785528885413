export const TRANSFER_LOCALE = {
  itemUnit: 'objet',
  itemsUnit: 'objets',
  notFoundContent: 'Cette liste est vide',
  searchPlaceholder: 'Rechercher'
};

export const TABLE_LOCALE = {
  filterConfirm: 'Ok',
  filterReset: 'Reset',
  emptyText: 'Aucune donnée'
};
