import {Button, Result} from 'antd';
import React, {FunctionComponent, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

type Props = {};

const LogoutPage: FunctionComponent<Props> = () => {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.replace('/signin');
  }, [history]);
  return (
    <StyledResult
      style={{color: 'white'}}
      status="403"
      title="You have been securely logged out."
      subTitle={'For security reasons, your Joonca sessions end after 30 minutes.'}
      extra={
        <Button type="primary" onClick={onClick}>
          Go back to login screen
        </Button>
      }
    />
  );
};

const StyledResult = styled(Result)`
  .ant-result-title {
    color: white;
  }
  .ant-result-subtitle {
    color: white;
  }
`;

export default LogoutPage;
