import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input} from '@joonca/library';
import {Checkbox, message} from 'antd';
import {Callbacks} from 'rc-field-form/lib/interface';
import React, {FunctionComponent} from 'react';
import {RouteChildrenProps} from 'react-router-dom';
import {PASSWORD, USERNAME} from 'src/config/env';
import {actions as authActions} from 'src/store/modules/auth';
import {sm} from '@joonca/library/src/utils/breakpoints';
import {useActions} from '@joonca/library/src/utils/redux';
import styled from 'styled-components';

type Props = RouteChildrenProps & {};

type Values = {
  username: string;
  password: string;
};

const LoginForm: FunctionComponent<Props> = ({history}) => {
  const [form] = Form.useForm();
  const {loginWithForm} = useActions(authActions, []);

  const onFinish: Callbacks['onFinish'] = (values: Values) => {
    console.log('Success:', values);

    const {username, password} = values;
    loginWithForm(username, password)
      .then(() => {
        history.push('/users');
      })
      .catch((err) => {
        console.warn('err', err);
        const bodyError = err.body ? err.body.error : '';
        message.error(`Login failed${bodyError ? ` : ${bodyError}` : ''}`);
      });
  };

  const onFinishFailed: Callbacks['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please fill out the form');
  };

  return (
    <StyledForm
      form={form}
      size="large"
      name="basic"
      initialValues={{username: USERNAME, password: PASSWORD, remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item name="username" rules={[{required: true, message: 'Please input your username!'}]}>
        <Input size="large" placeholder="Email" prefix={<UserOutlined />} />
      </Form.Item>
      <Form.Item name="password" rules={[{required: true, message: 'Please input your password!'}]}>
        <Input.Password size="large" placeholder="Password" prefix={<LockOutlined />} />
      </Form.Item>

      <div className="ant-row" style={{marginBottom: '8px'}}>
        <Form.Item name="remember" valuePropName="checked" style={{flex: 1}}>
          <Checkbox style={{fontSize: 'small'}} name="remember">
            Remember me
          </Checkbox>
        </Form.Item>
        <a href="mailto:contact@joonca.com" style={{fontSize: 'small'}}>
          Forgot password
        </a>
      </div>

      <Form.Item style={{textAlign: 'center'}}>
        <Button block type="primary" htmlType="submit">
          Log in
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default LoginForm;

const StyledForm = styled(Form)`
  &.ant-form {
    background: white;
    padding: 2rem;
    box-shadow: 2px 3px 9px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 0 auto;
    width: 368px;
    @media screen and (max-width: ${sm}px) {
      width: 95%;
    }
  }
`;
