import React from 'react';
import {Route, Switch} from 'react-router-dom';

import AppLayout from 'src/layouts/AppLayout';
import LaborDomainList from 'src/views/labor/LaborDomainList';
import LaborCategoryList from 'src/views/labor/LaborCategoryList';
import LaborProfessionList from 'src/views/labor/LaborProfessionList';
import LaborDesignationList from 'src/views/labor/LaborDesignationList';
import LaborSkillList from 'src/views/labor/LaborSkillList';
import NotFoundPage from 'src/views/errors/NotFoundPage';
import PrivateRoute from '../helpers/PrivateRoute';

const LaborRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/labor/domains" component={LaborDomainList} layout={AppLayout} />
    <PrivateRoute exact path="/labor/categories" component={LaborCategoryList} layout={AppLayout} />
    <PrivateRoute exact path="/labor/professions" component={LaborProfessionList} layout={AppLayout} />
    <PrivateRoute exact path="/labor/designations" component={LaborDesignationList} layout={AppLayout} />
    <PrivateRoute exact path="/labor/skills" component={LaborSkillList} layout={AppLayout} />
    <Route component={NotFoundPage} />
  </Switch>
);

export const breadcrumbs = {
  '/labor/domains': {label: 'Domains', icon: 'audit'},
  '/labor/categories': {label: 'Categories', icon: 'audit'},
  '/labor/professions': {label: 'Professions', icon: 'audit'},
  '/labor/designations': {label: 'Designations', icon: 'audit'},
  '/labor/skills': {label: 'Skills', icon: 'audit'}
};

LaborRoutes.breadcrumbs = breadcrumbs;

export default LaborRoutes;
