import React, {FunctionComponent} from 'react';

type Props = {};

const WorkerQualityForm: FunctionComponent<Props> = () => {
  return <div>WorkerQualityForm</div>;
};

export default WorkerQualityForm;

// import React, {PureComponent} from 'react';
// import {AutoComplete, Input, Select, Button, Spin, Radio, message} from 'antd';
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// // @locals
// import {IS_DEV} from 'src/config/env';
// import {Form, FormItem, Fieldset, FormFooter} from 'src/components/form';
// import {actions as qualitiesActions} from 'src/store/modules/qualities';
// // import CategoryInput from 'src/components/form/input/CategoryInput';
// // import DesignationSelect from 'src/components/form/select/DesignationSelect';
// // import FileInput from 'src/components/form/input/FileInput';
// import QUALITIES from 'src/assets/qualities';

// // @typings
// // import type {FormProps, ValidateCallback} from 'antd';
// // import type {ContextRouter} from 'react-router-dom';

// const TextArea = Input.TextArea; // eslint-disable-line prefer-destructuring
// const Option = Select.Option; // eslint-disable-line
// const RadioButton = Radio.Button; // eslint-disable-line
// const RadioGroup = Radio.Group; // eslint-disable-line

// const qualitiesDataSource = QUALITIES.map(({name: text}) => text);

// export const fixture = {
//   name: 'Aimable'
// };

// type Props = {
//   actions: any,
//   isCreating: boolean,
//   isUpdating: boolean,
//   isFetching: boolean
// };

// type State = {
//   isEditing: boolean,
//   isPreloading: boolean,
//   isLoading: boolean
// };

// export class WorkerQualityForm extends PureComponent<Props & FormProps & ContextRouter, State> {
//   state = {
//     isEditing: false,
//     isPreloading: true,
//     isLoading: false
//   };

//   static getDerivedStateFromProps(props: Props, state: State) {
//     const {match, isCreating, isUpdating, isFetching} = props;
//     const isEditing = !!match.params.id;
//     return {
//       ...state,
//       isEditing,
//       isPreloading: isEditing && isFetching,
//       isLoading: isEditing ? isUpdating : isCreating
//     };
//   }

//   componentDidMount() {
//     const {isEditing} = this.state;
//     const {actions, form} = this.props;
//     const {params} = this.props.match;
//     if (isEditing) {
//       actions.getQuality(params.id, {query: {}}).then(({body}) => {
//         form.setFieldsValue(body);
//       });
//     } else if (IS_DEV) {
//       form.setFieldsValue(fixture);
//     }
//   }

//   handleCancel = (ev: SyntheticEvent<any>) => {
//     const {history, location} = this.props;
//     const {isEditing} = this.state;
//     ev.preventDefault();
//     const target = location.pathname
//       .split('/')
//       .slice(0, isEditing ? -2 : -1)
//       .join('/');
//     history.push(target);
//   };

//   handleSubmit = (ev: SyntheticEvent<any>) => {
//     const {form} = this.props;
//     const {isEditing} = this.state;
//     ev.preventDefault();
//     form.validateFields((err, values: {[s: string]: any}) => {
//       if (err) {
//         return;
//       }
//       console.warn('validateFields', {values});
//       if (isEditing) {
//         this.performUpdateAction(values);
//       } else {
//         this.performCreateAction(values);
//       }
//     });
//   };

//   performUpdateAction(payload: {[s: string]: any}) {
//     const {actions} = this.props;
//     const {params} = this.props.match;
//     actions
//       .updateQuality({...payload, _sid: params.id})
//       .then(() => {
//         message.success(`Sucessfully updated position!`);
//       })
//       .catch(err => {
//         const text = err.body ? err.body.error : '';
//         message.error(`Update failed${text ? ` : ${text}` : ''}`);
//       });
//   }

//   performCreateAction(payload: {[s: string]: any}) {
//     const {actions, history} = this.props;
//     actions
//       .createQuality(payload)
//       .then(() => {
//         message.success(`Sucessfully created position!`);
//         history.push('/qualities');
//       })
//       .catch(err => {
//         const text = err.body ? err.body.error : '';
//         message.error(`Create failed${text ? ` : ${text}` : ''}`);
//       });
//   }

//   render() {
//     console.count(`${this.constructor.name}.render()`);
//     // console.warn(`${this.constructor.name}.render():props`, this.props);

//     const {isPreloading, isLoading, isEditing} = this.state;
//     const {params} = this.props.match;
//     const {form} = this.props;
//     const {getFieldDecorator} = form;
//     const {category = []} = form.getFieldsValue(['category']);

//     return (
//       <Spin spinning={isPreloading} delay={100}>
//         <Form onSubmit={this.handleSubmit} key={params.id || 'create'}>
//           <Fieldset legend="Quality">
//             <FormItem label="Name">
//               {getFieldDecorator('name', {
//                 rules: [{required: true}]
//               })(<AutoComplete dataSource={qualitiesDataSource} />)}
//             </FormItem>
//             <FormItem label="Description">
//               {getFieldDecorator('description', {
//                 rules: [{required: false}]
//               })(<TextArea rows={3} />)}
//             </FormItem>
//             {/* <FormItem label="Picture">
//               {getFieldDecorator('picture', {
//                 // valuePropName: 'fileList',
//                 getValueFromEvent: ({file}) => {
//                   console.warn('getValueFromEvent', file.status, file.response);
//                   return file && file.status === 'done'
//                     ? `${file.response.uri}?t=${+new Date(file.response.createdAt)}`
//                     : null;
//                 },
//                 rules: [{required: false}]
//               })(<FileInput name="positions/:_sid/picture" level="public" />)}
//             </FormItem> */}
//           </Fieldset>

//           <FormFooter>
//             <Button htmlType="button" onClick={this.handleCancel}>
//               Cancel
//             </Button>
//             &nbsp;
//             <Button type="primary" htmlType="submit" disabled={isPreloading} loading={isLoading}>
//               {isEditing ? 'Update' : 'Create'}
//             </Button>
//           </FormFooter>
//         </Form>
//       </Spin>
//     );
//   }
// }

// export default connect(
//   // mapStateToProps
//   state => ({
//     isCreating: state.qualities.resource.isCreating,
//     isUpdating: state.qualities.resource.isUpdating,
//     isFetching: state.qualities.resource.isFetchingItem
//   }),
//   // mapDispatchToProps
//   (dispatch: Function) => ({
//     actions: bindActionCreators(qualitiesActions, dispatch)
//   })
// )(Form.create()(WorkerQualityForm));
