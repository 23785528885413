/* eslint-disable @typescript-eslint/no-explicit-any */
import {Button, ButtonProps} from '@joonca/library/src/antd';
import type {LocationState} from 'history';
import React, {ReactElement, useCallback} from 'react';
import {LinkProps, useHistory} from 'react-router-dom';

export type LinkButtonProps<S = LocationState> = ButtonProps &
  Partial<Pick<LinkProps<S>, 'to'>> & {
    search?: Record<string, unknown>;
  };

export const LinkButton = <S,>({
  onClick,
  to = '',
  search = {},
  ...otherProps
}: LinkButtonProps<S>): ReactElement<any, any> | null => {
  const history = useHistory();
  const doClick = useCallback<NonNullable<LinkButtonProps['onClick']>>(
    (ev) => {
      if (onClick) {
        onClick(ev);
      }
      const searchAsString: string = Object.keys(search)
        .reduce<string[]>((soFar, key) => {
          return soFar.concat(`${key}=${encodeURIComponent(JSON.stringify(search[key]))}`);
        }, [])
        .join('&');

      if (!ev.defaultPrevented) {
        history.push(searchAsString ? `${to}?${searchAsString}` : `${to}`);
      }
    },
    [onClick, history, to, search]
  );
  return <Button onClick={doClick} {...otherProps} />;
};
