/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo} from 'react';
import {bindActionCreators} from 'redux';
import {AsyncActionCreatorsMapObject, UnwrapAsyncActionCreatorsMapObject} from 'redux-rest-resource';
import {useDispatch, useSelector} from 'react-redux';

function useActions<A, M extends AsyncActionCreatorsMapObject<A>>(
  actions: M,
  deps?: unknown[]
): UnwrapAsyncActionCreatorsMapObject<M>;
function useActions<A, M extends AsyncActionCreatorsMapObject<A>>(actions: M, deps?: unknown[]): M {
  const dispatch = useDispatch();
  return useMemo<M>(() => bindActionCreators(actions, dispatch), deps ? [dispatch, ...deps] : [dispatch]);
}

export {useActions, useSelector, useDispatch};

export default useActions;

// type ReturnType<T extends (...args: any) => any> = T extends (...args: any) => infer R ? R : any;
// export declare type AsyncActionCreator<T = unknown> = (context?: Context, contextOpts?: ContextOptions) => ThunkAction<Promise<Action<T>>, State, void, Action<T>>;

/*

export type AsyncActionCreator<T = unknown> = (
  context?: Context,
  contextOpts?: ContextOptions
) => ThunkAction<Promise<Action<T>>, State, void, Action<T>>;

export type AsyncActionCreatorsMapObject<T = unknown> = Record<string, AsyncActionCreator<T>>;

export type UnwrapAsyncActionCreatorsMapObject<
  T extends Record<string, AsyncActionCreator>
> = T extends AsyncActionCreatorsMapObject<infer A>
  ? Record<string, (context?: Context, contextOpts?: ContextOptions) => Promise<Action<A>>>
  : T;

*/
