// @source https://github.com/ant-design/ant-design-pro/blob/master/src/components/SelectLang/index.js

import React, {PureComponent} from 'react';
// import {formatMessage, setLocale, getLocale} from 'umi/locale';
import {Menu, Icon, Dropdown} from 'antd';
// import styled from 'styled-components';

const MenuItem = Menu.Item;

export default class SelectLang extends PureComponent {
  handleMenuClick = ({key}) => {
    // setLocale(key);
  };

  render() {
    // const selectedLang = getLocale();
    const langMenu = (
      <Menu
        selectedKeys={
          [
            /* selectedLang */
          ]
        }
        onClick={this.handleMenuClick}
      >
        <MenuItem key="fr-FR">
          <span role="img" aria-label="Français">
            🇫🇷
          </span>{' '}
          Français
        </MenuItem>
        <MenuItem key="en-US">
          <span role="img" aria-label="English">
            🇬🇧
          </span>{' '}
          English
        </MenuItem>
      </Menu>
    );
    return (
      <Dropdown overlay={langMenu} placement="bottomRight">
        <Icon type="global" title="navBar.lang" style={{marginLeft: 12}} />
      </Dropdown>
    );
  }
}
