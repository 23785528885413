// @source https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

/*
// Media queries breakpoints
// Extra small screen / phone
@screen-xs              : 480px;
@screen-xs-min          : @screen-xs;

// Small screen / tablet
@screen-sm              : 576px;
@screen-sm-min          : @screen-sm;

// Medium screen / desktop
@screen-md              : 768px;
@screen-md-min          : @screen-md;

// Large screen / wide desktop
@screen-lg              : 992px;
@screen-lg-min          : @screen-lg;

// Extra large screen / full hd
@screen-xl              : 1200px;
@screen-xl-min          : @screen-xl;

// Extra extra large screen / large descktop
@screen-xxl              : 1600px;
@screen-xxl-min          : @screen-xxl;

// provide a maximum
@screen-xs-max          : (@screen-sm-min - 1px);
@screen-sm-max          : (@screen-md-min - 1px);
@screen-md-max          : (@screen-lg-min - 1px);
@screen-lg-max          : (@screen-xl-min - 1px);
@screen-xl-max          : (@screen-xxl-min - 1px);
*/

export const xs = 480;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;
export const xxl = 1600;
