import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  HourglassOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  StopOutlined,
  SyncOutlined,
  WarningOutlined
} from '@ant-design/icons';
import type {IconBaseProps} from '@ant-design/icons/lib/components/Icon';
import React, {FunctionComponent} from 'react';

export type IconProps = IconBaseProps & {name: string};

export const Icon: FunctionComponent<IconProps> = ({name, ...otherProps}) => {
  const Icon = getIcon(name);
  return <Icon {...otherProps} />;
};

const getIcon = (name: string): FunctionComponent => {
  switch (name) {
    case 'check':
      return CheckOutlined;
    case 'check-circle':
      return CheckCircleOutlined;
    case 'close':
      return CloseOutlined;
    case 'close-circle':
      return CloseCircleOutlined;
    case 'clock-circle':
      return ClockCircleOutlined;
    case 'stop':
      return StopOutlined;
    case 'delete':
      return DeleteOutlined;
    case 'edit':
      return EditOutlined;
    case 'experiment':
      return ExperimentOutlined;
    case 'hourglass':
      return HourglassOutlined;
    case 'plus':
      return PlusOutlined;
    case 'question-circle':
      return QuestionCircleOutlined;
    case 'search':
      return SearchOutlined;
    case 'sync':
      return SyncOutlined;
    case 'exclamation-circle':
      return ExclamationCircleOutlined;
    default:
      return WarningOutlined;
  }
};

export default Icon;
