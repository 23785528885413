// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
const {presetPalettes} = require('@ant-design/colors');
const primaryColor = '#128df8';

module.exports = {
  // 'primary-color': '#1DA57A',
  // 'primary-color': '#128df8', // default
  'heading-color': presetPalettes.grey[7],
  'primary-color': primaryColor, // geekBlue5
  // 'link-color': '#128df8', // default
  'link-color': '#597ef7', // geekBlue5
  // 'primary-color': 'var(--primary500)',
  // 'link-color': 'var(--primary500)',
  'border-radius-base': '4px',
  'disabled-color': '#d5d8e3',
  'timeline-color': '#d5d8e3',

  // Layout
  'layout-header-background': 'inherit',
  'layout-body-background': 'inherit',
  'layout-header-height': '96px',
  // Menu
  'menu-bg': 'inherit'
};

// Layout
// @layout-body-background: #f0f2f5;
// @layout-header-background: #001529;
// @layout-header-height: 64px;
// @layout-header-padding: 0 50px;
// @layout-header-color: @text-color;
// @layout-footer-padding: 24px 50px;
// @layout-footer-background: @layout-body-background;
// @layout-sider-background: @layout-header-background;
// @layout-trigger-height: 48px;
// @layout-trigger-background: #002140;
// @layout-trigger-color: #fff;
// @layout-zero-trigger-width: 36px;
// @layout-zero-trigger-height: 42px;
// // Layout light theme
// @layout-sider-background-light: #fff;
// @layout-trigger-background-light: #fff;
// @layout-trigger-color-light: @text-color;

/*
var presetPrimaryColors = {
  red: '#F5222D',
  volcano: '#FA541C',
  orange: '#FA8C16',
  gold: '#FAAD14',
  yellow: '#FADB14',
  lime: '#A0D911',
  green: '#52C41A',
  cyan: '#13C2C2',
  blue: '#1890FF',
  geekblue: '#2F54EB',
  purple: '#722ED1',
  magenta: '#EB2F96',
  grey: '#666666'
};
*/
