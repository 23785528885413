import {
  BookOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  PauseOutlined
} from '@ant-design/icons';
import type {Application, ApplicationClientStatus, ApplicationWorkerStatus} from '@joonca/typings';
import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import React, {FunctionComponent, ReactNode} from 'react';
import {Tag, TagProps} from 'src/antd';
import {StatusTag} from './StatusTag';

export const APPLICATION_WORKER_STATUS_LABELS: {[s in ApplicationWorkerStatus]: string} = {
  applying: 'proposée',
  pending: 'en attente',
  withdrawn: 'retirée',
  confirmed: 'confirmée',
  terminated: 'clôturée'
};
export const APPLICATION_WORKER_STATUS_COLORS: {[s in ApplicationWorkerStatus]: TagProps['color']} = {
  applying: 'blue',
  pending: 'volcano',
  withdrawn: 'green',
  confirmed: 'green',
  terminated: 'purple'
};
export const APPLICATION_WORKER_STATUS_ICONS: {[s in ApplicationWorkerStatus]: ReactNode} = {
  applying: <BookOutlined />, // was book
  pending: <ClockCircleOutlined />,
  withdrawn: <ClockCircleOutlined />,
  confirmed: <CheckCircleOutlined />,
  terminated: <CloseCircleOutlined />
};
export const APPLICATION_WORKER_STATUS_CAPTIONS: {[s in ApplicationWorkerStatus]: string} = {
  applying: 'Le jober a envoyé sa candidature',
  pending: "Dans l'attente de réponse du jober", // ???
  withdrawn: 'Le jober a retiré sa candidature',
  confirmed: 'Le jober a confirmé son inscription à la mission',
  terminated: 'La candidature a été clôturée automatiquement par Joonca'
};

export const APPLICATION_CLIENT_STATUS_LABELS: {[s in ApplicationClientStatus]: string} = {
  pending: 'évaluée',
  accepted: 'acceptée',
  rejected: 'rejetée',
  scheduled: 'programmée',
  queued: 'réservée',
  cancelled: 'annulée',
  terminated: 'clôturée'
};
export const APPLICATION_CLIENT_STATUS_CAPTIONS: {[s in ApplicationClientStatus]: string} = {
  pending: "La candidature est en cours d'évaluation",
  accepted: 'La candidature a été acceptée',
  rejected: 'La candidature a été rejetée',
  scheduled: 'La mission a été programmée',
  queued: "La candidature a été acceptée sur liste d'attente",
  cancelled: 'La mission a été annulée',
  terminated: 'La candidature a été clôturée automatiquement par Joonca'
};
export const APPLICATION_CLIENT_STATUS_COLORS: {[s in ApplicationClientStatus]: TagProps['color']} = {
  pending: 'blue',
  accepted: 'green',
  rejected: 'red',
  scheduled: 'green',
  queued: 'blue',
  cancelled: 'red',
  terminated: 'purple'
};
export const APPLICATION_CLIENT_STATUS_ICONS: {[s in ApplicationClientStatus]: ReactNode} = {
  pending: <ClockCircleOutlined />,
  accepted: <CheckCircleOutlined />,
  rejected: <CloseCircleOutlined />,
  scheduled: <CheckCircleOutlined />,
  queued: <PauseOutlined />,
  cancelled: <CloseCircleOutlined />,
  terminated: <CloseCircleOutlined />
};

export type ApplicationWorkerStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Application['workerStatus']>;
  date?: Date | string | number;
};

export const ApplicationWorkerStatusTag: FunctionComponent<ApplicationWorkerStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip
      title={APPLICATION_WORKER_STATUS_CAPTIONS[value]}
      placement={placement}
      overlayStyle={{maxWidth: 200}}
    >
      <Tag
        icon={APPLICATION_WORKER_STATUS_ICONS[value]}
        color={APPLICATION_WORKER_STATUS_COLORS[value]}
        {...otherProps}
      >
        {APPLICATION_WORKER_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);

export type ApplicationClientStatusTagProps = TagProps & {
  placement?: TooltipProps['placement'];
  value: NonNullable<Application['clientStatus']>;
  date?: Date | string | number;
};

export const ApplicationClientStatusTag: FunctionComponent<ApplicationClientStatusTagProps> = ({
  value,
  placement,
  date,
  ...otherProps
}) => (
  <StatusTag date={date}>
    <Tooltip
      title={APPLICATION_CLIENT_STATUS_CAPTIONS[value]}
      placement={placement}
      overlayStyle={{maxWidth: 200}}
    >
      <Tag
        icon={APPLICATION_CLIENT_STATUS_ICONS[value]}
        color={APPLICATION_CLIENT_STATUS_COLORS[value]}
        {...otherProps}
      >
        {APPLICATION_CLIENT_STATUS_LABELS[value] || value}
      </Tag>
    </Tooltip>
  </StatusTag>
);
