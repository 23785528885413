// import {createContext} from 'react';
import {defaultIdKeys} from 'redux-rest-resource';
import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';
// import {State} from './reducers';

Object.assign(defaultIdKeys, {
  singular: '_sid',
  plural: '_sids'
});

/* eslint-disable no-underscore-dangle */
const actionSanitizer = (action) => (action.status ? {...action, type: `${action.type}.${action.status}`} : action);
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({actionSanitizer})
    : compose;
/* eslint-enable */

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

// export {State};
