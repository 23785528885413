export * from './TimeSelect';
export * from './TimeRangeSelect';
export * from './TimeMultipleRangeSelect';

import {TimeSelect as BaseTimeSelect} from './TimeSelect';
import {TimeRangeSelect} from './TimeRangeSelect';
import {TimeMultipleRangeSelect} from './TimeMultipleRangeSelect';
export const TimeSelect = Object.assign(BaseTimeSelect, {
  RangeSelect: TimeRangeSelect,
  MultipleRangeSelect: TimeMultipleRangeSelect
});
