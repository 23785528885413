// @docs https://ant.design/components/table/

import {CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField, numericSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {colors} from '@joonca/library/src/utils/typography';
import {Popconfirm} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {get} from 'lodash';
import React, {FunctionComponent, ReactNode, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as devicesActions} from 'src/store/modules/devices';
import {State} from 'src/store/reducers';
import {Device, Worker} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

type Props = {
  localStorageKey?: string;
};

const DEVICE_SEARCH_KEYS = [
  '_id',
  '_sid',
  'udid',
  'brand',
  'model',
  'manufacturer',
  'system.version',
  'application.version',
  'createdBy.email',
  'ownedBy.email'
];

const DeviceList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchDevices, deleteDevice} = useActions(devicesActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const devices = useSelector<State, Device[]>((state) => state.devices.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchDevices({}, {query: {population: {createdBy: 1, ownedBy: 1}}})]),
    [fetchDevices]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<Device>>(
    () => [
      {
        title: 'udid',
        dataIndex: 'udid'
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        filters: [{text: 'Apple', value: 'Apple'}],
        onFilter: (value, record): boolean => record.manufacturer === value
      },
      {
        title: 'Model',
        dataIndex: 'model',
        sorter: dateStringSorterForField('model')
      },
      {
        title: 'Build Number',
        dataIndex: 'application.buildNumber'
      },
      {
        title: 'Notifications',
        key: 'notifications',
        dataIndex: 'notifications',
        filters: [
          {text: 'Enabled', value: true},
          {text: 'Disabled', value: false}
        ],
        onFilter: (value, record): boolean => get(record, 'notifications.isEnabled', false) === value,
        sorter: numericSorterForField('notifications.isEnabled'),
        render: NotificationsCell
      },
      {
        title: 'Email',
        dataIndex: 'ownedBy.email',
        filters: [
          {text: 'Owned', value: 'owned'},
          {text: 'Created', value: 'created'},
          {text: 'Pristine', value: 'pristine'}
        ],
        onFilter: (value, record): boolean => {
          switch (value) {
            case 'owned':
              return !!get(record, 'ownedBy.email', false);
            case 'created':
              return !!get(record, 'createdBy.email', false);
            case 'pristine':
              return !get(record, 'ownedBy.email', false) && !get(record, 'createdBy.email', false);
            default:
              return true;
          }
        },
        render: (value, record): ReactNode => (
          <>
            <div>{get<Device, string>(record, 'ownedBy.email', '?')}</div>
            <small>{get<Device, string>(record, 'createdBy.email', '?')}</small>
          </>
        )
      },
      {
        title: 'Last Seen At',
        dataIndex: 'lastSeenAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('lastSeenAt'),
        render: dateTableRender
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('devices')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <LinkButton disabled ghost type="primary" icon="edit" to={`/devices/edit/${_sid}`} />
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteDevice({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteDevice, isSuperAdmin]
  );

  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon="plus" to="/devices/create">
          Add Device
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable<Device>
          columns={columns}
          dataSource={devices}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={DEVICE_SEARCH_KEYS}
          localStorageKey={localStorageKey || 'DeviceList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default DeviceList;

const NotificationsCell: FunctionComponent<Worker['notifications']> = (notifications) => {
  if (!notifications) {
    return <QuestionCircleOutlined style={{color: colors.error}} />;
  }
  const {hasPrompted, isEnabled} = notifications;
  if (!hasPrompted) {
    return <QuestionCircleOutlined style={{color: colors.info}} />;
  }
  if (!isEnabled) {
    return <CloseCircleOutlined style={{color: colors.error}} />;
  }
  return <CheckCircleOutlined style={{color: colors.success}} />;
};
