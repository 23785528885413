// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {CheckOutlined, CloseOutlined, HourglassOutlined} from '@ant-design/icons';
import {LinkButton, Button, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField, localeSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {colors} from '@joonca/library/src/utils/typography';
import {Worker} from '@joonca/typings';
import {Popconfirm, Tag, Tooltip} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {isEmpty, keyBy} from 'lodash';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import flags from 'src/assets/flags.json';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as usersActions} from 'src/store/modules/users';
import {actions as workersActions} from 'src/store/modules/workers';
import {State} from 'src/store/reducers';
import {useIsSuperAdmin} from 'src/utils/hooks';

const WORKER_SEARCH_KEYS = [
  'user.firstName',
  'user.lastName',
  'nationality',
  'gender',
  'user.email',
  'user.phoneNumber'
];

const getRegisterStepIndex = (worker: Worker): number => {
  // if (isEmpty(worker.experiences)) {
  //   return 0;
  // }
  // Diplôme 50%
  if (isEmpty(worker.certificates)) {
    return 1;
  }
  // Langues 60%
  if (isEmpty(worker.languages)) {
    return 2;
  }
  // Langues 70% SIREN
  if (isEmpty(worker.legalEntity) || !worker.legalEntity.number) {
    return 3;
  }
  // Langues 80% CNI
  if (isEmpty(worker.legalIdentity) || !worker.legalIdentity.document) {
    return 4;
  }
  // Langues 90% BANK
  if (isEmpty(worker.bankAccount) || !worker.bankAccount.iban) {
    return 5;
  }
  return 6;
};

type Props = {
  localStorageKey?: string;
};

const WorkerList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchWorkers, deleteWorker} = useActions(workersActions, []);
  const {blockUser} = useActions(usersActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const workers = useSelector<State, Array<Worker>>((state) => state.workers.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(() => Promise.all([fetchWorkers({}, {query: {population: {user: 1}}})]), [
    fetchWorkers
  ]);

  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const flagsMap = useMemo(() => keyBy(flags, 'code'), [flags]);

  const columns = useMemo<ColumnsType<Worker>>(
    () => [
      {
        title: 'firstName',
        dataIndex: ['user', 'firstName'],
        sorter: localeSorterForField('user.firstName')
      },
      {
        title: 'lastName',
        dataIndex: ['user', 'lastName'],
        sorter: localeSorterForField('user.lastName')
      },
      {
        title: 'Nationality',
        dataIndex: 'nationality',
        filters: [{text: 'French', value: 'fr'}],
        onFilter: (value, record) => record.nationality === value,
        render: (value) => {
          const flag = flagsMap[value.toUpperCase()];
          return (
            <Tag color="blue">
              {flag?.emoji || '🏳️'} {flag?.code}
            </Tag>
          );
        }
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        filters: [
          {text: 'Male', value: 'male'},
          {text: 'Female', value: 'female'}
        ],
        onFilter: (value, record) => record.gender === value
      },
      {
        title: 'Email',
        dataIndex: ['user', 'email']
      },
      {
        title: 'PhoneNumber',
        dataIndex: ['user', 'phoneNumber']
      },
      {
        title: 'progress',
        dataIndex: '_id',
        render: (_id, worker) => `${40 + getRegisterStepIndex(worker) * 10}%`,
        sorter: (a, b) => (getRegisterStepIndex(a) < getRegisterStepIndex(b) ? -1 : 1)
      },
      {
        title: 'legalEntity',
        dataIndex: 'legalEntity',
        render: LegalEntityCell
      },
      {
        title: 'legalIdentity',
        dataIndex: 'legalIdentity',
        render: LegalIdentityCell
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('workers')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 4,
        render: (_sid: string, record) => {
          return (
            <Button.Group>
              <Tooltip title="Voir" placement="bottom">
                <LinkButton ghost type="primary" icon="search" to={`/workers/${_sid}/view`} />
              </Tooltip>
              <Tooltip title="Editer" placement="bottom">
                <LinkButton disabled ghost type="primary" icon="edit" to={`/workers/${_sid}/edit`} />
              </Tooltip>
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => blockUser({_sid: record.user._sid})}
                okText="Block"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="stop" />
              </Popconfirm>
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteWorker({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteWorker, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon="plus" to="/workers/create">
          Add Worker
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={workers}
          searchValue={searchValue}
          searchKeys={WORKER_SEARCH_KEYS}
          rowKey="_sid"
          localStorageKey={localStorageKey || 'WorkerList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default WorkerList;

const LegalEntityCell: FunctionComponent<Worker['legalIdentity']> = (legalEntity) => {
  if (!legalEntity || !legalEntity.number) {
    return <CloseOutlined style={{color: colors.info}} />;
  }
  if (legalEntity.verificationStatus === 'pending') {
    return <HourglassOutlined style={{color: colors.error}} />;
  }
  if (!legalEntity.isVerified) {
    return <CloseOutlined style={{color: colors.error}} />;
  }
  return <CheckOutlined style={{color: colors.success}} />;
};

const LegalIdentityCell: FunctionComponent<Worker['legalIdentity']> = (legalIdentity) => {
  if (!legalIdentity || !legalIdentity.document) {
    return <CloseOutlined style={{color: colors.info}} />;
  }
  if (legalIdentity.verificationStatus === 'pending') {
    return <HourglassOutlined style={{color: colors.error}} />;
  }
  if (!legalIdentity.isVerified) {
    return <CloseOutlined style={{color: colors.error}} />;
  }
  return <CheckOutlined style={{color: colors.success}} />;
};
