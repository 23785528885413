/* eslint-disable @typescript-eslint/ban-ts-comment */
import {Button as BaseButton} from 'antd';
import styled from 'styled-components';
import Icon from 'src/components/Icon/Icon';
import React, {FunctionComponent, ReactNode} from 'react';
import {ButtonProps} from 'antd/lib/button';
import {Size, Theme, Contrast} from 'src/config/theme';

export type Props = Omit<ButtonProps, 'size' | 'type'> & {
  size?: Size;
  type?: ButtonProps['type'] | 'secondary';
  theme?: Theme;
  contrast?: Contrast;
  icon?: string | ReactNode;
};

// declare const ButtonTypes: ["default", "primary", "ghost", "dashed", "link", "text"];
// export declare type ButtonType = typeof ButtonTypes[number];

const Button: FunctionComponent<Props> = ({
  className = '',
  size = 'md',
  theme = 'primary',
  contrast = 500,
  icon,
  ...otherProps
}) => {
  return (
    <StyledButton
      size={size}
      className={`${className} ant-btn-${size}`.trim()}
      icon={typeof icon === 'string' ? <Icon name={icon} /> : icon}
      theme={theme}
      contrast={contrast}
      {...otherProps}
    />
  );
};

export default Object.assign(Button, {Group: BaseButton.Group});

const StyledButton = styled(BaseButton)<Props>`
  &.ant-btn {
    transition-duration: 0.2s;
    font-weight: 500;
    &.ant-btn-secondary {
      color: var(--grey700);
      background-color: var(--grey300);
      border-color: var(--grey300);
      &:hover {
        background-color: var(--grey400);
        border-color: var(--grey400);
      }
    }
    &.ant-btn-md {
      min-width: 40px;
      height: 40px;
    }
  }
`;

/*
.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['theme', 'contrast'].includes(prop) && defaultValidatorFn(prop)
})
color: white;
background-color: ${({theme, contrast}) => `var(--${theme}${contrast})`};
&:hover {
  background-color: ${({theme, contrast}) => `var(--${theme}${contrast + 100})`};
}
*/
