import {grey} from '@ant-design/colors';
import {ArrowRightOutlined} from '@ant-design/icons';
import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {TimeSelect, TimeSelectProps, TimeSelectValue} from './TimeSelect';

export type TimeRangeSelectValue = [TimeSelectValue, TimeSelectValue];

export type TimeRangeSelectProps = Omit<TimeSelectProps, 'onChange' | 'value'> & {
  value?: TimeRangeSelectValue;
  onChange?: (value: TimeRangeSelectValue) => void;
  spread?: number;
};

export const TimeRangeSelect: FunctionComponent<TimeRangeSelectProps> = ({
  onChange,
  value: propValue,
  hourStep = 1,
  minuteStep = 15,
  spread = 36e5 * 24,
  min,
  max,
  ...otherProps
}) => {
  const isDirty = useRef<boolean>(false);
  const [fromValue, setFromValue] = useState<TimeSelectValue | undefined>(
    propValue ? propValue[0] : undefined
  );
  const [toValue, setToValue] = useState<TimeSelectValue | undefined>(propValue ? propValue[1] : undefined);
  const values = useMemo<[TimeSelectValue?, TimeSelectValue?]>(() => [fromValue, toValue], [
    fromValue,
    toValue
  ]);

  useEffect(() => {
    if (!isDirty.current) {
      return;
    }
    const hasValidValues = !values.some((value) => value && value >= 0);
    if (onChange && hasValidValues) {
      onChange(values as TimeRangeSelectValue);
    }
  }, [onChange, values]);

  const handleFromChange = useCallback((value) => {
    isDirty.current = true;
    setFromValue(value);
  }, []);
  const handleToChange = useCallback((toValue) => {
    isDirty.current = true;
    setToValue(toValue);
  }, []);

  const fromMax = useMemo<number | undefined>(() => {
    const defaultMax = (toValue ?? spread) - minuteStep * 6e4;
    return max ? Math.min(max, defaultMax) : defaultMax;
  }, [toValue, minuteStep, spread, max]);

  const toMin = useMemo<number | undefined>(() => {
    const defaultMin = (fromValue ?? minuteStep * 6e4) + minuteStep * 6e4;
    return min ? Math.max(min, defaultMin) : defaultMin;
  }, [fromValue, minuteStep, min]);

  const toMax = useMemo<number | undefined>(() => {
    const defaultMax = (fromValue ?? 0) + spread;
    return max ? Math.min(max, defaultMax) : defaultMax;
  }, [fromValue, spread, max]);

  return (
    <Container>
      <TimeSelect
        {...otherProps}
        hourStep={hourStep}
        minuteStep={minuteStep}
        min={min}
        max={fromMax}
        onChange={handleFromChange}
        value={fromValue}
      />
      <Separator>
        {/* → */}
        <ArrowRightOutlined />
      </Separator>
      <TimeSelect
        {...otherProps}
        hourStep={hourStep}
        minuteStep={minuteStep}
        min={toMin}
        max={toMax}
        onChange={handleToChange}
        value={toValue}
        doesOverflow
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
`;
const Separator = styled.span`
  color: ${grey[0]};
  font-size: 20px;
  margin: 0 1rem;
`;
