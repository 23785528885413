import React, {FunctionComponent} from 'react';
import {Switch} from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import WorkerQualityForm from 'src/views/qualities/WorkerQualityForm';
import WorkerQualityList from 'src/views/qualities/WorkerQualityList';
import WorkerForm from 'src/views/workers/WorkerForm';
import WorkerList from 'src/views/workers/WorkerList';
import WorkerView from 'src/views/workers/WorkerView';
import PrivateRoute from '../helpers/PrivateRoute';

type Props = {};

const WorkerRoutes: FunctionComponent<Props> = () => (
  <Switch>
    <PrivateRoute exact path="/workers" component={WorkerList} layout={AppLayout} />
    <PrivateRoute path="/workers/create" component={WorkerForm} layout={AppLayout} />
    <PrivateRoute path="/workers/:id/edit" component={WorkerForm} layout={AppLayout} />
    <PrivateRoute path="/workers/:id/view" component={WorkerView} layout={AppLayout} />
    <PrivateRoute exact path="/qualities" component={WorkerQualityList} layout={AppLayout} />
    <PrivateRoute path="/qualities/create" component={WorkerQualityForm} layout={AppLayout} />
    <PrivateRoute path="/qualities/:id/edit" component={WorkerQualityForm} layout={AppLayout} />
  </Switch>
);

export const breadcrumbs = {
  '/workers': {label: 'Workers', icon: 'robot'},
  '/workers/create': {label: 'Create', icon: 'edit'},
  '/workers/:id/edit': {label: 'Edit', icon: 'edit'},
  '/workers/:id/view': {label: 'View', icon: 'search'},
  '/qualities': {label: 'Qualities', icon: 'like'},
  '/qualities/create': {label: 'Create', icon: 'edit'},
  '/qualities/:id/edit': {label: 'Edit', icon: 'edit'}
};

export default Object.assign(WorkerRoutes, {breadcrumbs});
