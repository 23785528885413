// @source https://github.com/ant-design/ant-design/tree/master/components/form

import {blue, red} from '@ant-design/colors';
import {Form as BaseForm} from 'antd';
import {FormItemProps as BaseFormItemProps, FormProps as BaseFormProps} from 'antd/lib/form';
import React, {forwardRef, ForwardRefRenderFunction, ReactNode, useContext, useRef} from 'react';
import styled from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';
import {FormInput} from './FormInput';
import {FormTextArea} from './FormTextarea';

const {Item: BaseItem, useForm} = BaseForm;

const StyledForm = styled(BaseForm)`
  &.ant-form {
    .ant-form-item-label > label {
      /* qonto label */
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: var(--grey700);

      &.ant-form-item-required {
        &::before {
          display: none;
        }
        &::after {
          content: '*';
          color: var(--grey500);
          /* color: var(--danger500); */
          display: inline-block;
          margin-bottom: -2px;
          overflow: hidden;
        }
      }
    }
    /* .ant-form-item-control-input {
      input {
        border-radius: 4px;
      }
    } */
    &.ant-form-lg {
      .ant-form-item-label > label {
        font-size: 16px;
      }
    }
    &.ant-form-xl {
      .ant-form-item-label > label {
        font-size: 18px;
      }
    }
    /* feat: more room to breathe */
    .ant-form-item {
      &.ant-form-item-with-help {
        margin-bottom: 8px;
      }
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
`;

export type Context = Record<string, unknown>;
export const Context = React.createContext<Context>({});

export type FormProps = Omit<BaseFormProps, 'size'> & {
  size?: SizeType;
  context?: Context;
};

const FormWithoutRef: ForwardRefRenderFunction<typeof BaseForm, FormProps> = (
  {children, context = {}, ...props},
  ref
) => {
  const contextRef = useRef<Context>(context);
  return (
    <Context.Provider value={contextRef.current}>
      <StyledForm ref={ref} {...mapSizeProps(props, 'ant-form-')} {...props}>
        {children}
      </StyledForm>
    </Context.Provider>
  );
};
export const Form = forwardRef(FormWithoutRef);

// Form.Item

export const StyledItem = styled(BaseItem)`
  &.ant-form-item {
    /* fix: https://github.com/ant-design/ant-design/issues/21116 */
    /* &.ant-form-item-has-error .ant-input-affix-wrapper {
      border-color: #ff4d4f;
      &:hover,
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
      }
      .ant-input {
        border: none;
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    } */
    .ant-form-item-control-input {
      input {
        border-radius: 4px;
      }
    }
    &.ant-form-item-has-error {
      .ant-radio-button-wrapper {
        border-color: #ff4d4f !important;
      }
      label > .anticon {
        color: ${red.primary};
      }
    }
    label > .anticon {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: ${blue.primary};
    }
  }
`;

export type FormItemProps = BaseFormItemProps & {
  prefix?: ReactNode;
};

export const ItemWithoutRef: ForwardRefRenderFunction<typeof BaseItem, FormItemProps> = (
  {label, prefix, children, ...otherProps},
  ref
) => {
  const context = useContext(Context);
  const actualPrefix = prefix ?? context.prefix;
  const actualLabel =
    label && actualPrefix ? (
      <>
        {actualPrefix} {label}
      </>
    ) : (
      label
    );
  return (
    <StyledItem ref={ref} label={actualLabel} {...otherProps}>
      {children}
      {/* {React.Children.map(children, child => React.cloneElement(child, {prefix: actualPrefix}))} */}
    </StyledItem>
  );
};
export const Item = forwardRef(ItemWithoutRef);

// Form.Row

const FormRow = styled.div.attrs<{className?: string}>(() => ({
  className: 'ant-row'
}))`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  /* align-items: space-between; */
  /* justify-content: space-between; */
  > .ant-row {
    flex: 1;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

// Interface

export default Object.assign(Form, {
  Item,
  Row: FormRow,
  Input: FormInput,
  TextArea: FormTextArea,
  useForm
});
