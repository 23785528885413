import React, {FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Select, SelectProps} from 'src/antd';
import {importAsset} from 'src/assets';
import {useIsMounted, useAsyncEffect} from 'src/hooks';
import {time, timeEnd} from 'src/utils';
import TrieSearch from 'trie-search';

const MIN_SEARCH_LENGTH = 2;

type Option = {
  value: string | number;
  label: string;
};

export type DesignationSelectProps = SelectProps<string> & {
  minSearchLength?: number;
  children?: ReactNode;
};

export const DesignationSelect: FunctionComponent<DesignationSelectProps> = ({
  defaultValue,
  value = '',
  minSearchLength = MIN_SEARCH_LENGTH,
  children,
  ...otherProps
}) =>
  // ref
  {
    const [options, setOptions] = useState<Option[]>([]);
    const {current: trieSearch} = useRef(new TrieSearch(['value', 'label']));
    const [trieIsReady, setTrieIsReady] = useState<boolean>(false);
    const isMounted = useIsMounted();

    useAsyncEffect(async () => {
      const datasource = await importAsset('rome/designationSelect');
      time('DesignationSelect.buildingTrie');
      trieSearch.addAll(datasource);
      timeEnd('DesignationSelect.buildingTrie');
      if (!isMounted()) {
        return;
      }
      setTrieIsReady(true);
      if (value) {
        setOptions(trieSearch.get(value));
      } else if (defaultValue) {
        setOptions(trieSearch.get(defaultValue));
      }
    }, []);

    const handleSearch = useCallback(
      (searchValue) => {
        if (searchValue.length < minSearchLength) {
          return;
        }
        time('DesignationSelect.searchingTrie');
        const results = trieSearch.get(
          [searchValue].concat(defaultValue ? [value].concat(defaultValue) : value)
        );
        timeEnd('DesignationSelect.searchingTrie');
        setOptions(results);
      },
      [defaultValue, minSearchLength, trieSearch, value]
    );

    // Track prop value external changes
    const isValidOption = useMemo<boolean>(() => options.some((option) => option.value === value), [
      value,
      options
    ]);
    useEffect(() => {
      function applyEffect(): void {
        if (!value || isValidOption || !trieIsReady) {
          return;
        }
        const options = trieSearch.get(value);
        setOptions(options);
      }
      applyEffect();
    }, [trieSearch, isValidOption, value, trieIsReady]);

    return (
      <>
        <Select
          showSearch
          value={isValidOption ? value : ''}
          options={options}
          defaultValue={defaultValue}
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={handleSearch}
          notFoundContent={null}
          {...otherProps}
        />
        {children}
      </>
    );
  };

/*
// @url http://www.pole-emploi.org/opendata/repertoire-operationnel-des-meti.html?type=article
cat pole-emploi-rome-arborescence-principale.json | jq '.[] | {(.fields.code_ogr): .fields.libelle}' | jq -s add
cat pole-emploi-rome-arborescence-principale.json | jq '.[] | {value: .fields.code_ogr, label: .fields.libelle}' | jq -s 'unique_by(.value)'
*/
