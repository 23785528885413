import {Tooltip} from 'antd';
import {TooltipProps} from 'antd/lib/tooltip';
import dayjs from 'dayjs';
import React, {FunctionComponent, HTMLProps, useMemo} from 'react';
import 'dayjs/plugin/relativeTime';

export type FormattedDateDateProps = Pick<HTMLProps<HTMLSpanElement>, 'style'> & {
  value: number | string | Date | undefined;
  format?: string;
  tooltip?: TooltipProps['placement'];
};

export const FormattedDate: FunctionComponent<FormattedDateDateProps> = ({
  value,
  format,
  tooltip,
  ...otherProps
}) => {
  const date = useMemo(() => {
    return dayjs(value);
  }, [value]);
  const formattedDate = useMemo(() => date.format(format), [date, format]);
  const fromNow = useMemo(() => date.fromNow(), [date]);
  const dateSpan = <span {...otherProps}>{formattedDate}</span>;
  if (!tooltip) {
    return dateSpan;
  }
  return (
    <Tooltip title={fromNow} placement={tooltip} style={{cursor: 'hand'}}>
      {dateSpan}
    </Tooltip>
  );
};
