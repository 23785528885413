// @source https://github.com/ant-design/ant-design/tree/master/components/form

import {Form as AntDesignForm, Spin} from 'antd';
import {FormProps as AntDesignFormProps} from 'antd/lib/form';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useLogEffect} from 'src/utils';
import './style/form.less';
import {FormInstance} from './useForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormProps<T = any> = Omit<AntDesignFormProps<T>, 'form' | 'onFinish'> & {
  form: FormInstance<T>;
  requiresInitialValues?: boolean;
  onFinish?: (changedValues: Partial<T>, allValues: T) => void;
};

export const Form = <T,>({
  form,
  onValuesChange,
  onFieldsChange,
  onFinish,
  requiresInitialValues,
  initialValues,
  ...otherProps
}: FormProps<T>): ReturnType<FunctionComponent> => {
  const {
    onValuesChange: formOnValuesChange,
    onInitialValueChange: formOnInitialValueChange
  } = form.__EXTERNAL__;
  useEffect(() => {
    if (formOnInitialValueChange) {
      formOnInitialValueChange(initialValues);
    }
  }, [initialValues]);
  const handleValuesChange = useCallback<NonNullable<AntDesignFormProps<T>['onValuesChange']>>(
    (changedValues, allValues) => {
      // jlog({onValuesChange: changedValues}, 'Form');
      if (formOnValuesChange) {
        formOnValuesChange(changedValues, allValues);
      }
      if (onValuesChange) {
        onValuesChange(changedValues, allValues);
      }
    },
    [formOnValuesChange, onValuesChange]
  );
  const handleFieldsChange = useCallback<NonNullable<AntDesignFormProps<T>['onFieldsChange']>>(
    (changedFields, allFields) => {
      // jlog({onFieldsChange: changedFields});
      if (onFieldsChange) {
        onFieldsChange(changedFields, allFields);
      }
    },
    [onFieldsChange]
  );
  // @NOTE Default to changedValues and move allValues as 2nd parameter
  const handleFinish = useCallback<NonNullable<AntDesignFormProps<T>['onFinish']>>(
    (allValues) => {
      const changedValues = form.getFieldsValue(true, (meta) => meta.touched);
      if (onFinish) {
        onFinish(changedValues, allValues);
      }
    },
    [form, onFinish]
  );
  // useLogEffect({handleValuesChange, handleFieldsChange, handleFinish, initialValues}, 'Form');

  // jlog({requiresInitialValues, initialValues});
  if (requiresInitialValues && !initialValues) {
    return <Spin size="large" />;
  }

  return (
    <AntDesignForm
      form={form}
      onValuesChange={handleValuesChange}
      onFieldsChange={handleFieldsChange}
      onFinish={handleFinish}
      initialValues={initialValues}
      {...otherProps}
    />
  );
};

// const isPristine = !form.isFieldsTouched();
// // form.validateFields();
// const hasAnyError = form.getFieldsError().some(({errors}) => errors.length > 0);
// jlog({onValuesChange: {isPristine, hasAnyError}});
// setDisabled(isPristine || hasAnyError);
