import React, {FunctionComponent} from 'react';
import {HOST_STORAGE_URL} from 'src/config/env';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
  source: {uri: string};
};

const Image: FunctionComponent<Props> = ({source, ...otherProps}) => {
  const {uri} = source;
  const src = `${HOST_STORAGE_URL}/files/contents/${uri}`;
  return <img src={src} {...otherProps} />;
};

export default Image;
