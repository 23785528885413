// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders
// @source https://github.com/ant-design/ant-design/blob/master/components/select/index.tsx

import {DatePicker as BaseDatePicker} from 'antd';
import {Moment} from 'moment';
import {
  DatePickerProps as BaseDatePickerProps,
  RangePickerProps as BaseRangePickerProps
} from 'antd/lib/date-picker';
import React, {createElement, useCallback, forwardRef, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';

// padding: 6.5px 11px 6.5px;

const toUTCStartOfDay = (date: Moment): Moment => date.utc().startOf('day');

const sizeMixin = (size: string): FlattenInterpolation<unknown> => css`
  &.ant-picker {
    &.ant-picker-${size} {
      padding: 6.5px 11px 6.5px;
      .ant-picker-input > input {
        font-size: 18px;
      }
    }
  }
`;

const StyledDatePicker = styled(BaseDatePicker)`
  /* &.ant-input {
    background: red;
  } */
  /* &.ant-select {
    &.ant-select-xl {
      font-size: 16px;
    }
    &.ant-select-single.ant-select-xl:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 40px;
      }
      .ant-select-selection-placeholder {
        line-height: 40px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 38px;
      }
    }
  } */
  ${sizeMixin('xl')}
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

export type DatePickerProps = BaseDatePickerProps & {
  size: SizeType;
};

const DatePicker: RefForwardingComponent<typeof BaseDatePicker, DatePickerProps> = (
  {onChange, ...otherProps},
  ref
) => {
  const handleChange = useCallback((date, string) => {
    if (onChange) {
      onChange(toUTCStartOfDay(date), string);
    }
  }, []);
  return <StyledDatePicker ref={ref} onChange={handleChange} {...otherProps} {...mapSizeProps} />;
  // return createElement(StyledDatePicker, {
  //   ref,
  //   ...props,
  //   ...mapSizeProps(props, 'ant-select-')
  // } as BaseDatePickerProps);
};

const StyledRangePicker = styled(BaseDatePicker.RangePicker)`
  /* &.ant-input {
    background: red;
  } */
  /* &.ant-select {
    &.ant-select-xl {
      font-size: 16px;
    }
    &.ant-select-single.ant-select-xl:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 40px;
      }
      .ant-select-selection-placeholder {
        line-height: 40px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 38px;
      }
    }
  } */
  ${sizeMixin('xl')}
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

export type RangePickerProps = BaseRangePickerProps & {
  size: SizeType;
};

const RangePicker: RefForwardingComponent<typeof BaseDatePicker.RangePicker, RangePickerProps> = (
  {onChange, ...otherProps},
  ref
) => {
  const handleChange = useCallback((dates, string) => {
    if (onChange) {
      onChange(dates.map(toUTCStartOfDay), string);
    }
  }, []);
  return <StyledRangePicker ref={ref} onChange={handleChange} {...otherProps} {...mapSizeProps} />;
  // return createElement(StyledDatePicker, {
  //   ref,
  //   ...props,
  //   ...mapSizeProps(props, 'ant-select-')
  // } as BaseDatePickerProps);
};

interface DatePickerExportInterface {
  (): typeof DatePicker;
  RangePicker: typeof RangePicker;
}
const ExportedDatePicker = (forwardRef(DatePicker) as unknown) as DatePickerExportInterface;
ExportedDatePicker.RangePicker = forwardRef(RangePicker);
export default ExportedDatePicker;
