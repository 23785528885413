import React, {PureComponent} from 'react';
// import {Button, LinkButton} from '@joonca/library';
// import {Link} from 'react-router-dom';
import {Select /* , Icon, Divider */} from 'antd';
import {fetch} from 'redux-rest-resource';
import {HOST_API_URL} from 'src/config/env';

const Option = Select.Option; // eslint-disable-line prefer-destructuring

export default class WorkPositionSelect extends PureComponent {
  state = {
    positions: []
  };

  async componentDidMount() {
    const res = await fetch(`${HOST_API_URL}/positions`, {credentials: 'include'});
    const positions = await res.json();
    this.setState({positions});
  }

  filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  render() {
    const {filter, value, ...otherProps} = this.props;
    const {positions} = this.state;
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={this.filterOption}
        value={positions.length ? value : null}
        // dropdownRender={menu => (
        //   <div>
        //     {menu}
        //     <Divider style={{margin: '4px 0'}} />
        //     <LinkButton icon="plus" style={{padding: '8px', cursor: 'pointer'}} to="/work/positions/create">
        //       Add item
        //     </LinkButton>
        //   </div>
        // )}
        {...otherProps}
      >
        {positions.map((position) => (
          <Option key={position._id} value={position._id}>
            {position.name} ({position.category})
          </Option>
        ))}
      </Select>
    );
  }
}
