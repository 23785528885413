import React, {Children, cloneElement, Fragment, PureComponent} from 'react';
import {Button, Spin} from 'antd';
// import {Route, Redirect} from 'react-router';
import {StepLink} from '@joonca/library';
// @locals
import Steps from 'src/components/navigation/Steps';
import {count} from '@joonca/library/src/utils/console';
import {IS_DEV} from 'src/config/env';
// @types
// import type {FormProps} from 'antd'; // CRASHES!!
// import type {ContextRouter} from 'react-router-dom';

import Form from './Form';
import FormFooter from './FormFooter';

const {Step} = Steps;

// type Props = FormProps &
//   ContextRouter & {
//     onSubmit: any => void
//   };

export default class StepperForm extends PureComponent<Props> {
  static getDerivedStateFromProps(props: Props, state: State) {
    const {match, location, steps, isCreating, isUpdating, isFetching, stepsValues} = props;
    const {stepsValues: stateStepsValues} = state;
    const isEditing = !!match.params.id;
    const path = location.pathname.replace(match.url, match.path);
    return {
      ...state,
      isEditing,
      isPreloading: isEditing && isFetching,
      isLoading: isEditing ? isUpdating : isCreating,
      stepsValues: stepsValues || stateStepsValues,
      ...StepperForm.getCurrentStep(steps, path, isEditing)
    };
  }

  static getCurrentStep(steps: Array<any>, path: string, isEditing = false) {
    let stepIndex = -1;
    let isExactStep = false;
    steps[isEditing ? 'edit' : 'create'].some((step, index) => {
      const doesMatch = path.startsWith(step.path);
      if (doesMatch) {
        stepIndex = index;
        isExactStep = path === step.path;
        return true;
      }
      return false;
    });
    return {stepIndex, isExactStep};
  }

  state = {
    isEditing: false,
    isPreloading: false,
    isLoading: false,
    stepIndex: 0,
    isExactStep: false,
    stepsValues: []
  };

  componentDidMount() {
    // Reset internal state on re-mount
    this.setState({stepsValues: []});
  }

  get steps() {
    const {isEditing} = this.state;
    const {steps} = this.props;
    return steps[isEditing ? 'edit' : 'create'];
  }

  handlePrevClick = () => {
    const {stepIndex} = this.state;
    const {form, match, history} = this.props;
    // Save draft of values without validation
    const currentValues = form.getFieldsValue();
    // console.warn({currentValues});
    this.handleChange(currentValues);
    const targetPath = this.steps[stepIndex - 1].path.replace(match.path, match.url);
    history.push(targetPath);
  };

  handleNextClick = (ev: SyntheticEvent<EventTarget>) => {
    ev.preventDefault();
    const {stepIndex} = this.state;
    const {form, match, history} = this.props;
    // Validate and save values
    form.validateFields((err, currentValues) => {
      if (err) {
        return;
      }
      this.handleChange(currentValues);
      const targetPath = this.steps[stepIndex + 1].path.replace(match.path, match.url);
      history.push(targetPath);
    });
  };

  handleChange = (nextValues) => {
    const {stepIndex, stepsValues} = this.state;
    stepsValues[stepIndex] = stepsValues[stepIndex] ? {...stepsValues[stepIndex], ...nextValues} : nextValues;
    window.nextValues = nextValues;
    // console.warn(`${this.constructor.name}.handleChange`, {nextValues, stepValues: stepsValues[stepIndex]});
    this.setState({stepsValues: stepsValues.slice()});
  };

  handleCancel = (ev: SyntheticEvent<any>) => {
    const {history, location} = this.props;
    const {isEditing} = this.state;
    ev.preventDefault();
    const target = location.pathname
      .split('/')
      .slice(0, isEditing ? -3 : -2)
      .join('/');
    history.push(target);
  };

  handleSubmit = (ev: SyntheticEvent<EventTarget>) => {
    ev.preventDefault();
    // console.warn('handleSubmit');
    const {form, onSubmit} = this.props;
    const {stepsValues} = this.state;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const flattenStepsValues = stepsValues.reduce((soFar, value) => (value ? {...soFar, ...value} : soFar), {});
      const finalValues = {...flattenStepsValues, ...values};
      if (onSubmit) {
        onSubmit(finalValues);
      }
    });
  };

  renderLeftButton(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <Button htmlType="button" icon="close" onClick={this.handleCancel}>
            Cancel
          </Button>
        );
      case -1:
        return null;
      default:
        return (
          <Fragment>
            <Button htmlType="button" icon="left" onClick={this.handlePrevClick}>
              Previous
            </Button>
            <Button htmlType="button" icon="close" onClick={this.handleCancel}>
              Cancel
            </Button>
          </Fragment>
        );
    }
  }

  renderRightButton(stepIndex: number) {
    const {isEditing, isPreloading, isLoading} = this.state;
    switch (stepIndex) {
      case this.steps.length - 1:
        return (
          <Button type="primary" htmlType="submit" icon="check" disabled={isPreloading} loading={isLoading}>
            {isEditing ? 'Update' : 'Create'}
          </Button>
        );
      case -1:
        return null;
      default:
        return (
          <Button type="primary" htmlType="submit" icon="right" onClick={this.handleNextClick}>
            Next
          </Button>
        );
    }
  }

  render() {
    count(`${this.constructor.name}.render()`);
    // console.warn(`${this.constructor.name}.render()`, this.props, this.state);
    // console.warn(`${this.constructor.name}.render()`, {values: this.props.values});
    const {location, children, match} = this.props;
    const {stepIndex, isEditing, isExactStep, isPreloading, stepsValues} = this.state;
    return (
      <Form onSubmit={this.handleSubmit} key={match.params.id || 'create'}>
        <Spin spinning={isPreloading} delay={100}>
          <Steps current={stepIndex}>
            {this.steps.map((step) => {
              const targetPath = step.path.replace(match.path, match.url);
              return IS_DEV || isEditing ? (
                <StepLink title={step.title} to={targetPath} key={targetPath} />
              ) : (
                <Step title={step.title} key={targetPath} />
              );
            })}
          </Steps>
          {Children.map(children, (child) => {
            const {component: Component, values} = child.props;
            if (!Component) {
              return child;
            }
            return cloneElement(child, {
              component: undefined,
              render: () => (
                <Component {...this.props} values={values || stepsValues[stepIndex]} onChange={this.handleChange} />
              )
            });
          })}
          {isExactStep ? (
            <FormFooter key={location.pathname}>
              {this.renderLeftButton(stepIndex)}
              {this.renderRightButton(stepIndex)}
            </FormFooter>
          ) : null}
        </Spin>
      </Form>
    );
  }
}
