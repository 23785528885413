// @docs https://ant.design/components/table/

import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {Popconfirm} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as qualitiesActions} from 'src/store/modules/qualities';
import {State} from 'src/store/reducers';
import {Quality} from '@joonca/typings';

type Props = {
  localStorageKey?: string;
};

const QUALITY_SEARCH_KEYS = ['_id', '_sid', 'name'];

const QualityList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchQualities, deleteQuality} = useActions(qualitiesActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const qualities = useSelector<State, Quality[]>((state) => state.qualities.resource.items);

  const fetchData = useCallback(
    () => Promise.all([fetchQualities({}, {query: {population: {createdBy: 1, ownedBy: 1}}})]),
    [fetchQualities]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<Quality>>(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: dateStringSorterForField('name')
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('qualities')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <LinkButton
                disabled
                ghost
                type="primary"
                icon={<EditOutlined />}
                to={`/qualities/edit/${_sid}`}
              />
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteQuality({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteQuality]
  );

  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon={<PlusOutlined />} to="/qualities/create">
          Add Quality
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable<Quality>
          columns={columns}
          dataSource={qualities}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={QUALITY_SEARCH_KEYS}
          localStorageKey={localStorageKey || 'QualityList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default QualityList;
