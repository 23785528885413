// @url https://stackoverflow.com/questions/41900143/what-is-the-default-color-and-font-for-input-placeholders
// @source https://github.com/ant-design/ant-design/blob/master/components/select/index.tsx

import {AutoComplete as BaseAutoComplete} from 'antd';
import {AutoCompleteProps as BaseAutoCompleteProps} from 'antd/lib/auto-complete';
import {createElement, forwardRef, RefForwardingComponent} from 'react';
import styled, {css, FlattenInterpolation} from 'styled-components';
import {mapSizeProps, SizeType} from '../helpers/size';

const {Option} = BaseAutoComplete;

const sizeMixin = (size: string): FlattenInterpolation<unknown> => css`
  &.ant-select {
    &.ant-select-${size} {
      font-size: 18px;
    }
    &.ant-select-single.ant-select-${size}:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 46px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 46px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: ${46 - 2}px;
      }
    }
  }
`;

const StyledAutoComplete = styled(BaseAutoComplete)`
  /* &.ant-input {
    background: red;
  } */
  /* &.ant-select {
    &.ant-select-xl {
      font-size: 16px;
    }
    &.ant-select-single.ant-select-xl:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 40px;
      }
      .ant-select-selection-placeholder {
        line-height: 40px;
      }
      &:not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 38px;
      }
    }
  } */
  ${sizeMixin('xl')}
  .ant-input-prefix > .anticon {
    color: #a9a9a9;
  }
`;

export type AutoCompleteProps = BaseAutoCompleteProps & {
  size: SizeType;
};

const AutoComplete: RefForwardingComponent<typeof BaseAutoComplete, AutoCompleteProps> = (props, ref) => {
  return createElement(StyledAutoComplete, {
    ref,
    ...props,
    ...mapSizeProps(props, 'ant-select-')
  } as BaseAutoCompleteProps);
};

interface AutoCompleteExportInterface {
  (): typeof AutoComplete;
  Option: typeof BaseAutoComplete.Option;
}
((AutoComplete as unknown) as AutoCompleteExportInterface).Option = Option;
export default forwardRef(AutoComplete);
