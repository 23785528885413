// @from https://stackoverflow.com/a/62017005/520129
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useCallback, useRef} from 'react';
import {debounce, DebounceSettings, DebouncedFunc} from 'lodash';

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings
): DebouncedFunc<T> => {
  // ...
  const funcRef = useRef<T>(func);
  const waitRef = useRef<number | undefined>(wait);
  useEffect(() => {
    funcRef.current = func;
    waitRef.current = wait;
  });

  return useCallback(
    debounce(
      // (...args) => {
      //   // Debounce is an async callback. Cancel it, if in the meanwhile
      //   // (1) component has been unmounted (see isMounted in snippet)
      //   // (2) delay has changed
      //   if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
      // },
      ((...args) => funcRef.current(...args)) as T,
      wait,
      options
    ),
    [wait]
  );
};
