export const NAME: string = process.env.REACT_APP_NAME || '';
export const VERSION: string = process.env.REACT_APP_VERSION || '';
export const RELEASE_NAME = `${NAME}@${VERSION}`;

const HOSTNAME_ENDPOINTS_MAP = {
  localhost: {
    api: '/api',
    auth: '/auth',
    storage: '/storage'
  },
  'admin.joonca.mgcrea.io': {
    api: 'https://api.joonca.mgcrea.io/api',
    auth: 'https://api.joonca.mgcrea.io/auth',
    storage: 'https://api.joonca.mgcrea.io/storage'
  },
  'admin.beta.joonca.com': {
    api: 'https://api.beta.joonca.com',
    auth: 'https://auth.beta.joonca.com',
    storage: 'https://storage.beta.joonca.com'
  },
  'admin.joonca.com': {
    api: 'https://api.joonca.com',
    auth: 'https://auth.joonca.com',
    storage: 'https://storage.joonca.com'
  }
};

const {hostname: CURRENT_HOSTNAME} = window.location;
const HOSTNAME = process.env.REACT_APP_HOSTNAME || CURRENT_HOSTNAME;

const HOSTNAME_ENDPOINTS = HOSTNAME_ENDPOINTS_MAP[HOSTNAME]
  ? HOSTNAME_ENDPOINTS_MAP[HOSTNAME]
  : HOSTNAME_ENDPOINTS_MAP.localhost;

export const HOST_API_URL: string = HOSTNAME_ENDPOINTS.api;
export const HOST_AUTH_URL: string = HOSTNAME_ENDPOINTS.auth;
export const HOST_STORAGE_URL: string = HOSTNAME_ENDPOINTS.storage;

export const APP_NAME: string = process.env.__APP_NAME__ || '';
export const APP_VERSION: string = process.env.__APP_VERSION__ || '';
export const APP_COMMIT: string = process.env.__APP_COMMIT__ || '';
Object.assign(window, {__VERSION__: {name: APP_NAME, version: APP_VERSION, commit: APP_COMMIT}});

export const USERNAME: string = process.env.REACT_APP_USERNAME || '';
export const PASSWORD: string = process.env.REACT_APP_PASSWORD || '';
export const PUBLIC_URL: string = process.env.PUBLIC_URL || '';
export const NODE_ENV: string = process.env.NODE_ENV || 'development';
export const IS_DEV: boolean = NODE_ENV === 'development';
export const IS_PROD: boolean = NODE_ENV === 'production';
export const USE_FIXTURES: boolean = IS_DEV && !!process.env.REACT_APP_USE_FIXTURES;
export const MAPBOX_API_ACCESS_TOKEN: string = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || '';
