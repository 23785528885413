import React, {FunctionComponent, HTMLProps} from 'react';
import styled from 'styled-components';
import {Link, LinkProps} from 'react-router-dom';
import {colors} from 'src/config/theme';

type AccentProps = HTMLProps<HTMLSpanElement> & {
  href?: LinkProps['href'];
  to?: LinkProps['to'];
};

export const Accent: FunctionComponent<AccentProps> = ({children, to, href, className, style}) => {
  return (
    <StyledSpan className={className} style={style}>
      {to ? <Link to={to}>{children}</Link> : null}
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer nofollow">
          {children}
        </a>
      ) : null}
      {!to && !href ? children : null}
    </StyledSpan>
  );
};

const StyledSpan = styled.span`
  transition: all 0.25s linear;
  cursor: default;
  /* color: var(--blue600); */
  color: ${colors.blue[600]};
  a {
    color: ${colors.blue[600]};
  }
  font-weight: 500;
  /* text-decoration: underline; */
  border-color: var(--blue200);
  box-shadow: inset 0 -2px 0 var(--blue200);
  /* background: #ddeaf6; */
  &:hover {
    /* color: var(--blue700); */
    box-shadow: inset 0 -20px 0 var(--blue200);
    /* background: var(--blue300); */
  }
`;
