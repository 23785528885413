// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {CloudDownloadOutlined} from '@ant-design/icons';
import {Button, useActions, useAsyncEffect} from '@joonca/library';
import {colors} from '@joonca/library/src/common/vars';
import {dateStringSorterForField, numericSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell, utcDateTableRender} from '@joonca/library/src/utils/table';
import {Popconfirm, Tooltip} from 'antd';
import {ColumnProps} from 'antd/lib/table';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import localityColumn from 'src/components/app/table/localityColumn';
import missionColumn from 'src/components/app/table/missionColumn';
import userColumn from 'src/components/app/table/userColumn';
// import InvoiceStatus from 'src/components/app/mission/InvoiceStatus';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {HOST_API_URL, HOST_STORAGE_URL} from 'src/config/env';
import {actions as invoicesActions} from 'src/store/modules/invoices';
import {State} from 'src/store/reducers';
import {Invoice} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

const QUERY_POPULATION = {
  worker: 1,
  user: {avatar: 1, firstName: 1, lastName: 1, username: 1},
  position: {name: 1},
  mission: {_sid: 1, internalTitle: 1, internalReference: 1, startsAt: 1, place: 1},
  organization: {name: 1}
};

type Props = {
  localStorageKey?: string;
  queryFilter?: {[s: string]: unknown};
};
const InvoiceList: FunctionComponent<Props> = ({localStorageKey, queryFilter = {}}) => {
  const {fetchInvoices, deleteInvoice} = useActions(invoicesActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const invoices = useSelector<State, Array<Invoice>>((state) => state.invoices.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchInvoices({}, {query: {filter: queryFilter, population: QUERY_POPULATION}})]),
    [fetchInvoices]
  );

  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnProps<Invoice>[]>(
    () => [
      userColumn(),
      missionColumn(),
      localityColumn(),
      {
        title: 'Start Date',
        dataIndex: ['mission', 'startsAt'],
        sorter: dateStringSorterForField('mission.startsAt'),
        render: dateTableRender
      },
      // {
      //   title: 'Position',
      //   dataIndex: 'position',
      //   render: value => (value ? value.name : '')
      // },
      // {
      //   title: 'Organization',
      //   dataIndex: 'organization',
      //   render: value => (value ? value.name : '')
      // },
      // isClosedColumn,
      // {
      //   title: 'Statut',
      //   dataIndex: 'status',
      //   render: (value, record) => <InvoiceStatus type="worker" status={value} date={record.statusDate} />
      // },

      {
        title: 'Date',
        dataIndex: 'date',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('date'),
        render: utcDateTableRender
      },
      {
        title: 'Montant',
        dataIndex: 'amount',
        sorter: numericSorterForField('amount'),
        render: (value, record) => (
          <div>
            <div style={{color: colors.primary}}>{record.amount.toFixed(2)} €</div>
            <small>
              <ul style={{padding: 0, listStyle: 'none'}}>
                {record.items.map((item, index) => (
                  <li key={index}>
                    {item.amount.toFixed(2)} € * {item.quantity.toFixed(2)} h
                  </li>
                ))}
              </ul>
            </small>
          </div>
        )
      },
      {
        title: 'Création',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('invoices')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 16 * 2 + 32 * 2,
        render: (_sid: string, record) => {
          return (
            <Button.Group>
              <Tooltip title="Télécharger" placement="bottom">
                <Button
                  ghost
                  type="primary"
                  icon={<CloudDownloadOutlined />}
                  target="_blank"
                  href={`${HOST_API_URL}/invoices/${_sid}/download`}
                />
              </Tooltip>
              {/* <Tooltip title="Editer" placement="bottom">
                <LinkButton ghost type="primary" icon="edit" to={`/invoices/${_sid}/edit`} />
              </Tooltip> */}
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteInvoice({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteInvoice, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        {/* <LinkButton type="primary" size="large" icon="plus" to="/invoices/create">
          Add Invoice
        </LinkButton> */}
      </TableHeader>
      <TableContent>
        <SearchableTable
          columns={columns}
          dataSource={invoices}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={[
            'user.firstName',
            'user.lastName',
            'mission.startsAt',
            'mission.internalTitle',
            'mission.internalReference',
            'position.name',
            'organization.name'
          ]}
          localStorageKey={localStorageKey || 'InvoiceList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default InvoiceList;
