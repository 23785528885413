import {Typography} from 'antd';
import styled from 'styled-components';

const {Title: BaseTitle, Paragraph: BaseParagraph, Text: BaseText} = Typography;

const TITLE_FONT_SIZE_PER_LEVEL = [32, 24, 20];
const TITLE_FONT_WEIGHT_PER_LEVEL = [700, 700, 500];
const TITLE_LINE_HEIGHT_PER_LEVEL = [40, 36, 32];

const Title = styled(BaseTitle)`
  &.ant-typography {
    color: var(--grey700);
    font-size: ${({level = 1}) => TITLE_FONT_SIZE_PER_LEVEL[level - 1]}px;
    font-weight: ${({level = 1}) => TITLE_FONT_WEIGHT_PER_LEVEL[level - 1]};
    line-height: ${({level = 1}) => TITLE_LINE_HEIGHT_PER_LEVEL[level - 1]}px;
  }
`;
const Paragraph = styled(BaseParagraph)``;
const Text = styled(BaseText)``;

export default {
  Title,
  Paragraph,
  Text
};
