import Geocoding, {
  GeocodeFeature,
  GeocodeResponse,
  GeocodeService
} from '@mapbox/mapbox-sdk/services/geocoding';
import {debounce} from 'lodash';
import React, {FunctionComponent, useCallback, useRef, useState} from 'react';
import Select, {SelectProps} from 'src/components/form/Select/Select';

const {REACT_APP_MAPBOX_API_ACCESS_TOKEN: defaultAccessToken} = process.env;

type Option = {
  label?: string;
  value: string;
};

export type Props = SelectProps<GeocodeFeature> & {
  accessToken?: string;
  value?: GeocodeFeature;
  onChange?: (feature: GeocodeFeature) => void;
  countries?: string[];
  language?: string[];
  limit?: number;
};

const optionFromFeature = (feature: GeocodeFeature): Option => ({
  label: feature.place_name,
  value: feature.id
});

const PlaceSelect: FunctionComponent<Props> = ({
  value: propValue,
  onChange,
  accessToken = defaultAccessToken || '',
  countries = ['FR'],
  language = ['fr-FR'],
  limit = 5,
  ...otherProps
}) => {
  const [value, setValue] = useState(propValue ? propValue.place_name : undefined);
  const [options, setOptions] = useState<Option[]>([]);
  const {current: geocodingService} = useRef<GeocodeService>(Geocoding({accessToken}));
  const latestFeatures = useRef<GeocodeFeature[]>([]);

  const handleSelect = useCallback((value: string, option: Option) => {
    setValue(option.label);
    const feature = latestFeatures.current.find((feature) => feature.id === value);
    if (onChange && feature) {
      console.warn('onChange');
      onChange(feature);
    }
  }, []);

  const handleChange = useCallback((value) => {
    setValue(value);
  }, []);

  const handleSearch = useCallback(
    debounce(async (searchValue: string) => {
      if (!searchValue) {
        return;
      }
      const proximity = [2.349014, 48.864716]; // Parisx
      const {body, statusCode} = await geocodingService
        .forwardGeocode({
          query: searchValue,
          mode: 'mapbox.places',
          countries,
          language,
          types: ['address', 'poi'],
          limit,
          proximity
        })
        .send();
      if (statusCode !== 200) {
        console.warn(`Failed to forwardGeocode with Mapbox`, body);
        return;
      }
      const {features} = body as GeocodeResponse;
      latestFeatures.current = features;
      setOptions(features.map(optionFromFeature));
    }, 500),
    []
  );

  // const optionItems = useMemo(
  //   () =>
  //     options.map(option => (
  //       <AutoComplete.Option key={option.value} value={option.value}>
  //         {option.label}
  //       </AutoComplete.Option>
  //     )),
  //   [options]
  // );

  return (
    <Select
      value={value}
      showSearch
      options={options}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onChange={handleChange}
      filterOption={false}
      notFoundContent={null}
      {...otherProps}
    >
      {/* {optionItems} */}
    </Select>
  );
};

export default PlaceSelect;
