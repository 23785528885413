// @flow

// @docs https://ant.design/components/button/
// @docs https://gist.github.com/samuelcastro/0ff7db4fd54ce2b80cd1c34a85b40c08

import {createElement, PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
// import type {Node} from 'react';
// import type {ContextRouter} from 'react-router-dom';
import {Steps} from 'antd';

const Step = Steps.Step; // eslint-disable-line

type Props = ContextRouter & {
  children?: Node;
  to: string;
};

export class StepLink extends PureComponent<Props> {
  handleClick = (ev: Event) => {
    const {history, to} = this.props;
    if (ev.defaultPrevented) {
      return;
    }
    history.push(to);
  };

  render() {
    const {to, staticContext, match, location, history, ...otherProps} = this.props;
    return createElement(Step, {...otherProps, style: {cursor: 'hand'}, onClick: this.handleClick});
  }
}

export default withRouter(StepLink);
