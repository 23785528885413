import React, {useMemo, FunctionComponent} from 'react';
import moment, {isMoment, Moment} from 'moment';
import {Tooltip} from 'antd';
import {TooltipPlacement} from 'antd/lib/tooltip';

type Props = {
  value: string | number | Date | Moment;
  format?: string;
  subtract?: number;
  unit?: 'hours' | 'minutes';
  tooltip?: boolean | TooltipPlacement;
};

const MomentDate: FunctionComponent<Props> = ({value = 0, format = 'L LT', subtract, unit, tooltip, ...otherProps}) => {
  const memoizedValue = useMemo(() => {
    const momentDate = isMoment(value) ? value.clone() : moment(value);
    if (subtract) {
      momentDate.subtract(subtract, unit);
    }
    return momentDate;
  }, [value, subtract, unit]);
  const memoizedFormattedValue = useMemo(() => memoizedValue.format(format), [memoizedValue, format]);
  const memoizedValueFromNow = useMemo(() => (tooltip ? memoizedValue.fromNow() : ''), [memoizedValue, tooltip]);

  const dateElement = <span {...otherProps}>{memoizedFormattedValue}</span>;

  if (tooltip) {
    const placement = tooltip !== true ? tooltip : 'top';
    return (
      <Tooltip title={memoizedValueFromNow} placement={placement}>
        {dateElement}
      </Tooltip>
    );
  }

  return dateElement;
};

export default MomentDate;
