// @flow

import React from 'react';
// import type {Node} from 'react';
import styled, {css} from 'styled-components';
import {fontSizes, colors} from '../../common/vars';

const paddings = {
  xs: '0.4rem 0.6rem',
  s: '0.5rem 0.8rem',
  m: '0.6rem 1rem',
  l: '0.8rem 1.4rem'
};

const StyledTag = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  border: 0;
  background: none;

  ${(props) =>
    css`
      background-color: ${colors[props.theme]};
      font-size: ${fontSizes[props.size]};
      padding: ${paddings[props.size]};
    `} color: white;
  border-radius: 40px;
`;

type Props = {
  children?: Node;
  theme?: 'neutral' | 'primary' | 'secondary' | 'third' | 'blank';
  size?: 'xs' | 's' | 'm' | 'l';
  className?: string;
};

const Tag = ({children, theme = 'neutral', size = 's', className}: Props) => (
  <StyledTag size={size} theme={theme} className={className}>
    {children}
  </StyledTag>
);

export default Tag;
