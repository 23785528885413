import React, {FunctionComponent} from 'react';
import {Switch} from 'react-router-dom';
import AppLayout from 'src/layouts/AppLayout/AppLayout';
import DeviceList from 'src/views/devices/DeviceList';
import SessionList from 'src/views/sessions/SessionList';
import UserForm from 'src/views/users/UserForm';
import UserList from 'src/views/users/UserList';
import PrivateRoute from '../helpers/PrivateRoute';
import {TeamOutlined, MobileOutlined, EyeOutlined, EditOutlined} from '@ant-design/icons';

const UserRoutes: FunctionComponent = () => (
  <Switch>
    <PrivateRoute exact path="/users" component={UserList} layout={AppLayout} />
    <PrivateRoute path="/users/create" component={UserForm} layout={AppLayout} />
    <PrivateRoute path="/users/:id/edit" component={UserForm} layout={AppLayout} />
    <PrivateRoute exact path="/sessions" component={SessionList} layout={AppLayout} />
    <PrivateRoute exact path="/devices" component={DeviceList} layout={AppLayout} />
  </Switch>
);

export const breadcrumbs = {
  '/users': {label: 'Users', icon: TeamOutlined},
  '/users/create': {label: 'Create', icon: EditOutlined},
  '/users/:id/edit': {label: 'Edit', icon: EditOutlined},
  '/sessions': {label: 'Sessions', icon: EyeOutlined},
  '/devices': {label: 'Devices', icon: MobileOutlined}
};

export default Object.assign(UserRoutes, {breadcrumbs});
