import {fetch} from 'redux-rest-resource';

export default fetch;

export const getAsync = (url, {method = 'GET', headers = {}, credentials = 'include', ...otherOpts} = {}) =>
  fetch(url, {
    credentials,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    ...otherOpts
  }).then((res) => res.json());

export const postAsync = (
  url,
  {method = 'POST', body = {}, headers = {}, credentials = 'include', ...otherOpts} = {}
) =>
  fetch(url, {
    credentials,
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    body: JSON.stringify(body),
    ...otherOpts
  }).then((res) => res.json());
