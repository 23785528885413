import React, {PureComponent, Fragment} from 'react';
import {WrappedFormUtils} from '.';

// import type {Node} from 'react';

type Props = {
  children?: Node;
  values?: {[s: string]: any};
  form: WrappedFormUtils;
};

export default class Fieldset extends PureComponent<Props> {
  state = {
    lastValues: null
  };

  componentDidMount() {
    const {values, form} = this.props;
    if (form && values) {
      this.setState({lastValues: values}, () => {
        form.setFieldsValue(values);
      });
    }
  }

  componentDidUpdate() {
    const {values, form} = this.props;
    const {lastValues} = this.state;
    if (form && values !== lastValues) {
      // eslint-disable-next-line
      this.setState({lastValues: values}, () => {
        form.setFieldsValue(values);
      });
    }
  }

  render() {
    const {children} = this.props;
    return <Fragment>{children}</Fragment>;
  }
}
