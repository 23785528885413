import {get, toNumber, toString} from 'lodash';
import {RefObject} from 'react';

type UnknownObject<T = unknown> = {[s: string]: T};
type Sorter = (a: UnknownObject, b: UnknownObject) => number;

export const localeSorterForField = (field: string): Sorter => {
  const dottedField = field.includes('.');
  return (a: UnknownObject, b: UnknownObject) => {
    const aValue = dottedField ? get(a, field) : a[field];
    const bValue = dottedField ? get(b, field) : b[field];
    return String(aValue).localeCompare(String(bValue));
  };
};

// const toDateTime = (value: string): number => new Date(value).getTime();

export const dateStringSorterForField = (field: string): Sorter => {
  const dottedField = field.includes('.');
  // "2018-11-09T16:12:37.375Z"
  return (a: UnknownObject, b: UnknownObject) => {
    const aValue = dottedField ? get(a, field) : a[field];
    const bValue = dottedField ? get(b, field) : b[field];
    return toString(aValue) > toString(bValue) ? 1 : -1;
  };
};

export const numericSorterForField = (field: string): Sorter => {
  const dottedField = field.includes('.');
  return (a: UnknownObject, b: UnknownObject) => {
    const aValue = dottedField ? get(a, field) : a[field];
    const bValue = dottedField ? get(b, field) : b[field];
    return toNumber(aValue) < toNumber(bValue) ? -1 : 1;
  };
};

export const refNumericSorterForField = (ref: RefObject<UnknownObject>, field: string): Sorter => {
  const dottedField = field.includes('.');
  return (a: UnknownObject, b: UnknownObject) => {
    const values = ref.current || {};
    const aValue = values[(dottedField ? get(a, field) : a[field]) as keyof UnknownObject];
    const bValue = values[(dottedField ? get(b, field) : b[field]) as keyof UnknownObject];
    jlog({aValue, bValue});
    return toNumber(aValue) || 0 < toNumber(bValue) || 0 ? -1 : 1;
  };
};
