import React, {FunctionComponent} from 'react';
import {RouteChildrenProps} from 'react-router';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import LoginLayout from 'src/layouts/LoginLayout';
import ErrorPage from 'src/views/errors/ErrorPage';
import NotFoundPage from 'src/views/errors/NotFoundPage';
import LoginForm from 'src/views/LoginForm';
import RouteWithLayout from './helpers/RouteWithLayout';
import FileRoutes from './routes/FileRoutes';
import OrganizationRoutes from './routes/OrganizationRoutes';
import UserRoutes from './routes/UserRoutes';
import LaborRoutes from './routes/LaborRoutes';
import MissionRoutes from './routes/MissionRoutes';
import WorkerRoutes from './routes/WorkerRoutes';

const bootstrapAuthRender: FunctionComponent<RouteChildrenProps> = (props) => {
  const {location} = props;
  const sessionId = localStorage.getItem('sessionId');
  const pathname = sessionId ? '/workers' : '/signin';
  return (
    <Redirect
      to={{
        pathname,
        state: {from: location}
      }}
    />
  );
};

const AppRouter: FunctionComponent<{}> = () => (
  <Router>
    <Switch>
      <Route exact path="/" render={bootstrapAuthRender} />
      <RouteWithLayout exact path="/signin" component={LoginForm} layout={LoginLayout} />
      <Route path={['/users', '/sessions', '/devices']} component={UserRoutes} />
      <Route path={['/workers', '/qualities']} component={WorkerRoutes} />
      <Route path="/files" component={FileRoutes} />
      <Route path="/organizations" component={OrganizationRoutes} />
      <Route path="/work" component={MissionRoutes} />
      <Route path="/labor" component={LaborRoutes} />
      <Route exact path="/404" component={NotFoundPage} />
      <Route exact path="/500" component={ErrorPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default AppRouter;
