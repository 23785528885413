import styled from 'styled-components';

// «»“”„
export const Quote = styled.blockquote`
  position: relative;
  display: inline-block;
  margin-left: 1em;
  font-style: italic;
  &::before {
    position: absolute;
    top: -0.5em;
    left: -0.5em;
    display: block;
    content: '“';
    font-size: 1em;
    color: var(--grey600);
    font-style: italic;
  }
  &::after {
    position: absolute;
    bottom: -0.5em;
    right: -0.5em;
    display: block;
    content: '”';
    font-size: 1em;
    color: var(--grey600);
    font-style: italic;
  }
`;
