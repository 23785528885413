import {FormInstance as AntdFormInstance, FormProps, useForm as antdUseForm} from 'antd/lib/form/Form';
// import type {InternalFormInstance} from 'rc-field-form/es/interface';
import {useCallback, useMemo, useRef, useState} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormInstance<TFormValues = any> = AntdFormInstance<TFormValues> & {
  useChangedValue: <K extends keyof TFormValues = keyof TFormValues>(name: K) => TFormValues[K] | undefined;
  useChangedValues: () => Partial<TFormValues>;
  useCurrentValues: () => Partial<TFormValues>;
  __EXTERNAL__: {
    onValuesChange: NonNullable<FormProps['onValuesChange']>;
    onInitialValueChange: (initialValues: any) => void;
  };
};

type UseFormResults<TFormValues> = [
  FormInstance<TFormValues>,
  {
    disabled: boolean;
    changedValues: Partial<TFormValues>;
  }
];

// const SECRET = 'RC_FORM_INTERNAL_HOOKS';

export const useForm = <TFormValues>(): UseFormResults<TFormValues> => {
  const [form] = antdUseForm<TFormValues>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [changedValues, setChangedValues] = useState<Partial<TFormValues>>({});
  const latestChangedValues = useRef<Partial<TFormValues>>(changedValues);
  const [currentValues, setCurrentValues] = useState<Partial<TFormValues>>({});
  const latestCurrentValues = useRef<Partial<TFormValues>>(currentValues);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  // const {
  //   getFields,
  //   setInitialValues,
  //   setCallbacks
  // } = ((form as unknown) as InternalFormInstance).getInternalHooks(SECRET)!;
  // setCallbacks({
  //   onValuesChange: (values) => {
  //     console.warn('getInternalHooks.onValuesChange');
  //   }
  // });
  const onValuesChange = useCallback<NonNullable<FormProps['onValuesChange']>>(
    async (changedValues) => {
      // console.warn({onValuesChange: changedValues}, 'useForm');
      setChangedValues((prevChangedValues) => {
        const nextValues = {...prevChangedValues, ...changedValues};
        latestChangedValues.current = nextValues;
        return nextValues;
      });
      setCurrentValues((prevCurrentValues) => {
        const nextValues = {...prevCurrentValues, ...changedValues};
        latestCurrentValues.current = nextValues;
        return nextValues;
      });
      // Pristine disabled
      const isPristine = !form.isFieldsTouched();
      if (isPristine) {
        setDisabled(true);
        return;
      }
      // Error disabled
      try {
        await form.validateFields(Object.keys(changedValues));
      } catch (err) {
        const {errorFields = []} = err;
        setDisabled(errorFields.length > 0);
        return;
      }
      setDisabled(false);
    },
    [form]
  );
  const onInitialValueChange = useCallback(
    async (changedValues) => {
      setCurrentValues((prevCurrentValues) => {
        const nextValues = {...prevCurrentValues, ...changedValues};
        latestCurrentValues.current = nextValues;
        return nextValues;
      });
    },
    [form]
  );

  const setFieldsValue = useCallback<FormInstance<TFormValues>['setFieldsValue']>(
    (values) => {
      // jlog({setFieldsValue: values});
      const result = form.setFieldsValue(values);
      onValuesChange(values, values);
      return result;
    },
    [form, onValuesChange]
  );

  const actualForm = useMemo<FormInstance<TFormValues>>(
    () =>
      Object.assign({}, form, {
        setFieldsValue,
        useChangedValue: <K extends keyof TFormValues = keyof TFormValues>(name: K) =>
          latestChangedValues.current[name],
        useChangedValues: () => latestChangedValues.current,
        useCurrentValues: () => latestCurrentValues.current,
        __EXTERNAL__: {onValuesChange, onInitialValueChange}
      }),
    [form, onValuesChange, setFieldsValue]
  );
  return [actualForm, {disabled, changedValues}];
};

/*
export interface InternalHooks {
    dispatch: (action: ReducerAction) => void;
    registerField: (entity: FieldEntity) => () => void;
    useSubscribe: (subscribable: boolean) => void;
    setInitialValues: (values: Store, init: boolean) => void;
    setCallbacks: (callbacks: Callbacks) => void;
    getFields: (namePathList?: InternalNamePath[]) => FieldData[];
    setValidateMessages: (validateMessages: ValidateMessages) => void;
    setPreserve: (preserve?: boolean) => void;
}
*/
