const fontFamilies = {
  defaultFont: "'Source Sans Pro', sans-serif"
};

const fontSizes = {
  xs: '0.8rem',
  s: '1rem',
  m: '1.2rem',
  l: '1.4rem',
  xl: '1.6rem',
  xxl: '2.4rem',
  xxxl: '3.6rem'
};

const colors = {
  primary: '#128df8',
  secondary: '#5e87d9',
  third: '#baa0f2',
  neutral: '#b7cada',
  dark: '#333434',
  light: '#f9fafb',
  lightest: '#ffffff',
  alert: '#f13737',
  transparent: 'transparent',
  white: 'white'
};

const spacings = {
  xs: '0.2rem',
  s: '0.4rem',
  m: '0.8rem',
  l: '1.6rem',
  xl: '2.4rem',
  xxl: '3.2rem',
  xxxl: '6.4rem'
};

export {fontFamilies, fontSizes, colors, spacings};
