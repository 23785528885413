import {
  AlertOutlined,
  AuditOutlined,
  BankOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CompassOutlined,
  EuroOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  RocketOutlined,
  SolutionOutlined,
  ThunderboltOutlined,
  TranslationOutlined,
  TrophyOutlined,
  UserOutlined
} from '@ant-design/icons';
import {AntdIconProps} from '@ant-design/icons/lib/components/AntdIcon';
// import {presetPalettes} from '@joonca/library/src/utils/typography';
import {createElement, FunctionComponent} from 'react';

export type AppIconProps = Omit<AntdIconProps, 'ref' | 'value'> & {
  type: string;
};

export const AppIcon: FunctionComponent<AppIconProps> = ({type, ...otherProps}) => {
  switch (type) {
    case 'info':
      return createElement(InfoCircleOutlined, otherProps);
    case 'mail':
      return createElement(MailOutlined, otherProps);
    case 'contact':
      return createElement(PhoneOutlined, otherProps);
    case 'user':
      return createElement(UserOutlined, otherProps);
    case 'date':
    case 'dates':
      return createElement(CalendarOutlined, otherProps);
    case 'days':
      return createElement(CarryOutOutlined, otherProps);
    case 'organization':
      return createElement(BankOutlined, otherProps);
    case 'mission':
      return createElement(SolutionOutlined, otherProps);
    case 'place':
      return createElement(CompassOutlined, otherProps);
    case 'position':
      return createElement(AuditOutlined, otherProps);
    case 'language':
    case 'locale':
      return createElement(TranslationOutlined, otherProps);
    case 'status':
    case 'application-status':
    case 'enrollment-status':
    case 'timesheet-status':
      return createElement(RocketOutlined, otherProps);
    case 'worker':
    case 'application-worker':
    case 'enrollment-worker':
    case 'timesheet-worker':
      return createElement(UserOutlined, otherProps);
    case 'application':
      return createElement(FileSearchOutlined, otherProps);
    case 'enrollment':
      return createElement(FileProtectOutlined, otherProps);
    case 'timesheet':
    case 'hours':
      return createElement(FieldTimeOutlined, otherProps);
    case 'invoice':
    case 'salary':
      return createElement(EuroOutlined, otherProps);
    case 'skill':
    case 'worker-skill':
      return createElement(TrophyOutlined, otherProps);
    case 'experience':
    case 'worker-experience':
      return createElement(ThunderboltOutlined, otherProps);
    case 'blame':
    case 'warning':
    case 'timesheet-blame':
      return createElement(AlertOutlined, otherProps);
    default:
      return null;
  }
};
