import {localeSorterForField} from '@joonca/library/src/utils/sort';
import {ColumnType} from 'antd/lib/table';
import {get} from 'lodash';
import React, {FunctionComponent, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Document, Mission, User} from '@joonca/typings';

type Props = {
  value: User;
  record: Document & {mission: Mission};
};

export const MissionCell: FunctionComponent<Props> = () => {
  return <div></div>;
};

export const missionColumn = <T extends Document & {mission: Mission}>(): ColumnType<T> => ({
  title: 'Mission',
  dataIndex: ['mission', '_sid'],
  sorter: localeSorterForField('mission.internalTitle'),
  render: (value: string, record): ReactNode => {
    // In case it has been deleted!
    // if (!record.mission) {
    //   return (
    //     <>
    //       <Icon type={'close-circle'} style={{color: presetPalettes['red'][4]}} />
    //       &nbsp;Unknown
    //     </>
    //   );
    // }
    return (
      <>
        <Link to={`/work/missions/${value}/view`}>{get(record, 'mission.internalTitle', 'SANS TITRE')}</Link>
        <div>
          {/* <small>
            {get(record, 'mission.place.postalCode', 'N/A')} {get(record, 'mission.place.locality', 'N/A')}
          </small>
          &nbsp;-&nbsp; */}
          <small>{get(record, 'mission.internalReference', 'N/A')}</small>
        </div>
      </>
    );
  }
});

export default missionColumn;
