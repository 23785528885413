import {localeSorterForField} from '@joonca/library/src/utils/sort';
import {ColumnType} from 'antd/lib/table';
import {get} from 'lodash';
import React from 'react';
import {Document, Mission, User} from '@joonca/typings';

type Props = {
  value: User;
  record: Document & {mission: Mission};
};

export const localityColumn = <T extends Document & {mission: Mission}>(): ColumnType<T> => ({
  title: 'Ville',
  dataIndex: ['mission', 'place', 'postalCode'],
  sorter: localeSorterForField('mission.place.postalCode'),
  render: (value, record) => (
    <span style={{fontSize: '12px'}}>
      {value}
      <br />
      {get(record, 'mission.place.locality', 'N/A')}
    </span>
  )
});

export default localityColumn;
