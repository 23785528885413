import {Spin} from 'antd';
import React, {FunctionComponent, HTMLProps, useCallback, useEffect, useState} from 'react';
import {ViewState} from 'react-map-gl';
import {MapView} from 'src/components/map';
import styled from 'styled-components';
import {PlaceSelect, PlaceSelectProps} from './PlaceSelect';

export type PlacePickerProps = PlaceSelectProps & {defaultCoordinates?: [number, number]; flyToZoom?: number};

export const PlacePicker: FunctionComponent<PlacePickerProps> = ({
  flyToZoom = 16,
  defaultCoordinates,
  onFeatureSelect,
  ...otherProps
}) => {
  const [viewport, setViewport] = useState<ViewState>();
  const [mapIsVisible, setMapIsVisible] = useState<boolean>(false);

  // @NOTE update map when a new feature is selected
  const handleFeatureSelect = useCallback<NonNullable<PlaceSelectProps['onFeatureSelect']>>(
    (feature) => {
      const {center} = feature;
      if (center) {
        setViewport({longitude: center[0], latitude: center[1], zoom: flyToZoom});
      }
      if (onFeatureSelect) {
        onFeatureSelect(feature);
      }
    },
    [onFeatureSelect, flyToZoom]
  );

  useEffect(() => {
    if (!defaultCoordinates || viewport) {
      return;
    }
    setViewport({longitude: defaultCoordinates[0], latitude: defaultCoordinates[1], zoom: flyToZoom});
  }, [viewport, flyToZoom, defaultCoordinates]);

  // @NOTE delay map display to ease perf
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMapIsVisible(true);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Container className="ant-row">
      <StyledPlaceSelect onFeatureSelect={handleFeatureSelect} limit={6} {...otherProps} />
      <MapViewContainer>
        {mapIsVisible ? <StyledMapView viewport={viewport} /> : <Spin size="large" />}
      </MapViewContainer>
    </Container>
  );
};

const Container = styled.div<HTMLProps<HTMLDivElement>>`
  justify-content: flex-end;
  align-items: stretch;
`;

const StyledPlaceSelect = styled(PlaceSelect)`
  margin-bottom: 12px;
`;

const StyledMapView = styled(MapView)`
  border-radius: 4px;
  overflow: hidden;
`;

const MapViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(230, 228, 224);
  height: 300px;
  width: 100%;
`;
