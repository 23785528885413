import {SyntheticEvent, useCallback} from 'react';
import {HOSTNAME_ENDPOINTS} from 'src/config/env';
console.warn({HOSTNAME_ENDPOINTS});
export const useDebugOnClick = (
  path: string,
  endpoint: keyof typeof HOSTNAME_ENDPOINTS = 'api'
): ((ev: SyntheticEvent<Element, MouseEvent>) => void) => {
  return useCallback(
    (ev) => {
      if (navigator.platform === 'MacIntel' && ev.nativeEvent.metaKey /* || ev.ctrlKey */) {
        setTimeout(() => {
          window.open(`${HOSTNAME_ENDPOINTS[endpoint]}${path}`);
        }, 0);
      }
    },
    [path, endpoint]
  );
};
