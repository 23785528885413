// @source https://github.com/ant-design/ant-design/blob/master/components/date-picker/RangePicker.tsx

import React, {PureComponent} from 'react';
import styled from 'styled-components';
import {TimePicker} from 'antd';
import invariant from 'invariant';
import {isMoment} from 'moment';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #999;
  > .ant-time-picker:nth-child(1) {
    margin-right: 0.5625rem;
  }
  > .ant-time-picker:nth-child(2) {
    margin: 0 0.5625rem;
  }
`;

// const CLIENT_TZ_OFFSET = new Date().getTimezoneOffset();

export default class TimeRangePicker extends PureComponent {
  state = {
    startTime: null,
    endTime: null
  };

  static getDerivedStateFromProps(props, state) {
    const {value} = props;
    if (!value) {
      return state;
    }
    // invariant(isMoment(value.startTime) && value.startTime.isLocal(), 'Expected a local moment startTime');
    // invariant(isMoment(value.endTime) && value.endTime.isLocal(), 'Expected a local moment endTime');
    return {
      ...state,
      startTime: value.startTime,
      endTime: value.endTime
    };
  }

  static setDerivedValue({startTime, endTime}) {
    // const offsetFromValue = startTime.utcOffset();
    // if (offsetFromValue) {
    //   startTime.add('minutes', -CLIENT_TZ_OFFSET);
    //   endTime.add('minutes', -CLIENT_TZ_OFFSET);
    // }
    console.warn('getDerivedValue', {
      isLocal: startTime.isLocal(),
      isUtc: startTime.isUtc(),
      format: startTime.format('HH:mm:ss'),
      iso: startTime.toISOString()
    });
    return {
      startTime: startTime
        // .utc()
        .startOf('minute')
        .set({year: 1970, month: 0, dayOfYear: 1}),
      endTime: endTime
        // .utc()
        .startOf('minute')
        .set({year: 1970, month: 0, dayOfYear: 1})
    };
  }

  handleStartChange = (startTime) => {
    const {onChange} = this.props;
    this.setState({startTime});
    if (onChange) {
      const {endTime} = this.state;
      const {setDerivedValue} = this.constructor;
      if (endTime) {
        console.warn('setDerivedValue', {
          isLocal: startTime.isLocal(),
          isUtc: startTime.isUtc(),
          format: startTime.format('HH:mm:ss'),
          iso: startTime.toISOString()
        });
        onChange(setDerivedValue({startTime, endTime}));
      }
    }
  };

  handleEndChange = (endTime) => {
    const {onChange} = this.props;
    this.setState({endTime});
    if (onChange) {
      const {startTime} = this.state;
      const {setDerivedValue} = this.constructor;
      if (startTime) {
        onChange(setDerivedValue({startTime, endTime}));
      }
    }
  };

  render() {
    const {onChange, value, ...otherProps} = this.props;
    const {startTime, endTime} = this.state;
    // const {getDerivedValue} = this.constructor;
    return (
      <Container>
        <TimePicker placeholder="Start time" value={startTime} onChange={this.handleStartChange} {...otherProps} />~
        <TimePicker placeholder="End time" value={endTime} onChange={this.handleEndChange} {...otherProps} />
        <div>{startTime && endTime && endTime.isBefore(startTime) ? 'J+1' : null}</div>
      </Container>
    );
  }
}
