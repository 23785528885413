// @docs https://ant.design/components/layout/

import React, {Component} from 'react';
import styled from 'styled-components';
import {Layout} from 'antd';
import {ReactComponent as AppLogo} from 'src/assets/AppLogo.svg';
import {gradientColors} from 'src/config/theme';
import Particles from 'react-particles-js';

const Header = styled(Layout.Header)`
  position: relative;
  color: #fff;
  background: none;
  /* background: ${gradientColors[0]}; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 20vh;
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
`;

const Content = styled(Layout.Content)`
  padding: 24px 0;
  /* background: linear-gradient(${gradientColors[0]}, ${gradientColors[1]}); */
`;

const Footer = styled(Layout.Footer)`
  color: white;
  background: none;
  /* background: ${gradientColors[1]}; */
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(${gradientColors[0]}, ${gradientColors[1]});
  z-index: 1;
`;

type Props = {
  children?: Node;
};

export default class LoginLayout extends Component<Props> {
  render() {
    const {children} = this.props;
    return (
      <>
        <Layout style={{zIndex: 3, background: 'none'}}>
          <Header>
            <AppLogo style={{margin: 0, paddingTop: 0}} />
            <Subtitle>admin access</Subtitle>
          </Header>
          <Content>{children}</Content>
          <Footer>Fait à Paris avec 🧡</Footer>
        </Layout>
        <Particles
          style={{position: 'absolute', zIndex: 2, top: 0, left: 0, bottom: 0, right: 0}}
          params={{
            particles: {
              number: {
                value: 160,
                density: {
                  enable: false
                }
              },
              size: {
                value: 3,
                random: true,
                anim: {
                  speed: 4,
                  size_min: 0.3
                }
              },
              line_linked: {
                enable: false
              },
              move: {
                random: true,
                speed: 1,
                direction: 'top',
                out_mode: 'out'
              }
            }
            // interactivity: {
            //   events: {
            //     onhover: {
            //       enable: true,
            //       mode: 'bubble'
            //     },
            //     onclick: {
            //       enable: true,
            //       mode: 'repulse'
            //     }
            //   },
            //   modes: {
            //     bubble: {
            //       distance: 250,
            //       duration: 2,
            //       size: 0,
            //       opacity: 0
            //     },
            //     repulse: {
            //       distance: 400,
            //       duration: 4
            //     }
            //   }
            // }
          }}
        />
        <Background />
      </>
    );
  }
}
