import React, {PureComponent} from 'react';
import {Transfer} from 'antd';
import styled from 'styled-components';
import professionSkills from 'src/assets/rome/profession_skills.json';
import designationsProfessions from 'src/assets/rome/designation_professions.json';
import {time, timeEnd} from '@joonca/library/src/utils/console';
import {TRANSFER_LOCALE} from 'src/config/locale';
import memoize from 'memoize-one';

const StyledTransfer = styled(Transfer)`
  &.ant-transfer {
    display: flex;
    padding-top: 4px;
  }
  .ant-transfer-list {
    flex: 1;
  }
`;

// type Props = {
//   value: ?(string[]),
//   designation: ?string,
//   onChange: (?(string[])) => void
// };
type State = {
  selectedKeys: string[];
};
export default class DesignationSkillsTransfer extends PureComponent<Props, State> {
  getDatasource = memoize((designation) => {
    if (!designation) {
      return [];
    }
    time(`${this.constructor.name}.getDatasource()`);
    const profession = designationsProfessions[designation];
    const skills = professionSkills[profession];
    const dataSource = skills.map(({label, value}) => ({
      key: value,
      label
    }));
    timeEnd(`${this.constructor.name}.getDatasource()`);
    return dataSource;
  });

  state = {
    selectedKeys: []
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]});
  };

  handleChange = (nextTargetKeys /* , direction, moveKeys */) => {
    const {onChange} = this.props;
    onChange(nextTargetKeys);
  };

  render() {
    const {designation, value} = this.props;
    const {selectedKeys, disabled} = this.state;
    const dataSource = this.getDatasource(designation);
    return (
      <StyledTransfer
        dataSource={dataSource}
        titles={['Disponibles', 'Actifs']}
        targetKeys={value}
        selectedKeys={selectedKeys}
        onChange={this.handleChange}
        onSelectChange={this.handleSelectChange}
        // onScroll={this.handleScroll}
        render={(item) => item.label}
        locale={{...TRANSFER_LOCALE, itemUnit: 'compétence', itemsUnit: 'compétences'}}
        disabled={disabled}
      />
    );
  }
}
