// @docs https://en.wikipedia.org/wiki/Common_European_Framework_of_Reference_for_Languages

import React from 'react';
import {Select} from 'antd';
import NestedSelect from 'src/components/form/select/NestedSelect';
import LANGUAGES from 'src/assets/language_codes';

const Option = Select.Option; // eslint-disable-line prefer-destructuring

// const LANGUAGE_LEVELS = [
//   {value: 'elementary', text: 'Basique'},
//   {value: 'professional-working', text: 'Avancé'},
//   {value: 'full-professional', text: 'Courant'},
//   {value: 'first-language', text: 'Maternelle'}
// ];

const LANGUAGE_LEVELS = [
  {value: 'A1', text: 'Beginner'},
  {value: 'A2', text: 'Elementary'},
  {value: 'B1', text: 'Intermediate'},
  // {value: 'B2', text: 'Upper Intermediate'},
  {value: 'C1', text: 'Advanced'},
  {value: 'C2', text: 'Proficiency'}
];

const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const languageLevelSelect = (
  <Select>
    {LANGUAGE_LEVELS.map(({value, text}) => (
      <Option key={value} value={value}>
        {text}
      </Option>
    ))}
  </Select>
);

export default function LanguageSelect(otherProps) {
  return (
    <NestedSelect
      mode="multiple"
      nestedInput={languageLevelSelect}
      nestedDefaultValue="A1"
      nestedLabel="Language Levels"
      filterOption={filterOption}
      {...otherProps}
    >
      {LANGUAGES.map(({name, alpha2}) => (
        <Option value={alpha2} key={alpha2}>
          {name}
        </Option>
      ))}
    </NestedSelect>
  );
}
