import moment, {isMoment, Moment} from 'moment';
import invariant from 'invariant';
import {isString} from 'lodash';

type DateLike = string | Date | Moment;

export const asDate = (date: DateLike): Date => {
  if (date instanceof Date) {
    return date;
  }
  if (isMoment(date)) {
    return (date as Moment).toDate();
  }
  return new Date(date);
};

// Keep dates as UTC
export const momentFromDateString = (date: string) => {
  invariant(isString(date) && date.length === 24, 'Expected date to be a string of 24 chars');
  return moment.utc(date);
};
export const momentFromTimeString = (date: string) => {
  invariant(isString(date) && date.length === 24, 'Expected date to be a string of 24 chars');
  return moment.utc(date);
  // return moment(date.slice(0, -1));
};

export const isValidDate = (date: Date): date is Date => date instanceof Date && !isNaN(date.getTime());

export const asDateISOString = (maybeDate: DateLike): string => asDate(maybeDate).toISOString();

export const getTime = (maybeDate: DateLike): number => asDate(maybeDate).getTime();

export const getHours = (maybeDate: DateLike): number => getTime(maybeDate) / 36e5;

export const moveDate = (maybeDate: DateLike, days: number): Date =>
  new Date(getTime(maybeDate) + days * 864e5);

export const combineISODateTime = (maybeDate: DateLike, maybeTime: DateLike): Date => {
  return new Date(`${asDateISOString(maybeDate).slice(0, 10)}T${asDateISOString(maybeTime).slice(11, 23)}Z`);
};

export const combineMomentDateTime = (date: Moment, time: Moment): Date => {
  // invariant(date.isUtc(), 'Expected date.isUtc() to be truthy');
  // invariant(time.isUtc(), 'Expected time.isUtc() to be truthy');
  console.warn('combineMomentDateTime', {
    isLocal: time.isLocal(),
    isUtc: time.isUtc(),
    format: time.format('HH:mm:ss'),
    iso: time.toISOString()
  });
  return new Date(`${date.format('YYYY-MM-DD')}T${time.format('HH:mm:ss')}.0000Z`);
};
