import React, {Component} from 'react';
import styled from 'styled-components';
import {Form} from 'antd';
// import {formItemLayout, tailFormItemLayout} from '@joonca/library/src/utils/layout';
/*
<FormItem {...formItemLayout} label="First name">
  {getFieldDecorator('firstName', {
    rules: [{required: false}]
  })(<Input />)}
</FormItem>
*/

export default styled(Form.Item)`
  flex: 1;
  /* .ant-form-item-required {
    &:after {
      display: inline-block;
      margin-right: 4px;
      content: '*';
      font-family: SimSun;
      line-height: 1;
      font-size: 14px;
      color: #f5222d;
    }
  } */
  .ant-form-item-label {
    label {
      color: #82879e;
      font-size: 0.71rem;
      font-weight: 600;
      margin-left: 1.125rem;
    }
    /* line-height: 20px; */
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 1.14rem;
    padding: 0;
  }
`;

// @TODO
// export class FormItem extends Component {
//   render() {
//     const props = this.props;
//     const {children} = props;
//     return <StyledFormItem>{children}</StyledFormItem>;
//   }
// }

// export default FormItem;

/*
  static modelName = 'Organization';
  static schema = {
    name: {bsonType: 'string', required: true},
    avatar: {bsonType: 'string', validate: [isURL, 'invalid url']},
    legalName: {bsonType: 'string', required: true},
    legalCountry: {bsonType: 'string', minLength: 3, maxLength: 3, validate: [isISO31661Alpha3, 'invalid nationality']},
    legalForm: {bsonType: 'string', enum: ['EURL', 'SA', 'SARL', 'SAS']},
    legalSector: {bsonType: 'string'},
    legalNumber: {bsonType: 'string'},
    legalRegistrationDate: {bsonType: 'date'},
    legalShareCapitalCents: {bsonType: 'long'},
    legalShareCapital: {bsonType: 'double'},
    contactEmail: {bsonType: 'string', required: true, pattern: '^.+@.+$', validate: [isEmail, 'invalid email']},
    address: {
      bsonType: 'object',
      additionalProperties: false,
      properties: {
        contactName: {bsonType: 'string', required: false},
        firstLine: {bsonType: 'string', required: false},
        secondLine: {bsonType: 'string', required: false},
        thirdLine: {bsonType: 'string', required: false},
        zipCode: {bsonType: 'string', required: false},
        city: {bsonType: 'string', required: false},
        country: {bsonType: 'string', required: false}
      }
    }
  };
    */
