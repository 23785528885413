import React, {FunctionComponent} from 'react';
import {RouteChildrenProps} from 'react-router';

import {useSelector, useActions} from '@joonca/library/src/utils/redux';
import {actions as fileActions} from 'src/store/modules/files';
import {useAsyncEffect} from '@joonca/library/src/hooks';
import {Spin, Button} from 'antd';
import styled from 'styled-components';

type Props = RouteChildrenProps<{id: string}> & {};

const FileView: FunctionComponent<Props> = ({match}) => {
  if (!match) {
    return null;
  }
  const {id} = match.params;
  console.warn({fileActions});
  const {getFile} = useActions(fileActions, []);
  const file = useSelector<State, Worker>((state) => state.files.resource.item);
  const isLoading = useSelector<State, boolean>(
    (state) => !state.files.resource.lastUpdatedItem || state.files.resource.isFetchingItem
  );

  useAsyncEffect(async () => {
    await Promise.all([getFile(id, {query: {population: {owner: 1}}})]);
  }, [id]);

  // Loading
  if (isLoading) {
    return <Spin style={{padding: '64px', width: '100%'}} />;
  }

  return (
    <div>
      <h2>Fichier</h2>
      <Row>
        <ul>
          <li>
            <Label>FileName:</Label>
            <Value>{file.fileName}</Value>
          </li>
          <li>
            <Label>Key:</Label>
            <Value>{file.key}</Value>
          </li>
          <li>
            <Label>Status:</Label>
            <Value>{file.status}</Value>
          </li>
        </ul>
      </Row>
    </div>
  );
};

export default FileView;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  padding-right: 4px;
`;

const Value = styled.span``;
