import {Spin} from 'antd';
import React, {FunctionComponent} from 'react';
// import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

type Props = {};

const LoadingPage: FunctionComponent<Props> = () => {
  // const history = useHistory();
  // const onClick = useCallback(() => {
  //   history.goBack();
  // }, [history]);
  return (
    <Container>
      <StyledSpin size="large" />
    </Container>
  );
};

const Container = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StyledSpin = styled(Spin)`
  &.ant-spin {
    /* background-color: var(--grey400);
    border-radius: 12px;
    padding: 24px; */
    margin-top: 4rem;
  }
`;

export default LoadingPage;
