// @source https://github.com/ant-design/ant-design/tree/master/components/radio

import {Form as AntDesignForm} from 'antd';
import {FormItemProps as AntDesignFormItemProps} from 'antd/lib/form';
import React, {FunctionComponent, useMemo} from 'react';
import {ReactNode} from 'react';
import './style/form-item.less';

export const RULE_MESSAGES = {
  required: 'saisie requise',
  pattern: 'saisie invalide',
  type: 'saisie invalide'
};

const {Item: AntDesignItem} = AntDesignForm;

export type FormItemProps = AntDesignFormItemProps & {
  icon?: ReactNode;
};

export const FormItem: FunctionComponent<FormItemProps> = ({
  icon,
  name,
  label,
  required,
  rules: rulesProp = [],
  ...otherProps
}) => {
  const rules = useMemo<NonNullable<FormItemProps['rules']>>(
    () => rulesProp.concat(required ? [{required: true, message: RULE_MESSAGES.required}] : []),
    [rulesProp, required]
  );
  // log(otherProps.name, rules);
  const actualLabel = useMemo(
    () =>
      icon || label ? (
        <>
          {icon}
          {label}
        </>
      ) : undefined,
    [icon, label]
  );
  // @NOTE Support dotted name-paths
  const actualName = useMemo(() => (typeof name === 'string' && name.includes('.') ? name.split('.') : name), [
    name
  ]);
  return (
    <AntDesignItem name={actualName} rules={rules} required={required} label={actualLabel} {...otherProps} />
  );
};
