// @docs https://github.com/gaearon/react-hot-loader
// @docs https://github.com/ianobermiller/redux-react-hook
// @docs https://github.com/gaearon/react-hot-loader/releases

import React, {FunctionComponent} from 'react';
import {Provider} from 'react-redux';
import {hot} from 'react-hot-loader/root';
import store from 'src/store';
import AppRouter from 'src/routers/AppRouter';

export type Props = {};

const App: FunctionComponent<Props> = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default hot(App);
