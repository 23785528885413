// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, StopOutlined} from '@ant-design/icons';
import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {dateStringSorterForField, localeSorterForField} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {colors} from '@joonca/library/src/utils/typography';
import {Popconfirm, Tag} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {FunctionComponent, ReactNode, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as usersActions} from 'src/store/modules/users';
import {State} from 'src/store/reducers';
import {User} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

type Props = {
  localStorageKey?: string;
};
const UserList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchUsers, deleteUser} = useActions(usersActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const users = useSelector<State, Array<User>>((state) => state.users.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchUsers({}, {query: {population: {position: 1, organization: 1}}})]),
    [fetchUsers]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<User>>(
    () => [
      {
        title: 'Username',
        key: 'username',
        dataIndex: 'username',
        sorter: localeSorterForField('username')
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
        filters: [
          {text: 'admin', value: 'admin'},
          {text: 'worker', value: 'worker'},
          {text: 'client', value: 'client'}
        ],
        onFilter: (value, record: User): boolean => record.role === value,
        render: (role: User['role']): ReactNode => <Tag color="blue">{role}</Tag>
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email'
      },
      {
        title: 'PhoneNumber',
        key: 'phoneNumber',
        dataIndex: 'phoneNumber'
      },
      {
        title: 'Verified',
        key: 'isVerified',
        dataIndex: 'isVerified',
        render: (isVerified: boolean): ReactNode =>
          isVerified ? <CheckOutlined style={{color: colors.success}} /> : null
      },
      {
        title: 'Blocked',
        key: 'isBlocked',
        dataIndex: 'isBlocked',
        render: (isBlocked: boolean): ReactNode =>
          isBlocked ? <StopOutlined style={{color: colors.error}} /> : null
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('users')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <LinkButton disabled ghost type="primary" icon={<EditOutlined />} to={`/users/edit/${_sid}`} />
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteUser({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon="delete" disabled={!isSuperAdmin} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteUser, isSuperAdmin]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon={<PlusOutlined />} to="/users/create">
          Add User
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable<User>
          columns={columns}
          dataSource={users}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={['_id', '_sid', 'role', 'firstName', 'lastName', 'username', 'email']}
          localStorageKey={localStorageKey || 'UserList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default UserList;
