import React from 'react';
import {Icon, Tag} from 'antd';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import styled from 'styled-components';
import MomentDate from 'src/components/content/MomentDate';
import {Moment} from 'moment';

const Container = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
const StaticTag = styled(Tag)`
  cursor: default !important;
  user-select: none;
`;
const TagWithIcon = styled.div`
  margin-bottom: 0.25rem;
  white-space: nowrap;
`;
const StyledDate = styled(MomentDate)`
  font-size: 10px;
  color: ${presetPalettes.grey[4]};
`;

export const MISSION_STATUS_LABELS: {[s: string]: string} = {
  draft: 'brouillon',
  published: 'publiée',
  scheduled: 'programmée',
  cancelled: 'annulée',
  terminated: 'clôturée',
  ended: 'finalisée'
};

const STATUS_DISPLAY_CONFIG: {[s: string]: {iconType: string; tagColor: string}} = {
  draft: {
    iconType: 'experiment',
    tagColor: 'blue'
  },
  published: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  scheduled: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  cancelled: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  terminated: {
    iconType: 'close-circle',
    tagColor: 'purple'
  },
  ended: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  default: {
    iconType: 'question-circle',
    tagColor: 'volcano'
  }
};

const getIconTypeForMissionStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].iconType : STATUS_DISPLAY_CONFIG.default.iconType;

const getTagColorForMissionStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status] ? STATUS_DISPLAY_CONFIG[status].tagColor : STATUS_DISPLAY_CONFIG.default.tagColor;

type Props = {
  status: string;
  date?: Date | Moment | string;
};

export default function MissionStatus({status, date}: Props) {
  const color = getTagColorForMissionStatus(status);
  const label = MISSION_STATUS_LABELS[status] || status;
  return (
    <Container>
      <TagWithIcon>
        <Icon
          type={getIconTypeForMissionStatus(status)}
          style={{color: presetPalettes[color][4], marginRight: '4px'}}
        />
        <StaticTag color={color}>{label}</StaticTag>
      </TagWithIcon>
      {date ? <StyledDate value={new Date()} format="DD/MM/YY LT" tooltip="bottom" /> : null}
    </Container>
  );
}
