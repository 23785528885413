import React from 'react';
import {Icon} from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import UserAvatar from 'src/components/content/UserAvatar';
import {NATIONALITY_LABELS, LANGUAGE_LEVEL_LABELS, GENDER_LABELS} from 'src/config/labels';

export {Marker} from 'react-map-gl';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  padding-right: 4px;
`;

const Value = styled.span``;

type Props = {
  user: {[s: string]: any};
  worker: {[s: string]: any};
};

// const GenderLabel = ({value}) => {
//   let label = '';
//   (
//   <span>
//     {/* <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> */}
//     {/* <Icon type="star" theme="filled" /> */}
//     {}
//   </span>
// );
//   }

const ICON_PROPS = {
  success: {type: 'check-circle', theme: 'twoTone', twoToneColor: 'limegreen'},
  warn: {type: 'exclamation-circle', theme: 'twoTone', twoToneColor: 'orange'}
};

export default function WorkerExpandedRow(props: Props) {
  const {user, worker} = props;

  const {experiences = [], certificates = [], languages = {}, licenses = {}} = worker;
  const lastExperience = experiences.length ? experiences[experiences.length - 1] : null;
  const lastCertificate = certificates.length ? certificates[certificates.length - 1] : null;

  return (
    <Row>
      <UserAvatar user={user} size={96} />
      <ul>
        <li>
          <Label>Nom:</Label>
          <Value>{`${user.firstName} ${user.lastName}`}</Value>
        </li>
        <li>
          <Label>Sexe:</Label>
          <Value>{GENDER_LABELS[worker.gender]}</Value>
        </li>
        <li>
          <Label>Age:</Label>
          <Value>{moment(worker.birthDate).fromNow(true)}</Value>
        </li>
        <li>
          <Label>Nationalité:</Label>
          <Value>{NATIONALITY_LABELS[worker.nationality]}</Value>
        </li>
        <li>
          <Label>Domicile:</Label>
          <Value>{`${worker.homePlace.postalCode}, ${worker.homePlace.locality}`}</Value>
        </li>
      </ul>
      <ul>
        <li>
          <Label>Missions:</Label>
          <Value>
            <Icon {...ICON_PROPS['warn']} /> Aucune
          </Value>
        </li>
        <li>
          <Label>Expériences:</Label>
          <Value>
            <Icon {...ICON_PROPS[experiences.length > 0 ? 'success' : 'warn']} /> {experiences.length}{' '}
            {lastExperience ? (
              <small>
                (dernier: {lastExperience.title} de {moment(lastExperience.startDate).format('MM/YYYY')} à{' '}
                {moment(lastExperience.endDate).format('MM/YYYY')})
              </small>
            ) : null}
          </Value>
        </li>
        <li>
          <Label>Dîplomes:</Label>
          <Value>
            <Icon {...ICON_PROPS[certificates.length > 0 ? 'success' : 'warn']} /> {certificates.length}{' '}
            {lastCertificate ? (
              <small>
                (dernier: {lastCertificate.title} en {lastCertificate.year})
              </small>
            ) : null}
          </Value>
        </li>
        <li>
          <Label>Permis:</Label> <Value>{Object.keys(licenses).join(', ')}</Value>
        </li>
        <li>
          <Label>Langues:</Label>{' '}
          <Value>
            {Object.keys(languages).map((language) => {
              return (
                <span key={language}>
                  {NATIONALITY_LABELS[language]} <small>({LANGUAGE_LEVEL_LABELS[languages[language]]})</small>
                </span>
              );
            })}
          </Value>
        </li>
      </ul>
    </Row>
  );
}
