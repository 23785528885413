import React, {FunctionComponent} from 'react';
import {Icon, Tag} from 'antd';
import {presetPalettes} from '@joonca/library/src/utils/typography';
import styled from 'styled-components';
import MomentDate from 'src/components/content/MomentDate';
import {Moment} from 'moment';

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const StaticTag = styled(Tag)`
  cursor: default !important;
  user-select: none;
`;
const TagWithIcon = styled.div`
  margin-bottom: 0.25rem;
  white-space: nowrap;
`;
const StyledDate = styled(MomentDate)`
  font-size: 10px;
  color: ${presetPalettes.grey[4]};
`;

export const APPLICATION_WORKER_STATUS_LABELS: {[s: string]: string} = {
  applying: 'proposée',
  withdrawn: 'retirée',
  confirmed: 'confirmée',
  terminated: 'clôturée'
};

export const APPLICATION_CLIENT_STATUS_LABELS: {[s: string]: string} = {
  pending: 'évaluée',
  accepted: 'acceptée',
  queued: 'réservée',
  rejected: 'rejetée',
  scheduled: 'programmée',
  cancelled: 'annulée',
  terminated: 'clôturée'
};

export const APPLICATION_STATUS_LABELS: {[s: string]: string} = {
  ...APPLICATION_WORKER_STATUS_LABELS,
  ...APPLICATION_CLIENT_STATUS_LABELS
};

const STATUS_DISPLAY_CONFIG: {[s: string]: {iconType: string; tagColor: string}} = {
  applying: {
    iconType: 'book',
    tagColor: 'blue'
  },
  pending: {
    iconType: 'clock-circle',
    tagColor: 'volcano'
  },
  accepted: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  scheduled: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  queued: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  confirmed: {
    iconType: 'check-circle',
    tagColor: 'green'
  },
  rejected: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  withdrawn: {
    iconType: 'close-circle',
    tagColor: 'volcano'
  },
  cancelled: {
    iconType: 'close-circle',
    tagColor: 'purple'
  },
  terminated: {
    iconType: 'close-circle',
    tagColor: 'purple'
  },
  default: {
    iconType: 'question-circle',
    tagColor: 'volcano'
  }
};

const getIconTypeForApplicationStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status]
    ? STATUS_DISPLAY_CONFIG[status].iconType
    : STATUS_DISPLAY_CONFIG.default.iconType;

const getTagColorForApplicationStatus = (status: string) =>
  STATUS_DISPLAY_CONFIG[status]
    ? STATUS_DISPLAY_CONFIG[status].tagColor
    : STATUS_DISPLAY_CONFIG.default.tagColor;

type Props = {
  status: string;
  type?: string;
  date?: Date | Moment | string;
};

const ApplicationStatus: FunctionComponent<Props> = ({status, date}) => {
  const color = getTagColorForApplicationStatus(status);
  const label = APPLICATION_STATUS_LABELS[status] || status;
  return (
    <Container>
      <TagWithIcon>
        <Icon
          type={getIconTypeForApplicationStatus(status)}
          style={{color: presetPalettes[color][4], marginRight: '4px'}}
        />
        <StaticTag color={color}>{label}</StaticTag>
      </TagWithIcon>
      {date ? <StyledDate value={date} format="DD/MM/YY LT" tooltip="bottom" /> : null}
    </Container>
  );
};

export default ApplicationStatus;
