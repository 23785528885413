// @docs https://ant.design/components/table/
// @source https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx

import {CheckOutlined, DeleteOutlined, EditOutlined, HourglassOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, LinkButton, useActions, useAsyncEffect} from '@joonca/library';
import {
  dateStringSorterForField,
  localeSorterForField,
  numericSorterForField
} from '@joonca/library/src/utils/sort';
import {dateTableRender, debugOnCell} from '@joonca/library/src/utils/table';
import {colors} from '@joonca/library/src/utils/typography';
import {Popconfirm, Tag, Tooltip} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Image from 'src/components/content/Image';
import SearchableTable from 'src/components/SearchableTable';
import SearchInput from 'src/components/SearchInput';
import TableLayout, {TableContent, TableHeader} from 'src/components/TableLayout';
import {actions as filesActions} from 'src/store/modules/files';
import {State} from 'src/store/reducers';
import {File} from '@joonca/typings';
import {useIsSuperAdmin} from 'src/utils/hooks';

type Props = {
  localStorageKey?: string;
};
const FileList: FunctionComponent<Props> = ({localStorageKey}) => {
  const {fetchFiles, deleteFile} = useActions(filesActions, []);

  const [searchValue, setSearchValue] = useState<string>('');
  const files = useSelector<State, Array<File>>((state) => state.files.resource.items);
  const isSuperAdmin = useIsSuperAdmin();

  const fetchData = useCallback(
    () => Promise.all([fetchFiles({}, {query: {population: {position: 1, organization: 1}}})]),
    [fetchFiles]
  );
  useAsyncEffect(async () => {
    console.time('fetchData');
    await fetchData();
    console.timeEnd('fetchData');
  }, []);

  const columns = useMemo<ColumnsType<File>>(
    () => [
      {
        title: 'Picture',
        dataIndex: 'uri',
        render: (value: string, record) =>
          ['image/jpeg'].includes(record.mimeType) ? (
            <Image source={{uri: value}} style={{maxHeight: '32px'}} />
          ) : null
      },
      {
        title: 'fileName',
        dataIndex: 'fileName',
        sorter: localeSorterForField('fileName')
      },
      {
        title: 'Key',
        dataIndex: 'key',
        sorter: localeSorterForField('key')
      },
      {
        title: 'Level',
        dataIndex: 'level',
        filters: [
          {text: 'Public', value: 'public'},
          {text: 'Protected', value: 'protected'},
          {text: 'Private', value: 'private'}
        ],
        onFilter: (value, record) => record.level === value,
        render: (value) => <Tag color="blue">{value}</Tag>
      },
      {
        title: 'Size',
        dataIndex: 'size',
        sorter: numericSorterForField('size')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          {text: 'Active', value: 'active'},
          {text: 'Pending', value: 'pending'}
        ],
        onFilter: (value, record) => record.status === value,
        render: (status: string) =>
          status === 'active' ? (
            <Tooltip title="Active" placement="top">
              <CheckOutlined style={{color: colors.success}} />
            </Tooltip>
          ) : (
            <Tooltip title="Pending" placement="top">
              <HourglassOutlined type="hourglass" style={{color: colors.error}} />
            </Tooltip>
          )
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        defaultSortOrder: 'descend',
        sorter: dateStringSorterForField('createdAt'),
        render: dateTableRender,
        onCell: debugOnCell('files', 'storage')
      },
      {
        title: 'Actions',
        dataIndex: '_sid',
        key: 'actions',
        width: 32 * 3,
        render: (_sid: string) => {
          return (
            <Button.Group>
              <LinkButton disabled ghost type="primary" icon={<EditOutlined />} to={`/files/edit/${_sid}`} />
              <Popconfirm
                title="Are you sure?"
                placement="topRight"
                onConfirm={() => deleteFile({_sid})}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button ghost type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Button.Group>
          );
        }
      }
    ],
    [deleteFile]
  );
  return (
    <TableLayout>
      <TableHeader>
        <SearchInput onSearch={setSearchValue} />
        <LinkButton disabled type="primary" size="large" icon={<PlusOutlined />} to="/files/create">
          Add File
        </LinkButton>
      </TableHeader>
      <TableContent>
        <SearchableTable<File>
          columns={columns}
          dataSource={files}
          searchValue={searchValue}
          rowKey="_sid"
          searchKeys={['_id', '_sid', 'role', 'firstName', 'lastName', 'filename', 'email']}
          localStorageKey={localStorageKey || 'FileList'}
        />
      </TableContent>
    </TableLayout>
  );
};

export default FileList;
